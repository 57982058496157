import { createContext, useState, FC, ReactNode, useContext, useCallback } from "react";
import { User, UserFilters } from "../utilities/types/admin/types";
import { AuthContext } from "./AuthContext";
import { getUserDetail, getUsers } from "../utilities/api/adminService";

interface UsersListContextValue {
  users: User[];
  totalPages: number;
  currentPage: number;
  perPage: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  loading: boolean;
  userDetail: any;
  fetchUsers: (page?: number, filters?: UserFilters) => Promise<void>;
  fetchUserDetail: (userId: string) => Promise<void>;
  setPerPage: (perPage: number) => void;
}

export const UsersListContext = createContext<UsersListContextValue>({
  users: [],
  totalPages: 1,
  currentPage: 1,
  perPage: 8,
  totalItems: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  loading: false,
  userDetail: null,
  fetchUsers: async () => {},
  fetchUserDetail: async () => {},
  setPerPage: () => {},
});

export const UsersListProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { accessToken } = useContext(AuthContext);
  const [users, setUsers] = useState<User[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState<User | null>(null);

  const fetchUsers = useCallback(
    async (page: number = 1, filters?: UserFilters) => {
      if (!accessToken) return;
      try {
        setLoading(true);
        const response = await getUsers(accessToken, page, perPage, filters);
        if (response.ok) {
          setUsers(response.data.data);
          setTotalItems(response.data.total);
          setTotalPages(response.data.last_page);
          setCurrentPage(response.data.current_page);
          setHasPreviousPage(response.data.current_page > 1);
          setHasNextPage(response.data.current_page < response.data.last_page);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    },
    [accessToken, perPage]
  );

  const fetchUserDetail = useCallback(
    async (userId: string) => {
      if (!accessToken) return;
      try {
        setLoading(true);
        const response = await getUserDetail(accessToken, userId);
        if (response.ok) {
          setUserDetail(response.data);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    },
    [accessToken]
  );

  return (
    <UsersListContext.Provider
      value={{
        users,
        totalPages,
        currentPage,
        perPage,
        totalItems,
        hasPreviousPage,
        hasNextPage,
        loading,
        userDetail,
        fetchUsers,
        fetchUserDetail,
        setPerPage,
      }}
    >
      {children}
    </UsersListContext.Provider>
  );
};

export const useUsersList = () => {
  const context = useContext(UsersListContext);
  if (!context) {
    throw new Error("useUsersList must be used within an UsersProvider");
  }
  return context;
};
