import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { createApiKey, createApplication, getApplication } from "../../utilities/api/applicationService";
import { enqueueSnackbar } from "notistack";
import Loader from "../loading/Loader";
import { CodeCircle, DocumentCode, DocumentText1, SecuritySafe, PasswordCheck, Personalcard, Refresh, Sms, User, Key } from "iconsax-react";
import MainButton from "../button/MainButton";
import { ApplicationData } from "../../utilities/types/application/types";
import ReusableModal from "../modal/ReusableModal";
import { Link } from "react-router-dom";

const Application: React.FC = () => {
  const { accessToken } = useContext(AuthContext);
  const [applicationData, setApplicationData] = useState<ApplicationData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState<boolean>(false);
  const [ApiKey, setApiKey] = useState<string>("");

  useEffect(() => {
    fetchApplication();
  }, []);

  const fetchApplication = async () => {
    if (!accessToken) return;
    setLoading(true);

    try {
      const response = await getApplication(accessToken);
      if (response.ok) {
        setApplicationData(response.data);
      } else {
        enqueueSnackbar(response.message || "Unable to load application data. Please refresh and try again.", { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching application:", error);
      enqueueSnackbar("An error occurred while retrieving application data. Please try again later.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateApplication = async () => {
    if (!accessToken) return;
    setLoading(true);
    try {
      const response = await createApplication(accessToken);
      if (response.ok) {
        setApplicationData(response.data);
        enqueueSnackbar("Application created successfully!", { variant: "success" });
      } else {
        enqueueSnackbar(response.message || "Unable to create application. Please check your inputs and try again.", { variant: "error" });
      }
    } catch (error) {
      console.error("Error creating application:", error);
      enqueueSnackbar("An error occurred during application creation. Please try again later.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshApplication = () => {
    setIsRefreshModalOpen(true);
  };

  const confirmRefreshApplication = async () => {
    if (!accessToken) return;
    setLoading(true);
    try {
      const response = await createApplication(accessToken);
      if (response.ok) {
        setApplicationData(response.data);
        enqueueSnackbar("Application refreshed successfully!", { variant: "success" });
      } else {
        enqueueSnackbar(response.message || "Unable to refresh application. Please try again.", { variant: "error" });
      }
    } catch (error) {
      console.error("Error refreshing application:", error);
      enqueueSnackbar("An error occurred during application refresh. Please try again later.", { variant: "error" });
    } finally {
      setLoading(false);
      setIsRefreshModalOpen(false);
    }
  };

  const handleCreateApiKey = async () => {
    if (!accessToken) return;
    try {
      const response = await createApiKey(accessToken);
      if (response.ok) {
        await fetchApplication();
        enqueueSnackbar("API Key generated successfully!", { variant: "success" });
      } else {
        enqueueSnackbar(response.message || "Failed to generate API Key", { variant: "error" });
      }
    } catch (error) {
      console.error("Error generating API Key:", error);
      enqueueSnackbar("An error occurred while generating API Key", { variant: "error" });
    }
  };

  const copyJsonToClipboard = () => {
    if (!applicationData) return;

    const jsonData = JSON.stringify(
      {
        grant_type: applicationData.grant_type,
        client_id: applicationData.client_id,
        client_secret: applicationData.client_secret,
        client_name: applicationData.client_name,
        username: applicationData.username,
        password: "YOUR_PANEL_PASSWORD",
        api_key: applicationData.api_key || null,
      },
      null,
      2
    );

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(jsonData)
        .then(() => {
          enqueueSnackbar("JSON data copied to clipboard", { variant: "success" });
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          enqueueSnackbar("Unable to copy JSON data. Please try again.", { variant: "error" });
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = jsonData;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        enqueueSnackbar("JSON data copied to clipboard", { variant: "success" });
      } catch (err) {
        console.error("Error fetching application:", err);
        enqueueSnackbar("An error occurred. JSON data was not copied to clipboard.", { variant: "error" });
      }
    }
  };

  const handleCopySecret = () => {
    if (!applicationData?.client_secret) return;

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(applicationData.client_secret)
        .then(() => {
          enqueueSnackbar("Secret key copied to clipboard", { variant: "success" });
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          enqueueSnackbar("Unable to copy secret key. Please try again.", { variant: "error" });
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = applicationData.client_secret;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        textArea.remove();
        enqueueSnackbar("Secret key copied to clipboard", { variant: "success" });
      } catch (err) {
        console.error("Error copying secret key:", err);
        enqueueSnackbar("An error occurred. Secret key was not copied.", { variant: "error" });
      }
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full p-4 lg:p-6">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="w-full p-4 lg:p-6">
        <h2 className="font-bold text-closed-shutter-300">Application</h2>
      </div>
      <div className="flex flex-col p-4 lg:p-6">
        {applicationData && Object.keys(applicationData).length > 0 ? (
          <div className="flex flex-col overflow-hidden border divide-y rounded-md divide-gorgonzola-blue-50 border-gorgonzola-blue-50">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-10 p-4 text-xs *:flex *:flex-col *:gap-3">
              <div className="col-span-2 lg:col-span-1">
                <div className="flex items-center gap-2 text-closed-shutter-300/70">
                  <User className="size-[1.125rem]" variant="Broken" />
                  <span>Name</span>
                </div>
                <span className="font-bold ps-6 text-closed-shutter-300 line-clamp-1">{applicationData.client_name}</span>
              </div>

              <div className="col-span-2 lg:col-span-1">
                <div className="flex items-center gap-2 text-closed-shutter-300/70">
                  <PasswordCheck className="size-[1.125rem]" variant="Broken" />
                  <span>Password</span>
                </div>
                <span className="font-bold ps-6 text-closed-shutter-300 line-clamp-1">Your account password!</span>
              </div>

              <div className="col-span-2 lg:col-span-1">
                <div className="flex items-center gap-2 text-closed-shutter-300/70">
                  <Sms className="size-[1.125rem]" variant="Broken" />
                  <span>Email</span>
                </div>
                <span className="font-bold ps-6 text-closed-shutter-300 line-clamp-1">{applicationData.username}</span>
              </div>

              <div className="col-span-2 lg:col-span-1">
                <div className="flex items-center gap-2 text-closed-shutter-300/70">
                  <Personalcard className="size-[1.125rem]" variant="Broken" />
                  <span>Client ID</span>
                </div>
                <span className="font-bold ps-6 text-closed-shutter-300 line-clamp-1">{applicationData.client_id}</span>
              </div>

              <div className="col-span-2">
                <div className="flex items-center gap-2 text-closed-shutter-300/70">
                  <SecuritySafe className="size-[1.125rem]" variant="Broken" />
                  <span>Secret key</span>
                </div>
                {/* <span className="overflow-x-auto font-bold ps-6 text-closed-shutter-300 line-clamp-1">{applicationData.client_secret}</span> */}
                <span onClick={handleCopySecret} className="w-full overflow-x-auto font-bold transition-colors cursor-pointer ps-6 text-closed-shutter-300 line-clamp-1 hover:text-gorgonzola-blue-500">
                  {applicationData.client_secret}
                </span>
              </div>

              {/* <ApiKeySection accessToken={accessToken!} ApiKey={ApiKey} onTokenGenerated={(token) => setApiKey(token)} /> */}
              {applicationData.api_key ? (
                <div className="col-span-2">
                  <div className="flex gap-2 items -center text-closed-shutter-300/70">
                    <Key className="size-[1.125rem]" variant="Broken" />
                    <span>API Key</span>
                  </div>
                  <span
                    onClick={() => navigator.clipboard.writeText(applicationData.api_key!)}
                    className="w-full overflow-x-auto font-bold transition-colors cursor-pointer ps-6 text-closed-shutter-300 line-clamp-1 hover:text-gorgonzola-blue-500"
                  >
                    {applicationData.api_key}
                  </span>
                </div>
              ) : // <div className="mt-2 ps-6">
              //   <MainButton type="secondary" onClick={handleCreateApiKey} customClass="w-fit">
              //     Generate API Key
              //   </MainButton>
              // </div>
              null}
            </div>

            <div className="flex flex-col-reverse items-center justify-between w-full gap-4 p-4 lg:gap-0 lg:flex-row bg-gorgonzola-blue-50/50 *:w-full lg:*:w-fit">
              <MainButton type="secondary" onClick={handleRefreshApplication} customClass="w-full justify-center lg:justify-start ">
                <Refresh className="size-6" variant="Broken" />
                <span>Refresh Application</span>
              </MainButton>

              <div className="flex flex-col lg:flex-row items-center justify-between w-full gap-4 lg:gap-6 lg:w-fit *:w-full lg:*:w-fit">
                <MainButton type="primary" to="https://api.shaku.tech/docs.html" passHref passHrefTarget="_blank" customClass="w-full justify-center lg:justify-start ">
                  <DocumentText1 className="size-6" variant="Broken" />
                  <span>Document</span>
                </MainButton>

                {!applicationData?.api_key && (
                  <MainButton type="primary" onClick={handleCreateApiKey} customClass="w-full justify-center lg:justify-start ">
                    <Key className="size-6" variant="Broken" />
                    <span>Generate API Key</span>
                  </MainButton>
                )}

                <MainButton type="primary" onClick={copyJsonToClipboard} customClass="w-full justify-center lg:justify-start ">
                  <DocumentCode className="size-6" variant="Broken" />
                  <span>Copy Json</span>
                </MainButton>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center w-full p-4 border rounded-md gap-13 border-gorgonzola-blue-50">
            <div className="flex flex-col items-center w-full gap-8">
              <img src="/assets/image/application/create-application.png" alt="create application" className="w-[28rem]" />

              <div className="space-y-6 text-xs text-center text-closed-shutter-300">
                <p>"You can create an application that provides a client ID and client secret for use in your services."</p>
                <p>
                  “You need to purchase a plan; please visit the plans page and select one of our{" "}
                  <span className="underline text-gorgonzola-blue-300">
                    <Link to={"/dashboard/plans"}>service plans</Link>
                  </span>{" "}
                  to purchase."
                </p>
              </div>
            </div>

            <MainButton type="primary" onClick={handleCreateApplication}>
              <span>Create Application</span>
              <CodeCircle className="size-6" variant="Broken" />
            </MainButton>
          </div>
        )}

        <ReusableModal isOpen={isRefreshModalOpen} onClose={() => setIsRefreshModalOpen(false)}>
          <div className="flex flex-col items-center gap-6 bg-white rounded-xl p-4 lg:p-6 w-fit max-w-[22.375rem]">
            {loading ? (
              <div className="flex flex-col items-center gap-6 bg-white rounded-xl w-fit">
                <div className="border-4 rounded-full size-16 border-gorgonzola-blue-300 border-t-transparent animate-spin" />
              </div>
            ) : (
              <>
                <img src="/assets/image/application/refresh-application-modal.svg" alt="Refresh Application" className="min-h-[11.625rem]" />
                <div className="text-center">
                  <h2 className="text-closed-shutter-300">Refresh Application</h2>
                  <p className="text-xs text-closed-shutter-300/70">Are you sure you want to refresh this application? This will generate new credentials.</p>
                </div>
                <div className="flex justify-end space-x-4">
                  <MainButton type="ghostOutlined" onClick={() => setIsRefreshModalOpen(false)}>
                    Cancel
                  </MainButton>
                  <MainButton type="secondary" onClick={confirmRefreshApplication}>
                    <Refresh className="size-6" variant="Broken" />
                    Confirm Refresh
                  </MainButton>
                </div>
              </>
            )}
          </div>
        </ReusableModal>
      </div>
    </div>
  );
};

export default Application;
