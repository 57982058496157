import React from "react";
import { ClothingItem } from "../../../utilities/types/free-trial/auto-tagging-service/types";
import { useVisualClothesServiceContext } from "../../../contexts/VisualClothesServiceContext";

interface CroppedImageDisplayProps {
  clothingItem: ClothingItem;
}

const CroppedImageDisplay: React.FC<CroppedImageDisplayProps> = ({ clothingItem }) => {
  const { uploadedImage, getAdjustedCoordinates, imageDimensions, scaleFactor } = useVisualClothesServiceContext();

  if (!uploadedImage || !imageDimensions || !scaleFactor) return null;

  const adjustedCoords = getAdjustedCoordinates(clothingItem.Region);
  const CONTAINER_SIZE = 40;

  const cropWidth = ((adjustedCoords.X2 - adjustedCoords.X1) * imageDimensions.width) / 100;
  const cropHeight = ((adjustedCoords.Y2 - adjustedCoords.Y1) * imageDimensions.height) / 100;

  const aspectRatio = cropWidth / cropHeight;

  let displayWidth = CONTAINER_SIZE;
  let displayHeight = CONTAINER_SIZE;

  if (aspectRatio > 1) {
    displayHeight = CONTAINER_SIZE / aspectRatio;
  } else {
    displayWidth = CONTAINER_SIZE * aspectRatio;
  }

  const scaleX = displayWidth / cropWidth;
  const scaleY = displayHeight / cropHeight;

  const scale = Math.max(scaleX, scaleY);

  const x = (adjustedCoords.X1 * imageDimensions.width) / 100;
  const y = (adjustedCoords.Y1 * imageDimensions.height) / 100;

  return (
    <div
      className="relative overflow-hidden rounded-md bg-gorgonzola-blue-50"
      style={{
        width: `${CONTAINER_SIZE}px`,
        height: `${CONTAINER_SIZE}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: `${displayWidth}px`,
          height: `${displayHeight}px`,
          // overflow: "hidden",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: `${imageDimensions.width}px`,
            height: `${imageDimensions.height}px`,
            backgroundImage: `url(data:image/jpeg;base64,${uploadedImage})`,
            backgroundSize: `${imageDimensions.width}px ${imageDimensions.height}px`,
            transform: `scale(${scale}) translate(${-x}px, ${-y}px)`,
            transformOrigin: "0 0",
          }}
        />
      </div>
    </div>
  );
};

export default CroppedImageDisplay;
