import MobileNavbar from "./MobileNavbar";
import NavbarLeftSide from "./NavbarLeftSide";
import NavbarRightSide from "./NavbarRightSide";

const Navbar = () => {
  return (
    <nav className="w-full">
      <div className="hidden sticky inset-0 lg:flex justify-between px-10 py-4 border-b border-gorgonzola-blue-100 bg-[#F9F9FA] z-50">
        <NavbarLeftSide />
        <NavbarRightSide />
      </div>
      <div className="lg:hidden sticky inset-0 flex justify-between py-4 border-b border-gorgonzola-blue-100 bg-[#F9F9FA] z-50">
        <MobileNavbar />
      </div>
    </nav>
  );
};

export default Navbar;
