import instance from "./axiosInstance";

// Define the input parameters type
interface GetRequestChartsParams {
  from_date?: string;
  to_date?: string;
  point?: "daily" | "monthly";
  filter?: "error" | "success";
  client_timezone?: string;
}

interface RequestChartsResponse {
  ok: boolean;
  message: string;
  data: RequestChartsTestResponse;
  status_code: number;
}
interface RequestChartsTestResponse {
  chart: DataEntry[];
}
export interface DataEntry {
  period: number;
  total: number;
  success: number;
  error: number;
}

export const getRequestCharts = async (accessToken: string, params: GetRequestChartsParams = {}): Promise<RequestChartsResponse> => {
  try {
    instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    const response = await instance.get<RequestChartsResponse>("/api/v1/dashboard/requests_charts", {
      params: {
        from_date: params.from_date,
        to_date: params.to_date,
        point: params.point,
        filter: params.filter,
        client_timezone: params.client_timezone,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching request charts:", error);

    throw error;
  }
};
