import Dashboard from "../../../components/Dashboard";
import NotFound from "../../../components/NotFound";
import FreeTrial from "../../../components/free-trial/FreeTrial";
import FreeTrialDetails from "../../../components/free-trial/FreeTrialDetails";
import Tutorial from "../../../components/tutorial/Tutorial";
import Application from "../../../components/application/Application";
import PaymentProvider from "../../../components/payment/PaymentProvider";
import Profile from "../../../components/profile/Profile";
import Plans from "../../../components/plan/Plans";
import PasswordReset from "../../../components/auth/PasswordReset";
import Register from "../../../components/auth/Register";
import Login from "../../../components/auth/Login";
import TutorialPost from "../../../components/tutorial/TutorialPost";
import UsageProvider from "../../../components/usage/UsageProvider";
import InvoiceDetails from "../../../components/payment/InvoiceDetails";
import Support from "../../../components/support/Support";
import SupportChat from "../../../components/support/SupportChat";

export const routes = {
  login: {
    path: "/auth/login",
    component: Login,
  },
  register: {
    path: "/auth/register",
    component: Register,
  },
  passwordReset: {
    path: "/auth/password-reset",
    component: PasswordReset,
  },
  dashboard: {
    path: "/dashboard",
    component: Dashboard,
  },
  FreeTrial: {
    path: "/dashboard/free-trial",
    component: FreeTrial,
  },
  FreeTrialDetails: {
    path: "/dashboard/free-trial/:id",
    component: FreeTrialDetails,
  },
  application: {
    path: "/dashboard/application",
    component: Application,
  },
  usage: {
    path: "/dashboard/usage",
    component: UsageProvider,
  },
  payment: {
    path: "/dashboard/payment",
    component: PaymentProvider,
  },
  invoiceDetails: {
    path: "/dashboard/payment/:id",
    component: InvoiceDetails,
  },
  profile: {
    path: "/dashboard/profile",
    component: Profile,
  },
  Tutorial: {
    path: "/dashboard/tutorial",
    component: Tutorial,
  },
  TutorialPost: {
    path: "/dashboard/tutorial/:id",
    component: TutorialPost,
  },
  support: {
    path: "/dashboard/support",
    component: Support,
  },
  supportChat: {
    path: "/dashboard/support/:id",
    component: SupportChat,
  },
  plan: {
    path: "/dashboard/plans",
    component: Plans,
  },
  notFound: {
    path: "*",
    component: NotFound,
  },
};


