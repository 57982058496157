export enum UserRole {
  ADMIN = "admin",
  OWNER = "owner",
  USER = "user",
}

export interface UserProfile {
  id: number;
  user_type: string;
  user_id: number;
  file: string;
  created_at: string;
  updated_at: string;
  profile_link: string;
}

export interface BusinessInfo {
  business_email: string;
  company: string;
  legal: {
    value: boolean;
    label: string;
  };
  tax_number: string;
  city: string;
  country: string;
  postal_code: string;
  address: string;
}

export interface UserBusiness {
  id: number;
  info: BusinessInfo;
}

export interface UserPlan {
  id: number;
  name: string;
  label: string;
  price: number;
}

export interface UserInfo {
  id: number;
  email: string;
  first_name: string | null;
  last_name: string | null;
  profile: UserProfile;
  phone_number: string | null;
  business: UserBusiness | null;
  plans: UserPlan[];
  can_change_password: boolean;
  tour_guide_passed: boolean;
  role_info: UserRole;
  created_at: number;
  updated_at: number;
}

export interface UserInfoResponse {
  ok: boolean;
  message: string;
  data: UserInfo;
  status_code: number;
}
