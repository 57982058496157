import React, { useState, useEffect, forwardRef } from "react";
import styles from "../../styles/text-field/text-field.module.css";
import { Eye, EyeSlash } from "iconsax-react";

interface TextFieldProps {
  name?: string;
  type?: "text" | "email" | "password" | "textArea";
  placeholder: string;
  label: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  success?: boolean;
  showPasswordHint?: boolean;
  customClass?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const TextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextFieldProps>(
  (
    {
      label,
      placeholder,
      value,
      onChange,
      onKeyDown,
      onClick,
      disabled = false,
      error = false,
      success = false,
      type = "text",
      name = "",
      customClass = "",
      showPasswordHint = false,
      required = false,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState({
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecialChar: false,
      isLongEnough: false,
    });

    useEffect(() => {
      return () => setIsFocused(false);
    }, []);

    useEffect(() => {
      if (type === "password" && showPasswordHint) {
        setPasswordStrength({
          hasUppercase: /[A-Z]/.test(value),
          hasLowercase: /[a-z]/.test(value),
          hasNumber: /\d/.test(value),
          hasSpecialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]/.test(value),
          isLongEnough: value.length >= 10,
        });
      }
    }, [value, type, showPasswordHint]);

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    const getInputClasses = () => {
      let baseClasses = `${styles.base} `;

      if (isFocused) {
        baseClasses += ` ${styles.focused}`;
      }

      if (error) {
        baseClasses += ` ${styles.error}`;
      } else if (success && type === "password") {
        baseClasses += ` ${styles.success}`;
      }

      if (customClass) {
        baseClasses += ` ${customClass}`;
      }

      return baseClasses;
    };

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className="relative flex flex-col gap-1">
        <label htmlFor={name} className={styles.label}>
          <span className={styles.labelText}>{label}</span>
          {required && <span className={styles.labelAsterisk}>*</span>}
        </label>

        <div className="relative w-full">
          {type === "textArea" ? (
            <textarea
              id={name}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange as React.ChangeEventHandler<HTMLTextAreaElement>}
              onKeyDown={onKeyDown as React.KeyboardEventHandler<HTMLTextAreaElement>}
              onClick={onClick as React.MouseEventHandler<HTMLTextAreaElement>}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={disabled}
              className={`${getInputClasses()} !pt-4`}
              rows={10}
              ref={ref as React.Ref<HTMLTextAreaElement>}
              maxLength={500}
            ></textarea>
          ) : (
            <input
              id={name}
              type={type === "password" && showPassword ? "text" : type}
              name={name}
              placeholder={placeholder}
              value={value}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
              onKeyDown={onKeyDown as React.KeyboardEventHandler<HTMLInputElement>}
              onClick={onClick as React.MouseEventHandler<HTMLInputElement>}
              disabled={disabled}
              className={getInputClasses()}
              ref={ref as React.Ref<HTMLInputElement>}
              maxLength={50}
              {...(type === "password" ? { autoComplete: "new-password" } : {})}
              autoSave="off"
            />
          )}
          {type === "password" ? (
            <button type="button" onClick={togglePasswordVisibility} className="absolute transform -translate-y-1/2 right-3 top-1/2 bg-[#F9F9FA]">
              {showPassword ? <EyeSlash className="size-6" variant="Broken" /> : <Eye className="size-6" variant="Broken" />}
            </button>
          ) : null}
        </div>

        {showPasswordHint ? (
          <div className="mt-2 space-y-2 text-[10px]">
            <p>Require at least one:</p>
            <ul className="pl-5 space-y-1 leading-4 list-disc">
              <li className={!value ? "text-closed-shutter-300" : !passwordStrength.hasUppercase || !passwordStrength.hasLowercase ? "text-english-vermillion-300" : ""}>
                Uppercase and lowercase letter (A,z)
              </li>
              <li className={!value ? "text-closed-shutter-300" : !passwordStrength.hasNumber ? "text-english-vermillion-300" : ""}>Numeric character (0-9)</li>
              <li className={!value ? "text-closed-shutter-300" : !passwordStrength.hasSpecialChar ? "text-english-vermillion-300" : ""}>Special character</li>
              <li className={!value ? "text-closed-shutter-300" : !passwordStrength.isLongEnough ? "text-english-vermillion-300" : ""}>At least 10 characters long</li>
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
);

export default TextField;
