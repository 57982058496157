import PaymentsAnalytics from "../../../components/admin/analytics/PaymentsAnalytics";
import PlansAnalytics from "../../../components/admin/analytics/PlansAnalytics";
import UsageAnalytics from "../../../components/admin/analytics/UsageAnalytics";
import AdminDashboard from "../../../components/admin/dashboard/AdminDashboard";
import AdminProfile from "../../../components/admin/profile/AdminProfile";
import TicketsList from "../../../components/admin/tickets/TicketsList";
import UserPayments from "../../../components/admin/users/UserPayments";
import UserPlans from "../../../components/admin/users/UserPlans";
import UserProfile from "../../../components/admin/users/UserProfile";
import UsersList from "../../../components/admin/users/UsersList";
import UserUsage from "../../../components/admin/users/UserUsage";
import ContactsList from "../../../components/admin/contact/ContactsList";
import TicketChat from "../../../components/admin/tickets/TicketChat";

export const adminRoutes = {
  dashboard: {
    path: "/admin",
    component: AdminDashboard,
  },
  users: {
    list: {
      path: "/admin/users",
      component: UsersList,
    },
    profile: {
      path: "/admin/users/:userId",
      component: UserProfile,
    },
    payments: {
      path: "/admin/users/:userId/payments",
      component: UserPayments,
    },
    plans: {
      path: "/admin/users/:userId/plans",
      component: UserPlans,
    },
    usage: {
      path: "/admin/users/:userId/usage",
      component: UserUsage,
    },
  },
  analytics: {
    usage: {
      path: "/admin/analytics/usage",
      component: UsageAnalytics,
    },
    plans: {
      path: "/admin/analytics/plans",
      component: PlansAnalytics,
    },
    payments: {
      path: "/admin/analytics/payments",
      component: PaymentsAnalytics,
    },
  },
  tickets: {
    list: {
      path: "/admin/tickets",
      component: TicketsList,
    },
    chat: {
      path: "/admin/tickets/:ticketId",
      component: TicketChat,
    },
  },
  profile: {
    path: "/admin/profile",
    component: AdminProfile,
  },
  contact: {
    path: "/admin/contact",
    component: ContactsList,
  },
};
