import { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "../utilities/defaults/routes/routes";
import { adminRoutes } from "../utilities/defaults/routes/adminRoutes";
import DashboardTemplate from "./template/DashboardTemplate";
import ProtectedRoute from "./ProtectedRoute";
import InitialRouteHandler from "./InitialRouteHandler";
import AdminRoute from "./AdminRoute";

const AppRoutes: FC = () => {
  return (
    <Routes>
      {/* Initial route handler */}
      <Route path="/" element={<InitialRouteHandler />} />

      {/* Auth routes */}
      <Route path="/auth">
        <Route index element={<Navigate to="/auth/login" replace />} />
        <Route path="login" element={<routes.login.component />} />
        <Route path="register" element={<routes.register.component />} />
        <Route path="password-reset" element={<routes.passwordReset.component />} />
      </Route>

      {/* Protected user routes */}
      <Route element={<ProtectedRoute />}>
        <Route path={routes.dashboard.path} element={<DashboardTemplate />}>
          <Route index element={<routes.dashboard.component />} />
          <Route path={routes.FreeTrial.path} element={<routes.FreeTrial.component />} />
          <Route path={routes.FreeTrialDetails.path} element={<routes.FreeTrialDetails.component />} />
          <Route path={routes.application.path} element={<routes.application.component />} />
          <Route path={routes.usage.path} element={<routes.usage.component />} />
          <Route path={routes.payment.path} element={<routes.payment.component />} />
          <Route path={routes.invoiceDetails.path} element={<routes.invoiceDetails.component />} />
          <Route path={routes.profile.path} element={<routes.profile.component />} />
          <Route path={routes.Tutorial.path} element={<routes.Tutorial.component />} />
          <Route path={routes.TutorialPost.path} element={<routes.TutorialPost.component />} />
          <Route path={routes.support.path} element={<routes.support.component />} />
          <Route path={routes.supportChat.path} element={<routes.supportChat.component />} />
          <Route path={routes.plan.path} element={<routes.plan.component />} />{" "}
        </Route>
      </Route>

      <Route element={<AdminRoute />}>
        <Route path={adminRoutes.dashboard.path} element={<DashboardTemplate />}>
          <Route index element={<adminRoutes.dashboard.component />} />

          {/* Users routes */}
          <Route path="users">
            <Route index element={<adminRoutes.users.list.component />} />
            <Route path=":userId" element={<adminRoutes.users.profile.component />} />
            <Route path=":userId/payments" element={<adminRoutes.users.payments.component />} />
            <Route path=":userId/plans" element={<adminRoutes.users.plans.component />} />
            <Route path=":userId/usage" element={<adminRoutes.users.usage.component />} />
          </Route>


          {/* Analytics routes */}
          <Route path="analytics">
            <Route path="usage" element={<adminRoutes.analytics.usage.component />} />
            <Route path="plans" element={<adminRoutes.analytics.plans.component />} />
            <Route path="payments" element={<adminRoutes.analytics.payments.component />} />
          </Route>

          {/* Tickets routes */}
          <Route path="tickets">
            <Route index element={<adminRoutes.tickets.list.component />} />
            <Route path=":ticketId" element={<adminRoutes.tickets.chat.component />} />
          </Route>

          {/* Settings routes */}
          <Route path="profile" element={<adminRoutes.profile.component />} />
          <Route path="contact" element={<adminRoutes.contact.component />} />
        </Route>
      </Route>

      {/* 404 route */}
      <Route path="*" element={<routes.notFound.component />} />
    </Routes>
  );
};

export default AppRoutes;
