import React, { useState } from "react";
import { useGlobalInfo } from "../../../contexts/GlobalInfoContext";
import BasicInformation from "../../profile/profile-tabs/BasicInformation";
import Password from "../../profile/profile-tabs/Password";
import MainButton from "../../button/MainButton";
import { ADMIN_PROFILE_TABS as adminProfileTabs } from "../../../utilities/defaults/adminProfile/AdminProfileTabs";

const AdminProfile: React.FC = () => {
  const { userInfo } = useGlobalInfo();
  const [activeAdminProfileTabs, setActiveAdminProfileTabs] = useState(0);

  const handleAdminProfileTabsClick = (index: number) => {
    setActiveAdminProfileTabs(index);
  };

  const renderAdminProfileTab = () => {
    switch (activeAdminProfileTabs) {
      case 0:
        return <BasicInformation />;
      case 1:
        if (userInfo?.can_change_password) {
          return <Password />;
        } else {
          break;
        }
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="w-full p-4 md:p-6">
        <h2 className="font-bold text-closed-shutter-300">My Admin Profile</h2>
      </div>

      <div className="p-4 md:p-6">
        <div className="flex flex-col gap-13">
          <div className="flex gap-6 *:min-w-fit overflow-x-auto">
            {adminProfileTabs.map((adminProfileTab, index) =>
              !userInfo?.can_change_password && adminProfileTab.title === "Password" ? null : (
                <MainButton key={index} type={index === activeAdminProfileTabs ? "primary" : "secondary"} onClick={() => handleAdminProfileTabsClick(index)}>
                  {adminProfileTab.title}
                </MainButton>
              )
            )}
          </div>
          {renderAdminProfileTab()}
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
