import React, { useState } from "react";
import MainButton from "../../button/MainButton";
import { Eye } from "iconsax-react";
import ContactModal from "./ContactModal";
import TransformPublishedAt from "../../../utilities/helper/TransformPublishedAt";

interface MobileContactCardProps {
  contact: any;
}

const MobileContactCard: React.FC<MobileContactCardProps> = ({ contact }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex flex-col items-center px-4 text-center *:w-full *:py-6 *:border-b *:border-b-gorgonzola-blue-100 text-closed-shutter-300">
      <span className="font-medium line-clamp-1">{contact.attributes.email}</span>

      <div className="grid grid-cols-2 gap-2 ">
        <div>
          <p className="text-sm text-gray-500">First Name</p>
          <p>{contact.attributes.first_name}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Last Name</p>
          <p>{contact.attributes.last_name}</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2">
        <div>
          <p className="text-sm text-gray-500">Date</p>
          <p>{TransformPublishedAt(contact.attributes.createdAt)}</p>
        </div>

        <div className="flex items-center justify-center w-full">
          <MainButton type="ghostOutlined" onClick={() => setIsModalOpen(true)}>
            <Eye variant="Broken" className="mr-2 size-4" />
            See
          </MainButton>
        </div>
      </div>

      <ContactModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} contact={contact} />
    </div>
  );
};

export default MobileContactCard;
