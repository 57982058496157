import React, { useEffect } from "react";
import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
import MainButton from "../../../button/MainButton";
import { CloudChange, DocumentUpload } from "iconsax-react";
import BodyMeasurementServicePagination from "../BodyMeasurementServicePagination";

const UploadPic: React.FC = () => {
  const { frontImage, sideImage, handleFrontImageUpload, handleSideImageUpload, setCanProceedToNextStep } = useBodyMeasurementServiceContext();

  // Handle the file change event for both front and side images
  const handleFrontImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      handleFrontImageUpload(files[0]);
    }
  };

  const handleSideImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      handleSideImageUpload(files[0]);
    }
  };

  // Check if the user has uploaded both images and set the canProceedToNextStep flag accordingly
  useEffect(() => {
    setCanProceedToNextStep(frontImage !== null && sideImage !== null);
  }, [frontImage, sideImage, setCanProceedToNextStep]);

  return (
    <div className="flex flex-col items-center w-full gap-8 md:gap-32">
      <div className="flex flex-col w-fit xl:flex-row justify-center gap-4 *:flex *:flex-col md:*:flex-row *:items-center md:*:justify-center xl:*:justify-between *:gap-4 *:rounded-md *:border *:border-gorgonzola-blue-50 *:p-4 lg:*:min-w-[30rem]">
        <div>
          <div className="w-[9.375rem] h-[17.375rem] border-8 rounded-md border-gorgonzola-blue-50 overflow-hidden *:max-h-full">
            {frontImage ? (
              <img src={`data:image/png;base64,${frontImage}`} alt="Front Style" className="object-contain w-full h-full" />
            ) : (
              <img src="/assets/image/free-trial/body-measurment/temp-front-body-measurment.png" alt="temp-front-body-measurment" className="object-contain w-full h-full p-6" />
            )}
          </div>
          <div className="flex flex-col justify-between gap-4 min-w-[15.125rem] md:max-w-[25rem]">
            <div className="flex flex-col w-full gap-4">
              <div className="flex gap-2 font-bold text-gorgonzola-blue-300">
                <span className="grid rounded-full bg-gorgonzola-blue-50 min-w-6 size-6 place-content-center">1</span>
                {/* <h2>Front Style Image</h2> */}
                <h2>For a Front Side Image</h2>
              </div>
              <ul className="space-y-1 text-xs list-disc ps-6 text-closed-shutter-300">
                {/* <li>Full body visible in image</li>
                <li>Stand facing camera directly</li>
                <li>Stand straight, no slouching</li>
                <li>Wear fitted clothing, not loose</li>
                <li>Arms slightly apart from body</li>
                <li>No shoes in the image</li>
                <li>Limit image size to a maximum of 5MB.</li> */}
                <li>Ensure the full body is visible.</li>
                <li>Stand directly facing the camera.</li>
                <li>Stand straight; don't slouch.</li>
                <li>Wear fitted clothing, not loose.</li>
                <li>Keep arms slightly apart from the body.</li>
                <li>Ensure no shoes are included in the image.</li>
                <li>Limit image size to a maximum of 5MB.</li>
              </ul>
            </div>

            <div className="flex flex-col w-full gap-2">
              <MainButton type="primary" customClass="justify-center !p-0">
                <label htmlFor="FrontImagefile" className="z-50 flex items-center justify-center h-full min-w-full gap-2 cursor-pointer">
                  {frontImage ? <CloudChange variant="Broken" /> : <DocumentUpload variant="Broken" />}
                  <span>{frontImage ? "Change Image" : "Upload Image"}</span>
                </label>
              </MainButton>
              <input id="FrontImagefile" type="file" className="hidden" onChange={handleFrontImageChange} />

              {/* <MainButton type="secondary" customClass="justify-center">
                <Camera variant="Broken" />
                <span>Camera</span>
              </MainButton> */}
            </div>
          </div>
        </div>

        <div>
          <div className="w-[9.375rem] h-[17.375rem]  border-8 rounded-md  border-gorgonzola-blue-50 overflow-hidden *:max-h-full">
            {sideImage ? (
              <img src={`data:image/png;base64,${sideImage}`} alt="Side Style" className="object-contain w-full h-full" />
            ) : (
              <img src="/assets/image/free-trial/body-measurment/temp-side-body-measurment.png" alt="temp-side-body-measurment" className="object-contain w-full h-full p-6" />
            )}
          </div>
          <div className="flex flex-col justify-between gap-4 min-w-[15.125rem] md:max-w-[25rem]">
            <div className="flex flex-col w-full gap-4">
              <div className="flex gap-2 font-bold text-gorgonzola-blue-300">
                <span className="grid rounded-full bg-gorgonzola-blue-50 size-6 place-content-center">2</span>
                {/* <h2>Side Style Image</h2> */}
                <h2>For a Side Image</h2>
              </div>
              <ul className="space-y-1 text-xs list-disc ps-6 text-closed-shutter-300">
                {/* <li>Full body visible in image</li>
                <li>Stand in full profile (side view)</li>
                <li>Stand straight, no slouching</li>
                <li>Wear fitted clothing, not loose</li>
                <li>No shoes in the image</li>
                <li>Limit image size to a maximum of 5MB.</li> */}
                <li>Ensure the full body is visible.</li>
                <li>Stand in full profile (side view).</li>
                <li>Stand straight; don't slouch.</li>
                <li>Wear fitted clothing, not loose.</li>
                <li>Ensure no shoes are included in the image.</li>
                <li>Limit image size to a maximum of 5MB.</li>
              </ul>
            </div>

            <div className="flex flex-col w-full gap-2">
              <MainButton type="primary" customClass="justify-center !p-0">
                <label htmlFor="SideImagefile" className="z-50 flex items-center justify-center h-full min-w-full gap-2 cursor-pointer">
                  {sideImage ? <CloudChange variant="Broken" /> : <DocumentUpload variant="Broken" />}
                  <span>{sideImage ? "Change Image" : "Upload Image"}</span>
                </label>
              </MainButton>
              <input id="SideImagefile" type="file" className="hidden" onChange={handleSideImageChange} />

              {/* <MainButton type="secondary" customClass="justify-center">
                <Camera variant="Broken" />
                <span>Camera</span>
              </MainButton> */}
            </div>
          </div>
        </div>
      </div>

      <BodyMeasurementServicePagination />
    </div>
  );
};

export default UploadPic;
