import React from "react";
import ReusableModal from "../../modal/ReusableModal";
import MainButton from "../../button/MainButton";
import { ArrowLeft, Printer } from "iconsax-react";
import TransformPublishedAt, { TransformTime } from "../../../utilities/helper/TransformPublishedAt";

interface ContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  contact: any;
}

const ContactModal: React.FC<ContactModalProps> = ({ isOpen, onClose, contact }) => {
  if (!contact) {
    return null;
  }

  const handlePrint = () => {
    window.print();
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="flex flex-col items-center gap-8 bg-white p-0 md:p-6 rounded-md w-full md:min-w-[33.5625rem] md:max-w-[90%] max-h-[90%] overflow-y-auto">
        <div className="grid w-full grid-cols-1 gap-y-4 md:gap-y-0 md:gap-x-4 md:grid-cols-3">
          <div className="flex flex-col items-center col-span-2 gap-4">
            <div className="flex flex-col gap-1 p-4 bg-[#ededfa80] w-full rounded border border-gorgonzola-blue-100 *:flex *:flex-col *:gap-1">
              <div>
                <h5 className="text-2xs text-closed-shutter-300/70 line-clamp-1">Email:</h5>
                <span className="text-xs capitalize text-gorgonzola-blue-300 ">{contact.attributes.email ? contact.attributes.email : "---"}</span>
              </div>
              <div>
                <h5 className="capitalize text-2xs text-closed-shutter-300/70">Phone Number:</h5>
                <span className="text-xs text-closed-shutter-300 line-clamp-1">{contact.attributes.first_name ? contact.attributes.first_name : "---"}</span>
              </div>
              <div>
                <h5 className="capitalize text-2xs text-closed-shutter-300/70">Name:</h5>
                <span className="text-xs capitalize text-closed-shutter-300">{contact.attributes.last_name ? contact.attributes.last_name : "---"}</span>
              </div>
              <div>
                <h5 className="capitalize text-2xs text-closed-shutter-300/70">Last name:</h5>
                <span className="text-xs capitalize text-closed-shutter-300">{contact.attributes.last_name ? contact.attributes.last_name : "---"}</span>
              </div>
              <div>
                <h5 className="capitalize text-2xs text-closed-shutter-300/70">Company Website:</h5>
                <span className="text-xs capitalize text-closed-shutter-300">{contact.attributes.website ? contact.attributes.website : "---"}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-between w-full p-4 border rounded border-gorgonzola-blue-100 min-h-max">
            <div className="flex flex-col gap-1 p-3 bg-[#ededfa80] rounded">
              <h5 className="text-2xs text-closed-shutter-300/70">Date:</h5>
              <span className="text-xs capitalize text-closed-shutter-300">{TransformPublishedAt(contact.attributes.updatedAt)}</span>
            </div>
            <span className="w-full h-px my-4 bg-gorgonzola-blue-100" />
            <div className="flex flex-col gap-1 p-3 bg-[#ededfa80] rounded">
              <h5 className="text-2xs text-closed-shutter-300/70">Time:</h5>
              <span className="text-xs capitalize text-closed-shutter-300">{TransformTime(contact.attributes.updatedAt)}</span>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="flex items-start justify-start w-full gap-1 p-3 mb-2 overflow-hidden border rounded-md text-2xs border-closed-shutter-200 bg-closed-shutter-50">
            <p className="flex-grow text-xs font-bold text-left text-closed-shutter-300">description: {contact.attributes.description}</p>
          </div>
        </div>

        <div className="flex justify-between w-full gap-8">
          <MainButton type="secondary" onClick={onClose}>
            <ArrowLeft className="size-6" variant="Broken" />
            Close
          </MainButton>
          <MainButton type="primary" onClick={handlePrint}>
            Print
            <Printer className="size-6" variant="Broken" />
          </MainButton>
        </div>
      </div>
    </ReusableModal>
  );
};

export default ContactModal;
