import React from "react";
import ReactPaginate from "react-paginate";
import { ArrowLeft, ArrowRight } from "iconsax-react";
import useDevice from "../../hooks/useDevice";
import MainButton from "../button/MainButton";

interface CustomPaginationProps {
  currentPage: number;
  totalPages: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  onPageChange: (newPage: number) => void;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ currentPage, totalPages, hasPreviousPage, hasNextPage, onPageChange }) => {
  const { isTablet } = useDevice();

  return (
    <div className="flex items-center justify-between w-full gap-1 p-2 md:gap-5 md:px-4 md:py-6">
      <MainButton type="ghostOutlined" disabled={!hasPreviousPage} onClick={() => onPageChange(currentPage - 1)}>
        <ArrowLeft className="size-4 md:size-6" variant="Broken" />
        <span className="hidden ml-2 md:block">Previous</span>
      </MainButton>

      <ReactPaginate
        pageCount={totalPages}
        pageRangeDisplayed={isTablet ? 1 : 3}
        marginPagesDisplayed={isTablet ? 1 : 2}
        forcePage={currentPage - 1}
        onPageChange={({ selected }) => onPageChange(selected + 1)}
        containerClassName="flex items-center w-fit justify-center gap-1 md:gap-2"
        previousLabel={null}
        nextLabel={null}
        pageClassName="flex justify-center rounded overflow-hidden"
        pageLinkClassName="relative flex items-center justify-center min-w-8 md:min-w-10 h-8 md:h-10 gap-1 md:gap-2 px-2 md:px-3 py-1 md:py-2 text-xs transition-colors border rounded duration-600 disabled:bg-closed-shutter-100 disabled:cursor-not-allowed disabled:text-closed-shutter-200 text-closed-shutter-300 hover:text-gorgonzola-blue-300 active:text-gorgonzola-blue-600 border-gorgonzola-blue-50 hover:border-gorgonzola-blue-100 active:border-gorgonzola-blue-200"
        activeClassName="!bg-gorgonzola-blue-50"
        breakLabel="..."
        breakClassName="px-1 md:px-2"
        disabledClassName="opacity-50 cursor-not-allowed"
      />

      <MainButton type="ghostOutlined" disabled={!hasNextPage} onClick={() => onPageChange(currentPage + 1)}>
        <span className="hidden mr-2 md:block">Next</span>
        <ArrowRight className="size-4 md:size-6" variant="Broken" />
      </MainButton>
    </div>
  );
};

export default CustomPagination;
