import React, { useEffect } from "react";
import CustomPagination from "../../table/CustomPagination";
import Loader from "../../loading/Loader";
import TicketsTable from "./TicketsTable";
import { useTickets } from "../../../contexts/TicketsContext";

const TicketsList: React.FC = () => {
  const { ticketsData, totalPages, currentPage, hasPreviousPage, hasNextPage, loading, fetchTicketsData } = useTickets();

  useEffect(() => {
    fetchTicketsData(currentPage);
  }, [fetchTicketsData, currentPage]);

  const handlePageChange = (newPage: number) => {
    fetchTicketsData(newPage);
  };

  return (
    <div className="w-full rounded-md">
      <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
        <div className="flex flex-col justify-between w-full gap-2.5 p-4 md:py-6 md:items-center md:flex-row md:gap-0">
          <h2 className="font-bold text-closed-shutter-300">Received tickets</h2>
        </div>

        <div className="w-full p-4 md:p-6">
          <div className="flex flex-col w-full border divide-y rounded-md divide-gorgonzola-blue-50 border-gorgonzola-blue-50">
            <div className="flex flex-col justify-between w-full gap-2.5 p-4 md:py-6 md:items-center md:flex-row md:gap-0">
              <h2 className="font-bold text-closed-shutter-300">Tickets Table</h2>
            </div>

            {loading ? (
              <div className="flex justify-center w-full p-4 md:p-6">
                <Loader />
              </div>
            ) : ticketsData.length > 0 ? (
              <>
                <TicketsTable ticketsData={ticketsData} />

                <CustomPagination currentPage={currentPage} totalPages={totalPages} hasPreviousPage={hasPreviousPage} hasNextPage={hasNextPage} onPageChange={handlePageChange} />
              </>
            ) : (
              <div className="p-6 text-center">
                <p className="text-closed-shutter-300">No tickets found</p>
                <p className="mt-2 text-xs text-closed-shutter-200">There are no tickets matching your filters.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketsList;
