import React, { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { TickCircle } from "iconsax-react";
import { verifyChangePassword } from "../../utilities/api/passwordService";
import OTPInput from "../OTPInput";
import MainButton from "../button/MainButton";
import ReusableModal from "./ReusableModal";

interface PasswordChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  currentPassword: string;
  newPassword: string;
  accessToken: string;
}

const PasswordChangeModal: React.FC<PasswordChangeModalProps> = ({ isOpen, onClose, onConfirm, currentPassword, newPassword, accessToken }) => {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleOtpChange = (value: string) => {
    setOtp(value);
    setOtpError(false);
  };

  const handleVerify = async () => {
    setIsVerifying(true);
    try {
      const response = await verifyChangePassword(accessToken, {
        current_password: currentPassword,
        new_password: newPassword,
        code: otp,
      });

      if (response.ok) {
        setIsSuccess(true);
        enqueueSnackbar("Password changed successfully!", { variant: "success" });
      } else {
        setOtpError(true);
        enqueueSnackbar(response.message || "Unable to verify password change. Please try again.", { variant: "error" });
      }
    } catch (error) {
      console.error("Error verifying password change:", error);
      enqueueSnackbar("An error occurred during password change verification. Please try again.", { variant: "error" });
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose}>
      {!isSuccess ? (
        <div className="relative flex flex-col items-center gap-6 p-6 max-w-[22.375rem]">
          <img src="/assets/image/password/change-password.svg" alt="change password" />
          <div className="space-y-2 text-center capitalize text-closed-shutter-300">
            <h2>Enter your code</h2>
            <p className="text-xs">
              We have sent a verification code to your
              <a href="mailto:" className="px-1 text-gorgonzola-blue-300">
                Email address
              </a>
              . Please enter your code down below
            </p>
          </div>
          <OTPInput length={6} onChange={handleOtpChange} error={otpError} />
          <div className="flex justify-end mt-6 space-x-4">
            <MainButton type="ghostOutlined" onClick={onClose}>
              Cancel
            </MainButton>
            <MainButton type="primary" onClick={handleVerify} disabled={otp.length !== 6 || isVerifying}>
              {isVerifying ? "Verifying..." : "Confirm"}
              <TickCircle className="size-6" variant="Broken" />
            </MainButton>
          </div>
        </div>
      ) : (
        <>
          <div className="relative flex flex-col items-center gap-6 p-6 max-w-[22.375rem]">
            <img src="/assets/image/password/change-password.svg" alt="change password" />
            <div className="space-y-2 text-center capitalize text-closed-shutter-300">
              <h2>Change Password , Done</h2>
              <p className="text-xs">We have sent a verification code to your email address. Please enter your code down below </p>
            </div>
          </div>
        </>
      )}
    </ReusableModal>
  );
};

export default PasswordChangeModal;
