import React from "react";
import { User } from "../../../utilities/types/admin/types";
import StatusBadge from "../../table/StatusBadge";

interface MobileUserCardProps {
  user: User;
  onClick: () => void;
}

const MobileUserCard: React.FC<MobileUserCardProps> = ({ user, onClick }) => {
  return (
    <div className="flex flex-col items-center px-4 text-center *:w-full *:py-6 *:border-b *:border-b-gorgonzola-blue-100 text-closed-shutter-300" onClick={onClick}>
      <span className="font-medium line-clamp-1">{user.email}</span>

      <div className="grid grid-cols-2 gap-2 ">
        <div>
          <p className="text-sm text-gray-500">Users Id(B)</p>
          <p>{user.id}</p>
        </div>

        <div>
          <p className="text-sm text-gray-500">Type user</p>
          <div className="flex justify-center w-full">
            <StatusBadge statusText={user.role_info.name.toLowerCase()} />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2 ">
        <div>
          <p className="text-sm text-gray-500">Total Requests</p>
          <p>{user.total_requests}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Plan</p>
          <div className="flex justify-center">
            <StatusBadge statusText={user.plan_status} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileUserCard;
