import { useEffect, useRef, useState } from "react";
import { ArrowDown2, Crown1 } from "iconsax-react";
import { motion, AnimatePresence } from "framer-motion";
import { useLogoutHandler } from "../modal/LogoutHandler";
import PlanButton from "../button/PlanButton";
import { Link } from "react-router-dom";
import { useGlobalInfo } from "../../contexts/GlobalInfoContext";
import useDevice from "../../hooks/useDevice";

const NavbarRightSide = () => {
  const { userInfo, loading, isAdmin } = useGlobalInfo();
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);
  const { handleLogout, LogoutModalComponent } = useLogoutHandler();
  const { isMobile } = useDevice();

  useEffect(() => {
    if (isMobile) {
      const handleClickOutside = (event: MouseEvent) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };

      document.addEventListener("click", handleClickOutside);
      return () => document.removeEventListener("click", handleClickOutside);
    } else {
      const handleMouseLeave = (event: MouseEvent) => {
        if (navbarRef.current && !navbarRef.current.contains(event.relatedTarget as Node)) {
          setIsOpen(false);
        }
      };

      const currentNavbarRef = navbarRef.current;
      if (currentNavbarRef) {
        currentNavbarRef.addEventListener("mouseleave", handleMouseLeave);
      }

      return () => {
        if (currentNavbarRef) {
          currentNavbarRef.removeEventListener("mouseleave", handleMouseLeave);
        }
      };
    }
  }, [isMobile]);

  const handleInteraction = (e: React.MouseEvent) => {
    if (isMobile) {
      e.stopPropagation();
      setIsOpen(!isOpen);
    }
  };

  if (loading) {
    return null;
  }

  const renderPlanButton = () => {
    if (!userInfo || !userInfo.plans || userInfo.plans.length === 0) {
      return <PlanButton type="free" title="Free Plan" to={`${isAdmin ? "/admin" : "/dashboard"}/plans`} icon={<Crown1 className="size-6" variant="Broken" />} />;
    }

    if (userInfo.plans.length === 1) {
      const plan = userInfo.plans[0];
      let type: "bodyMeasurement" | "autoTagging";
      let title: string;

      if (plan.label.includes("size_measurement")) {
        type = "bodyMeasurement";
        title = "Body Measurement";
      } else if (plan.label.includes("clothes_recognition")) {
        type = "autoTagging";
        title = "Auto Tagging";
      } else {
        return <PlanButton type="free" title="Free Plan" to={`${isAdmin ? "/admin" : "/dashboard"}/plans`} icon={<Crown1 className="size-6" variant="Broken" />} />;
      }

      return <PlanButton type={type} title={title} to={`${isAdmin ? "/admin" : "/dashboard"}/plans`} icon={<Crown1 className="size-6" variant="Broken" />} />;
    } else {
      return <PlanButton type="mixed" title="Premium Mixed" to={`${isAdmin ? "/admin" : "/dashboard"}/plans`} icon={<Crown1 className="size-6" variant="Broken" />} />;
    }
  };

  const avatarUrl = userInfo?.profile?.profile_link || "/assets/image/navbar/avatar.svg";

  return (
    <>
      <div className="flex items-center gap-6" ref={navbarRef}>
        <div
          className="relative flex items-center justify-between gap-1.5 md:gap-3 w-fit"
          onClick={handleInteraction}
          onMouseEnter={() => !isMobile && setIsOpen(true)}
          onMouseLeave={() => !isMobile && setIsOpen(false)}
        >
          <div className="flex items-center gap-2 cursor-pointer">
            <AnimatePresence mode="wait">
              <motion.div key={isOpen ? "open" : "closed"} initial={{ rotate: isOpen ? 90 : 0 }} animate={{ rotate: isOpen ? 0 : 90 }} transition={{ duration: 0.2 }}>
                <ArrowDown2 variant="Broken" />
              </motion.div>
            </AnimatePresence>

            {userInfo ? (
              <div className="flex-col items-start hidden md:flex">
                <h3 className="text-xs capitalize text-closed-shutter-300">
                  {userInfo.first_name || userInfo.last_name ? (
                    <>
                      {userInfo.first_name || ""} {userInfo.last_name || ""}
                    </>
                  ) : (
                    "Username"
                  )}
                </h3>
                <h4 className="text-2xs text-closed-shutter-300/70">{userInfo.email}</h4>
              </div>
            ) : null}
          </div>

          <div className="overflow-hidden rounded-full size-10 bg-gorgonzola-blue-50">
            <Link to={`${isAdmin ? "/admin" : "/dashboard"}/profile`} className="hidden md:block">
              <img src={avatarUrl} alt="User Avatar" className="object-contain w-full h-full" />
            </Link>
            <img src={avatarUrl} alt="User Avatar" className="object-contain w-full h-full md:hidden" />
          </div>

          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                className="absolute right-0 w-full pt-5 top-full  min-w-[13.5rem]"
              >
                <div className=" w-full p-4 rounded-2xl shadow-[0px_5px_15px_0px_rgba(0,_0,_0,_0.15)] flex flex-col overflow-hidden bg-[#F9F9FA] divide-y divide-gorgonzola-blue-50 z-[999]">
                  {!isAdmin ? <div className="pb-3 space-y-2">{renderPlanButton()}</div> : null}

                  <div>
                    <ul className="py-3 *:py-2 *:transition-colors *:duration-600 *:text-xs *:text-closed-shutter-300 hover:*:text-gorgonzola-blue-300 *:cursor-pointer">
                      {isAdmin ? (
                        <>
                          <li>
                            <Link to={"/admin/users"}>Users List</Link>
                          </li>
                          <li>
                            <Link to={"/admin/analytics/usage"}>All Usage</Link>
                          </li>
                          <li>
                            <Link to={"/admin/analytics/plans"}>All plan Buy</Link>
                          </li>
                          <li>
                            <Link to={"/admin/analytics/payments"}>All payments</Link>
                          </li>
                          <li>
                            <Link to={"/admin/tickets"}>Received tickets</Link>
                          </li>
                          <li>
                            <Link to={"/admin/profile"}>Profile</Link>
                          </li>
                          <li>
                            <Link to={"/admin/contact"}>Contact us</Link>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <Link to={"/dashboard/profile"}>Profile</Link>
                          </li>
                          <li>
                            <Link to={"/dashboard/payment"}>Payment</Link>
                          </li>
                          <li>
                            <Link to={"/dashboard/support"}>Support</Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>

                  <div>
                    <ul className="pt-3 *:transition-colors *:duration-600 *:cursor-pointer">
                      <li className="text-base text-english-vermillion-300 hover:text-english-vermillion-600" onClick={handleLogout}>
                        Log out
                      </li>
                    </ul>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {LogoutModalComponent}
      </div>
    </>
  );
};

export default NavbarRightSide;
