import React from "react";
import { Call, Flag, Sms } from "iconsax-react";
import StatusBadge from "../../../table/StatusBadge";
import ReusableModal from "../../../modal/ReusableModal";

interface UserInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  userDetail: any;
}

const UserInfoModal: React.FC<UserInfoModalProps> = ({ isOpen, onClose, userDetail }) => {
  return (
    <ReusableModal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-6 bg-white rounded-xl w-full min-w-[15rem] md:min-w-[22.375rem] ">
        <div className="flex flex-col items-center gap-4">
          <div className="overflow-hidden rounded-md size-20 bg-gorgonzola-blue-50">
            <img src={userDetail.profile ? userDetail.profile : "/assets/image/profile/avatar.svg"} alt="User Avatar" className="object-contain size-full" />
          </div>
          <h2 className="font-bold text-closed-shutter-300">User Information</h2>
        </div>

        <div className="flex flex-col gap-6 text-xs text-closed-shutter-300">
          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Name:</p>
            {userDetail.first_name || userDetail.last_name ? (
              <p className="line-clamp-1">
                {userDetail.first_name ? userDetail.first_name : null} {userDetail.last_name ? userDetail.last_name : null}
              </p>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Email:</p>
            {userDetail.email ? (
              <a href={`mailto:${userDetail.email}`} className="inline-flex items-center gap-2 hover:text-gorgonzola-blue-300 duration-600 w-full text-left line-clamp-1">
                <Sms className="size-[1.125rem]" variant="Broken" />
                <p className="line-clamp-1">{userDetail.email}</p>
              </a>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Business Email:</p>
            {userDetail.business_email ? (
              <a href={`mailto:${userDetail.business_email}`} className="inline-flex items-center gap-2 hover:text-gorgonzola-blue-300 duration-600 w-full text-left line-clamp-1">
                <Sms className="size-[1.125rem]" variant="Broken" />
                <p className="line-clamp-1">{userDetail.business_email}</p>
              </a>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md text-closed-shutter-300">
            <p className="font-bold text-closed-shutter-300/70">Phone Number:</p>
            {userDetail.phone_number ? (
              <a href={`tel:${userDetail.phone_number}`} className="inline-flex items-center gap-2 hover:text-gorgonzola-blue-300 duration-600 w-full text-left line-clamp-1">
                <Call className="size-[1.125rem]" variant="Broken" />
                <p className="line-clamp-1">{userDetail.phone_number}</p>
              </a>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Role:</p>
            <StatusBadge statusText={userDetail.role_info.name.toLowerCase()} />
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Country:</p>
            {userDetail.country ? (
              <div className="inline-flex items-center gap-2">
                <p className="line-clamp-1">{userDetail.country}</p>
              </div>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">City:</p>
            {userDetail.city ? (
              <div className="inline-flex items-center gap-2">
                <p className="line-clamp-1">{userDetail.city}</p>
              </div>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Company:</p>
            {userDetail.company ? (
              <div className="inline-flex items-center gap-2">
                <p className="line-clamp-1">{userDetail.company}</p>
              </div>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Address:</p>
            {userDetail.address ? (
              <div className="inline-flex items-center gap-2">
                <p className="line-clamp-1">{userDetail.address}</p>
              </div>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Legal:</p>
            {userDetail.legal ? (
              <div className="inline-flex items-center gap-2">
                <p className="line-clamp-1">{userDetail.legal === 1 ? "Legal Entity" : "Natural Person"}</p>
              </div>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Postal Code:</p>
            {userDetail.postal_code ? (
              <div className="inline-flex items-center gap-2">
                <p className="line-clamp-1">{userDetail.postal_code}</p>
              </div>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Tax Number:</p>
            {userDetail.tax_number ? (
              <div className="inline-flex items-center gap-2">
                <p className="line-clamp-1">{userDetail.tax_number}</p>
              </div>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Updated At:</p>
            {userDetail.updated_at ? (
              <div className="inline-flex items-center gap-2">
                <p className="line-clamp-1">{userDetail.updated_at ? new Date(userDetail.updated_at * 1000).toLocaleDateString() : "-"}</p>
              </div>
            ) : (
              <span>---</span>
            )}
          </div>

          <div className="flex flex-col gap-2 border border-gorgonzola-blue-50 p-2 rounded-md">
            <p className="font-bold text-closed-shutter-300/70">Created At:</p>
            {userDetail.created_at ? (
              <div className="inline-flex items-center gap-2">
                <p className="line-clamp-1">{userDetail.created_at ? new Date(userDetail.created_at * 1000).toLocaleDateString() : "-"}</p>
              </div>
            ) : (
              <span>---</span>
            )}
          </div>
        </div>
      </div>
    </ReusableModal>
  );
};

export default UserInfoModal;
