import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { routes } from "../utilities/defaults/routes/routes";
import { hasAdminAccess } from "../utilities/helper/roleHelper";
import { useGlobalInfo } from "../contexts/GlobalInfoContext";
import Loader from "./loading/Loader";
import { adminRoutes } from "../utilities/defaults/routes/adminRoutes";

const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth();
  const { userInfo, loading } = useGlobalInfo();

  if (!isAuthenticated) {
    return <Navigate to={routes.login.path} replace />;
  }

  if (loading || !userInfo) {
    return (
      <div className="grid w-full h-dvh place-content-center">
        <Loader />
      </div>
    );
  }

  if (hasAdminAccess(userInfo)) {
    return <Navigate to={adminRoutes.dashboard.path} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
