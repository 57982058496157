import React, { useState, useEffect, useContext, useRef } from "react";
import { getChat, sendMessage } from "../../utilities/api/ticketService";
import { AuthContext } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Data } from "../../utilities/types/tickets/types";
import { enqueueSnackbar } from "notistack";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import StatusBadge from "../table/StatusBadge";
import { Send2 } from "iconsax-react";
import Loader from "../loading/Loader";

const SupportChat: React.FC = () => {
  const { accessToken } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState<Data | null>(null);
  const [loading, setLoading] = useState(false);
  const [sendingLoader, setSendingLoader] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const ticketIdNumber = Number(id);
  const chatContainerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const fetchChat = async () => {
      setLoading(true);
      if (!accessToken) {
        enqueueSnackbar("Authentication failed. Please log in again.", { variant: "error" });
        return;
      }
      try {
        const response = await getChat(accessToken, { ticket_id: ticketIdNumber });
        if (response.ok) {
          setChat(response.data);
          scrollToBottom();
        } else {
          enqueueSnackbar(response.message || "Unable to load chat. Please try again.", { variant: "error" });
        }
      } catch (err) {
        console.error("Error fetching chat:", err);
        enqueueSnackbar("An error occurred while loading the chat. Please try again.", { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    fetchChat();
  }, [accessToken, ticketIdNumber]);

  const handleSendMessage = async () => {
    if (!message.trim()) {
      enqueueSnackbar("Please enter a message", { variant: "error" });
      return;
    }
    setSendingLoader(true);
    setIsSubmitting(true);
    try {
      const sendResponse = await sendMessage(accessToken!, { ticket_id: ticketIdNumber, message });
      if (sendResponse.ok) {
        setMessage("");
        enqueueSnackbar("Message sent successfully", { variant: "success" });
        const chatResponse = await getChat(accessToken!, { ticket_id: ticketIdNumber });
        if (chatResponse.ok) {
          setChat(chatResponse.data);
          scrollToBottom();
        } else {
          enqueueSnackbar(chatResponse.message || "Unable to refresh chat. Please try again.", { variant: "error" });
        }
      } else {
        enqueueSnackbar(sendResponse.message || "Unable to send message. Please try again.", { variant: "error" });
      }
    } catch (err) {
      console.error("Error sending message or refreshing chat:", err);
      enqueueSnackbar("An unexpected error occurred. Please try again.", { variant: "error" });
    } finally {
      setSendingLoader(false);
      setIsSubmitting(false);
    }
  };
  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }, 100);
  };
  // const getUserName = () => {
  //   const userInfo = localStorage.getItem("userInfo");
  //   if (userInfo !== null) {
  //     return JSON.parse(userInfo).email;
  //   } else {
  //     return "Unknown";
  //   }
  // };

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <Breadcrumb isId />

      {loading ? (
        <div className="flex justify-center w-full p-4 md:p-6">
          <Loader />
        </div>
      ) : (
        <div className="w-full p-4 md:p-6">
          <div className="flex flex-col border divide-y rounded-md divide-gorgonzola-blue-50 border-gorgonzola-blue-50">
            <div className="flex items-center justify-between px-4 py-6">
              <h2 className="text-base font-bold capitalize text-closed-shutter-300">{chat?.ticket.title}</h2>
              {/* <StatusBadge statusText={chat?.ticket.status === "answered" ? "answered" : "pending"} isSuccess={chat?.ticket.status === "answered" ? true : null} /> */}
              {chat?.ticket.status ? <StatusBadge statusText={chat?.ticket.status} /> : null}
            </div>

            <div className="w-full p-4 ">
              <ul ref={chatContainerRef} className="flex flex-col mb-4 list-none gap-14 max-h-[30rem] overflow-y-auto hide_scroll">
                {chat?.messages.map((message, i) => (
                  <li
                    key={i}
                    className={`w-full flex items-center py-2 mb-2 ${message.message.role === "client" || message.message.role === "user" || message.message.role === "user" ? "justify-end" : ""}`}
                  >
                    <div className={`flex flex-col items-end w-full gap-2`}>
                      <div className={`flex w-full ${message.message.role === "client" || message.message.role === "user" ? "justify-end ps-2" : "justify-end flex-row-reverse pe-2"}`}>
                        <div
                          className={`w-fit max-w-full px-2 py-1 overflow-hidden ${
                            message.message.role === "client" || message.message.role === "user" ? "bg-gorgonzola-blue-300 rounded-s-md rounded-br-md" : "bg-[#F9F9FA] rounded-e-md rounded-bl-md"
                          }`}
                        >
                          {/* 
                            <div className="flex justify-between">
                              <span>{getUserName()}</span>
                            </div> 
                          */}

                          <div className="w-full overflow-hidden line-clamp-1">
                            <p className={`${message.message.role === "client" || message.message.role === "user" ? "text-white " : "text-closed-shutter-300"}`}>{message.message.message}</p>
                          </div>
                        </div>

                        <div className="*:min-w-2 w-2">
                          {message.message.role === "client" || message.message.role === "user" ? (
                            <img src="/assets/image/get-help/top-vector-tip.svg" alt="vector" />
                          ) : (
                            <img src="/assets/image/get-help/top-vector-tip2.svg" alt="vector" />
                          )}
                        </div>
                      </div>

                      <div className={`w-full inline-flex ${message.message.role === "client" || message.message.role === "user" ? " justify-end " : " justify-start "}`}>
                        <span className="text-xs text-gorgonzola-blue-200">{moment(message.message.created_at * 1000).format("YYYY/DD/MM, h:mm:ss a")}</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              <form
                onSubmit={(e) => e.preventDefault()}
                className={`flex justify-between items-center gap-2 w-full px-3 text-xs transition-colors duration-300  border rounded-lg text-closed-shutter-300 outline-none min-h-14 ${
                  sendingLoader ? "bg-closed-shutter-100  border-closed-shutter-100 cursor-not-allowed" : "bg-[#F9F9FA]"
                }`}
              >
                {sendingLoader ? (
                  <span className="text-closed-shutter-300/70">Sending...</span>
                ) : (
                  <input type="text" value={message} maxLength={250} onChange={(e) => setMessage(e.target.value)} placeholder="Start typing..." className="flex-grow py-2 bg-[#F9F9FA] outline-none " />
                )}

                <button type="submit" disabled={isSubmitting} onClick={handleSendMessage}>
                  <Send2 className="size-6 text-closed-shutter-300" variant="Broken" />
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SupportChat;
