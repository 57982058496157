import React from "react";
import { MeasurementResult } from "../../utilities/types/measurement-data/types";
import { getValidMeasurements } from "../../utilities/helper/filterValidMeasurements";

interface BodyMeasurementResultProps {
  measurementData: MeasurementResult;
}

const BodyMeasurementResult: React.FC<BodyMeasurementResultProps> = ({ measurementData }) => {
  const validMeasurements = getValidMeasurements(measurementData);

  if (validMeasurements.length === 0) {
    return (
      <div className="w-full p-6 text-center rounded-xl bg-gorgonzola-blue-50">
        <p className="text-base text-closed-shutter-300">No valid measurements found. Please try again.</p>
      </div>
    );
  }

  return (
    <div className="grid w-full grid-cols-2 gap-4 md:grid-cols-3">
      {validMeasurements.map((measurement) => (
        <div key={measurement.key} className="flex flex-col gap-1 p-3 rounded-xl bg-gorgonzola-blue-100">
          <span className="text-xs text-closed-shutter-300/70">{measurement.label}</span>
          <span className="text-base font-bold text-closed-shutter-300">{measurement.value.toFixed(2)} cm</span>
        </div>
      ))}
    </div>
  );
};

export default BodyMeasurementResult;
