
export enum Status {
  SUCCESS = "success",
  ERROR = "error",
  PAID = "paid",
  NEW = "new",
  ANSWERED = "answered",
  CLOSED = "closed",
  OPEN = "open",
  PENDING = "pending",
  WAITING = "waiting",
  ACTIVE = "active",
  INACTIVE = "inactive",
  BUSINESS = "business",
  CONSUMER = "consumer",
  ACTIVED = "actived",
  PAYING = "paying",
  RESERVED = "reserved",
  EXPIRED = "expired",
  INACTIVED = "inactived",
  CANCELED = "canceled",
  PROCESSING = "processing",
  ENDED = "ended",
}

export const StatusColorMapping = {
  [Status.SUCCESS]: {
    badge: "bg-shindig-100 text-shindig-300",
    dot: "bg-shindig-300",
  },
  [Status.ERROR]: {
    badge: "bg-english-vermillion-100 text-english-vermillion-300",
    dot: "bg-english-vermillion-300",
  },
  [Status.PAID]: {
    badge: "bg-shindig-100 text-shindig-300",
    dot: "bg-shindig-300",
  },
  [Status.NEW]: {
    badge: "bg-closed-shutter-100 text-closed-shutter-300",
    dot: "bg-closed-shutter-300/70",
  },
  [Status.ANSWERED]: {
    badge: "bg-shindig-100 text-shindig-300",
    dot: "bg-shindig-300",
  },
  [Status.CLOSED]: {
    badge: "bg-english-vermillion-100 text-english-vermillion-300",
    dot: "bg-english-vermillion-300",
  },
  [Status.OPEN]: {
    badge: "bg-closed-shutter-100 text-closed-shutter-300",
    dot: "bg-closed-shutter-300/70",
  },
  [Status.WAITING]: {
    badge: "bg-closed-shutter-100 text-closed-shutter-300",
    dot: "bg-closed-shutter-300/70",
  },
  [Status.PENDING]: {
    badge: "bg-closed-shutter-100 text-closed-shutter-300",
    dot: "bg-closed-shutter-300/70",
  },
  [Status.ACTIVE]: {
    badge: "bg-shindig-100 text-shindig-300",
    dot: "bg-shindig-300",
  },
  [Status.INACTIVE]: {
    badge: "bg-closed-shutter-100 text-closed-shutter-300",
    dot: "bg-closed-shutter-300/70",
  },
  [Status.BUSINESS]: {
    badge: "bg-gorgonzola-blue-100 text-gorgonzola-blue-300",
    dot: "bg-gorgonzola-blue-300/70",
  },
  [Status.CONSUMER]: {
    badge: "bg-slap-happy-100 text-slap-happy-300",
    dot: "bg-slap-happy-300/70",
  },
  [Status.ACTIVED]: {
    badge: "bg-shindig-100 text-shindig-300",
    dot: "bg-shindig-300",
  },
  [Status.PAYING]: {
    badge: "bg-closed-shutter-100 text-closed-shutter-300",
    dot: "bg-closed-shutter-300/70",
  },
  [Status.RESERVED]: {
    badge: "bg-gorgonzola-blue-100 text-gorgonzola-blue-300",
    dot: "bg-gorgonzola-blue-300/70",
  },
  [Status.EXPIRED]: {
    badge: "bg-[#5A6376] text-[#E9EDF5]",
    dot: "bg-[#E9EDF5]",
  },
  [Status.INACTIVED]: {
    badge: "bg-closed-shutter-100 text-closed-shutter-300",
    dot: "bg-closed-shutter-300/70",
  },
  [Status.CANCELED]: {
    badge: "bg-english-vermillion-100 text-english-vermillion-300",
    dot: "bg-english-vermillion-300",
  },
  [Status.ENDED]: {
    badge: "bg-closed-shutter-100 text-closed-shutter-300",
    dot: "bg-closed-shutter-300/70",
  },
  [Status.PROCESSING]: {
    badge: "bg-closed-shutter-100 text-closed-shutter-300",
    dot: "bg-closed-shutter-300/70",
  },
};

interface StatusBadgeProps {
  statusText: string;
  customClass?: string;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ statusText, customClass }) => {
  const status = Object.values(Status).find((s) => s === statusText.toLowerCase()) || Status.PENDING;

  const { badge, dot } = StatusColorMapping[status];

  return (
    <div className={`relative flex items-center w-fit px-2 py-1 gap-2 text-xs capitalize rounded-md ${badge} ${customClass || ""}`}>
      <span className={`min-w-3 size-3 rounded-full ${dot}`} />
      <span className="line-clamp-1">{statusText}</span>
    </div>
  );
};

export default StatusBadge;
