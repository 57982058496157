import { TutorialPostsParams } from "../../types/tutorial/types";

export const HOW_TO_WORK_POSTS: TutorialPostsParams = [
  {
    id: 0,
    title: "Auto Tagging",
    description: "Lorem ipsum dolor sit amet consectetur. Odio at nascetur pulvinar pellentesque.",
    image: "/assets/image/tutorial/slide_0.png",
    image_alt: "how to work post image",
    reading_time: 8,
    created_at: "MAY 7, 2024",
    movie_src: "auto_tagging",
    tag: "auto_tagging",
  },
  {
    id: 1,
    title: "Body Measurment",
    description: "Lorem ipsum dolor sit amet consectetur. Odio at nascetur pulvinar pellentesque.",
    image: "/assets/image/tutorial/slide_1.png",
    image_alt: "how to work post image",
    reading_time: 8,
    created_at: "MAY 7, 2024",
    movie_src: "body_measurment",
    tag: "body_measurment",
  },
  {
    id: 2,
    title: "Auto Tagging",
    description: "Lorem ipsum dolor sit amet consectetur. Odio at nascetur pulvinar pellentesque.",
    // image: "/assets/image/tutorial/slide_2.png",
    // image_alt: "how to work post image",
    reading_time: 8,
    created_at: "MAY 7, 2024",
    movie_src: "auto_tagging",
    tag: "auto_tagging",
  },
  {
    id: 3,
    title: "Body Measurment",
    description: "Lorem ipsum dolor sit amet consectetur. Odio at nascetur pulvinar pellentesque.",
    // image: "/assets/image/tutorial/slide_3.png",
    // image_alt: "how to work post image",
    reading_time: 8,
    created_at: "MAY 7, 2024",
    movie_src: "body_measurment",
    tag: "body_measurment",
  },
  {
    id: 4,
    title: "Auto Tagging",
    description: "Lorem ipsum dolor sit amet consectetur. Odio at nascetur pulvinar pellentesque.",
    image: "/assets/image/tutorial/slide_4.png",
    image_alt: "how to work post image",
    reading_time: 8,
    created_at: "MAY 7, 2024",
    movie_src: "auto_tagging",
    tag: "auto_tagging",
  },
  {
    id: 5,
    title: "Body Measurment",
    description: "Lorem ipsum dolor sit amet consectetur. Odio at nascetur pulvinar pellentesque.",
    image: "/assets/image/tutorial/slide_5.png",
    image_alt: "how to work post image",
    reading_time: 8,
    created_at: "MAY 7, 2024",
    movie_src: "body_measurment",
    tag: "body_measurment",
  },
  {
    id: 6,
    title: "Auto Tagging",
    description: "Lorem ipsum dolor sit amet consectetur. Odio at nascetur pulvinar pellentesque.",
    image: "/assets/image/tutorial/slide_6.png",
    image_alt: "how to work post image",
    reading_time: 8,
    created_at: "MAY 7, 2024",
    movie_src: "auto_tagging",
    tag: "auto_tagging",
  },
  {
    id: 7,
    title: "Body Measurment",
    description: "Lorem ipsum dolor sit amet consectetur. Odio at nascetur pulvinar pellentesque.",
    // image: "/assets/image/tutorial/slide_7.png",
    // image_alt: "how to work post image",
    reading_time: 8,
    created_at: "MAY 7, 2024",
    movie_src: "body_measurment",
    tag: "body_measurment",
  },
  {
    id: 8,
    title: "Auto Tagging",
    description: "Lorem ipsum dolor sit amet consectetur. Odio at nascetur pulvinar pellentesque.",
    image: "/assets/image/tutorial/slide_8.png",
    image_alt: "how to work post image",
    reading_time: 8,
    created_at: "MAY 7, 2024",
    movie_src: "auto_tagging",
    tag: "auto_tagging",
  },
];
