import { TableProps } from "../../../utilities/types/support/types";

const AdminDashboardTable = <T extends Record<string, any>>({ data, columns, onRowClick, showHeader = true }: TableProps<T> & { showHeader?: boolean }) => {
  return (
    <div className="min-w-full overflow-hidden border rounded-md border-gorgonzola-blue-50">
      <table className="min-w-full divide-y rounded-md divide-gorgonzola-blue-100">
        {showHeader && (
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key as string} className="px-6 py-5 ">
                  <div className="flex items-center justify-center gap-2">
                    <span className="text-xs font-medium tracking-wider text-left capitalize text-closed-shutter-300">{column.header}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
        )}

        <tbody className="odd:*:bg-white even:*:bg-gorgonzola-blue-50/30">
          {data.map((item, index) => (
            <tr
              key={index}
              onClick={() => onRowClick && onRowClick(item)}
              className="grid grid-cols-1 transition-colors *:border-b cursor-pointer *:border-b-gorgonzola-blue-100 duration-600 hover:bg-gorgonzola-blue-50 md:*:border-none md:table-row"
            >
              {columns.map((column) => (
                <td key={column.key as string} className="px-4 py-6">
                  <div className="flex items-center justify-center">
                    <span className=" text-closed-shutter-300 !line-clamp-1">{column.render ? column.render(item[column.key as keyof T], item) : item[column.key as keyof T]}</span>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDashboardTable;
