import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useGlobalInfo } from "../contexts/GlobalInfoContext";
import { hasAdminAccess } from "../utilities/helper/roleHelper";
import { routes } from "../utilities/defaults/routes/routes";
import { adminRoutes } from "../utilities/defaults/routes/adminRoutes";
import Loader from "./loading/Loader";

const InitialRouteHandler: FC = () => {
  const { isAuthenticated } = useAuth();
  const { userInfo, loading } = useGlobalInfo();

  if (!isAuthenticated) {
    return <Navigate to={routes.login.path} replace />;
  }

  if (loading || !userInfo) {
    return (
      <div className="grid w-full h-dvh place-content-center">
        <Loader />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to={routes.login.path} replace />;
  }

  // If user has admin access (admin or owner), redirect to admin dashboard
  if (hasAdminAccess(userInfo)) {
    return <Navigate to={adminRoutes.dashboard.path} replace />;
  }

  // Otherwise, redirect to user dashboard
  return <Navigate to={routes.dashboard.path} replace />;
};

export default InitialRouteHandler;
