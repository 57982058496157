import { ProfileTabs } from "../../types/profile-tabs/types";

export const ADMIN_PROFILE_TABS: ProfileTabs[] = [
  {
    title: "Personal Information",
    active: true,
  },
  {
    title: "Change Password",
    active: false,
  },
];
