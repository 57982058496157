import React, { useState } from "react";
import { PROFILE_TABS as profileTabs } from "../../utilities/defaults/profile/ProfileTabs";
import MainButton from "../button/MainButton";
import BasicInformation from "./profile-tabs/BasicInformation";
import Businessinfo from "./profile-tabs/Businessinfo";
import Password from "./profile-tabs/Password";
import { useGlobalInfo } from "../../contexts/GlobalInfoContext";

const Profile: React.FC = () => {
  const { userInfo } = useGlobalInfo();
  const [activeProfileTabs, setActiveProfileTabs] = useState(0);

  const handleProfileTabsClick = (index: number) => {
    setActiveProfileTabs(index);
  };

  const renderProfileTab = () => {
    switch (activeProfileTabs) {
      case 0:
        return <BasicInformation />;
      case 1:
        return <Businessinfo />;
      case 2:
        if (userInfo?.can_change_password) {
          return <Password />;
        } else {
          break;
        }
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="w-full p-4 md:p-6">
        <h2 className="font-bold text-closed-shutter-300">My Profile</h2>
      </div>

      <div className="p-4 md:p-6">
        <div className="flex flex-col gap-13">
          <div className="flex gap-6 *:min-w-fit overflow-x-auto">
            {profileTabs.map((profileTab, index) =>
              !userInfo?.can_change_password && profileTab.title === "Password" ? null : (
                <MainButton key={index} type={index === activeProfileTabs ? "primary" : "secondary"} onClick={() => handleProfileTabsClick(index)}>
                  {profileTab.title}
                </MainButton>
              )
            )}
            {/* {profileTabs.map((profileTab, index) => (
              <MainButton key={index} type={index === activeProfileTabs ? "primary" : "secondary"} onClick={() => handleProfileTabsClick(index)}>
                {profileTab.title}
              </MainButton>
            ))} */}
          </div>
          {renderProfileTab()}
        </div>
      </div>
    </div>
  );
};

export default Profile;
