import React from "react";
import StatusBadge from "../../../table/StatusBadge";

interface MobilePlanCardProps {
  plan: any;
  onClick: () => void;
}

const MobilePlanCard: React.FC<MobilePlanCardProps> = ({ plan, onClick }) => {
  return (
    <div className="flex flex-col items-center px-4 text-center *:w-full *:py-6 *:border-b *:border-b-gorgonzola-blue-100 text-closed-shutter-300" onClick={onClick}>
      <span className="font-medium line-clamp-1">{plan.user?.email ? plan.user?.email : "-"}</span>

      <div className="grid grid-cols-2 gap-2 ">
        <div>
          <p className="text-sm text-gray-500">Service</p>
          <p>{plan.plan?.remaining_apis[0].api_name ? plan.plan?.remaining_apis[0].api_name : "-"}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Plan</p>
          <p>{plan.plan?.name ? plan.plan?.name : "-"}</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2">
        <div>
          <p className="text-sm text-gray-500">Start Plan</p>
          <p>{plan.actived_date ? new Date(plan.actived_date * 1000).toLocaleDateString() : "-"}</p>
        </div>

        <div>
          <p className="text-sm text-gray-500">End Plan</p>
          <p>{plan.expired_date ? new Date(plan.expired_date * 1000).toLocaleDateString() : "-"}</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2 ">
        <div>
          <p className="text-sm text-gray-500">Req/Req use</p>
          <span className="text-sm">
            {plan.apis.total_requests}/{plan.apis.use_requests}
          </span>
        </div>
        <div>
          <p className="text-sm text-gray-500">Status</p>
          <div className="flex justify-center">
            <StatusBadge statusText={plan.status} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePlanCard;
