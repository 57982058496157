import { createContext, useState, FC, ReactNode, useContext, useCallback } from "react";
import { AuthContext } from "./AuthContext";
import { getAllPlans } from "../utilities/api/adminService";

interface PlansAnalyticsContextValue {
  plansData: any[];
  totalPages: number;
  currentPage: number;
  perPage: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  loading: boolean;
  fetchPlansData: (page?: number, filters?: any) => Promise<void>;
  setPerPage: (perPage: number) => void;
}

export const PlansAnalyticsContext = createContext<PlansAnalyticsContextValue>({
  plansData: [],
  totalPages: 1,
  currentPage: 1,
  perPage: 10,
  totalItems: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  loading: false,
  fetchPlansData: async () => {},
  setPerPage: () => {},
});

export const PlansAnalyticsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { accessToken } = useContext(AuthContext);
  const [plansData, setPlansData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchPlansData = useCallback(
    async (page: number = 1, filters?: any) => {
      if (!accessToken) return;
      try {
        setLoading(true);
        const response = await getAllPlans(accessToken, page, perPage, filters);
        if (response.ok) {
          setPlansData(response.data.data);
          setTotalItems(response.data.total);
          setTotalPages(response.data.last_page);
          setCurrentPage(response.data.current_page);
          setHasPreviousPage(response.data.current_page > 1);
          setHasNextPage(response.data.current_page < response.data.last_page);
        }
      } catch (error) {
        console.error("Error fetching plans data:", error);
      } finally {
        setLoading(false);
      }
    },
    [accessToken, perPage]
  );

  return (
    <PlansAnalyticsContext.Provider
      value={{
        plansData,
        totalPages,
        currentPage,
        perPage,
        totalItems,
        hasPreviousPage,
        hasNextPage,
        loading,
        fetchPlansData,
        setPerPage,
      }}
    >
      {children}
    </PlansAnalyticsContext.Provider>
  );
};

export const usePlansAnalytics = () => {
  const context = useContext(PlansAnalyticsContext);
  if (!context) {
    throw new Error("usePlansAnalytics must be used within an PlansAnalyticsProvider");
  }
  return context;
};
