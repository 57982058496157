import React from "react";
import { TableColumn } from "../../../utilities/types/support/types";
import StatusBadge from "../../table/StatusBadge";
import Table from "../../table/Table";
import MobileUserPlanCard from "./MobileUserPlanCard";

interface UserPlansTableProps {
  plansData: any[];
  onRowClick: (plan: any) => void;
}

const UserPlansTable: React.FC<UserPlansTableProps> = ({ plansData, onRowClick }) => {
  const plansColumns: TableColumn<any>[] = [
    {
      key: "id",
      header: "Id",
      render: (value, item) => <span className="text-sm">{item.id ? item.id : "-"}</span>,
    },
    {
      key: "plan Name",
      header: "Plan Name",
      render: (value, item) => <span className="text-sm">{item.plan?.remaining_apis[0].api_name ? item.plan?.remaining_apis[0].api_name : "-"}</span>,
    },
    {
      key: "plan",
      header: "Plan",
      render: (value, item) => <span className="text-sm">{item.plan?.name ? item.plan?.name : "-"}</span>,
    },
    {
      key: "actived_date",
      header: "Start Plan",
      render: (value) => <span className="text-sm">{value ? new Date(value * 1000).toLocaleDateString() : "-"}</span>,
    },
    {
      key: "expired_date",
      header: "End Plan",
      render: (value) => <span className="text-sm">{value ? new Date(value * 1000).toLocaleDateString() : "-"}</span>,
    },
    {
      key: "status",
      header: "Status",
      render: (value) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.toLowerCase()} />
        </div>
      ),
    },
    {
      key: "remaining_money",
      header: "Req/Req use",
      render: (value, item) => (
        <span className="text-sm">
          {item.apis.total_requests}/{item.apis.use_requests}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="block xl:hidden odd:*:bg-gorgonzola-blue-50/30 even:*:bg-white">
        {plansData.map((plan) => (
          <MobileUserPlanCard key={plan.id} plan={plan} onClick={() => onRowClick(plan)} />
        ))}
      </div>

      <div className="hidden w-full xl:block">
        <Table data={plansData} columns={plansColumns} onRowClick={onRowClick} />
      </div>
    </>
  );
};

export default UserPlansTable;
