import React from "react";
import { Eye } from "iconsax-react";
import { ServiceHistoryItem } from "../../utilities/api/serviceHistoryService";
import MainButton from "../button/MainButton";
import StatusBadge from "../table/StatusBadge";
import { formatDate } from "../../utilities/helper/dateUtils";

interface MobileServiceCardProps {
  service: ServiceHistoryItem;
  onViewDetails: (service: ServiceHistoryItem) => void;
}

const MobileServiceCard: React.FC<MobileServiceCardProps> = ({ service, onViewDetails }) => {
  return (
    <div className="flex flex-col items-center px-4 text-center *:w-full *:py-6 *:border-b *:border-b-gorgonzola-blue-100">
      <span className="font-medium line-clamp-1 text-closed-shutter-300">{service.service_name}</span>

      <div className="grid grid-cols-2 *:place-content-center *:place-self-center overflow-hidden">
        {/* <StatusBadge statusText={service.status} isSuccess={service.status.toLowerCase() === "success" ? true : service.status.toLowerCase() === "error" ? false : null} /> */}
        <StatusBadge statusText={service.status} />

        <MainButton type="ghostOutlined" onClick={() => onViewDetails(service)}>
          <Eye variant="Broken" className="mr-2 size-4" />
          See Details
        </MainButton>
      </div>

      <div className="grid grid-cols-2 *:place-content-center *:place-self-center overflow-hidden">
        <p>{service.created_at ? formatDate(new Date(service.created_at * 1000).toLocaleDateString()) : "-"}</p>
        <p>{service.created_at ? new Date(service.created_at * 1000).toLocaleTimeString() : "-"}</p>
      </div>
    </div>
  );
};

export default MobileServiceCard;
