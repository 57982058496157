import React from "react";
import { useNavigate } from "react-router-dom";
import { useVisualClothesServiceContext } from "../../../contexts/VisualClothesServiceContext";
import { VISUAL_CLOTHES_SERVICE_STEPS as steps } from "../../../utilities/defaults/free-trial/visual-clothes-service-steps";
import MainButton from "../../button/MainButton";
import { ArrowLeft, ArrowRight, Refresh2 } from "iconsax-react";

interface PaginationProps {
  handlerButtonText?: string;
  isTaggingCompleted?: boolean;
}

const VisualClothesServicePagination: React.FC<PaginationProps> = ({ handlerButtonText = "Continue", isTaggingCompleted = false }) => {
  const { resetVisualClothesService, canProceedToNextStep, currentStep, handlePageChange } = useVisualClothesServiceContext();

  const navigate = useNavigate();

  const handlePrevPage = () => {
    if (currentStep > 0) {
      handlePageChange(isTaggingCompleted ? currentStep - 2 : currentStep - 1);
    }
  };

  const handleNextPage = () => {
    if (isTaggingCompleted) {
      resetVisualClothesService();
      navigate("/dashboard/free-trial/visual-clothes-search");
      return;
    }

    if (currentStep < steps.length - 1 && canProceedToNextStep) {
      handlePageChange(currentStep + 1);
    }
  };

  return (
    <div className="flex justify-between w-full gap-4">
      <MainButton type="secondary" onClick={handlePrevPage} disabled={currentStep === 0}>
        <ArrowLeft variant="Broken" />
        Back
      </MainButton>

      <MainButton type="primary" onClick={handleNextPage} disabled={!canProceedToNextStep}>
        {handlerButtonText}
        {isTaggingCompleted ? <Refresh2 variant="Broken" /> : <ArrowRight variant="Broken" />}
      </MainButton>
    </div>
  );
};

export default VisualClothesServicePagination;
