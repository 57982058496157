import { UserInfo, UserRole } from "../types/user/types";

export const isAdmin = (userInfo: UserInfo | null): boolean => {
  return userInfo?.role_info?.toLowerCase() === UserRole.ADMIN.toLowerCase();
};

export const isOwner = (userInfo: UserInfo | null): boolean => {
  return userInfo?.role_info?.toLowerCase() === UserRole.OWNER.toLowerCase();
};

export const hasAdminAccess = (userInfo: UserInfo | null): boolean => {
  if (!userInfo || !userInfo.role_info) return false;
  const role = userInfo.role_info.toLowerCase();
  return role === UserRole.ADMIN.toLowerCase() || role === UserRole.OWNER.toLowerCase();
};

export const isUser = (userInfo: UserInfo | null): boolean => {
  return userInfo?.role_info?.toLowerCase() === UserRole.USER.toLowerCase();
};
