import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import styles from "../../styles/button/mainButton.module.css";
import { HTMLAttributeAnchorTarget } from "react";

//  * Interface for the MainButton component props

interface MainButtonProps {
  type?: "finance" | "primary" | "secondary" | "ghost" | "ghostOutlined" | "error" | "submit";
  to?: string | undefined;
  disabled?: boolean;
  customClass?: string;
  isSubmit?: boolean;
  passHref?: boolean;
  passHrefTarget?: HTMLAttributeAnchorTarget | undefined;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const MappedTypes: { [key: string]: string } = {
  finance: `${styles.base} ${styles.finance}`,
  primary: `${styles.base} ${styles.primary}`,
  secondary: `${styles.base} ${styles.secondary}`,
  ghost: `${styles.base} ${styles.ghost}`,
  ghostOutlined: `${styles.base} ${styles.ghostOutlined}`,
  error: `${styles.base} ${styles.error}`,
  submit: `${styles.base} ${styles.active}`,
};

const MainButton = ({ type = "finance", to = undefined, disabled = false, onClick, customClass, isSubmit, passHref, passHrefTarget, children }: MainButtonProps) => {
  // Render a disabled button if the 'disabled' prop is true
  if (disabled)
    return (
      <button disabled={true} className={`${styles.base} ${customClass ? customClass : ""}`} onClick={onClick ? onClick : undefined}>
        {children}
      </button>
    );

  // Render the "finance" button type with a gradient background animation
  if (type === "finance" && to) {
    return (
      <Link to={to}>
        <FinanceButton customClass={customClass} onClick={onClick ? onClick : undefined}>
          {children}
        </FinanceButton>
      </Link>
    );
  } else if (type === "finance" && !to) {
    return (
      <FinanceButton customClass={customClass} onClick={onClick ? onClick : undefined}>
        {children}
      </FinanceButton>
    );
  }

  // Render the button as a <a></a> if the 'passHref' prop is provided
  if (passHref && to)
    return (
      <a href={to} target={passHrefTarget}>
        <button disabled={disabled} className={`${MappedTypes[type]} ${customClass ? customClass : ""}`} onClick={onClick ? onClick : undefined}>
          {children}
        </button>
      </a>
    );

  // Render the button as a Link component if the 'to' prop is provided
  if (to)
    return (
      <Link to={to}>
        <button disabled={disabled} className={`${MappedTypes[type]} ${customClass ? customClass : ""}`} onClick={onClick ? onClick : undefined}>
          {children}
        </button>
      </Link>
    );

  // Render the button as a regular button for other button types, with the option to be a submit button
  return (
    <button type={isSubmit ? "submit" : undefined} disabled={disabled} className={`${MappedTypes[type]} ${customClass ? customClass : ""}`} onClick={onClick ? onClick : undefined}>
      {children}
    </button>
  );
};

/*
 * FinanceButton component
 * Renders a button with a gradient background animation
 * - Additional custom classes to apply to the button
 * - The function to be called when the button is clicked
 * - The content to be displayed inside the button
 * - The rendered FinanceButton component
 */
const FinanceButton = ({ customClass, onClick, children }: { customClass?: string; onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; children: React.ReactNode }) => {
  return (
    <motion.button
      className={`${MappedTypes["finance"]} ${customClass ? customClass : ""}`}
      // Set the initial gradient background
      initial={{ background: "linear-gradient(90deg, #474BCF 99.41%, #242669 112.34%)" }}
      // Set the gradient background on hover
      whileHover={{
        background: "linear-gradient(94deg, #242669 0%, #474BCF 99.99%)",
        transition: { duration: 0.6 },
      }}
      // Set the gradient background on click
      whileTap={{
        background: "linear-gradient(274deg, #242669 0%, #474BCF 99.99%)",
        transition: { duration: 0.6 },
      }}
      onClick={onClick ? onClick : undefined}
    >
      {children}
    </motion.button>
  );
};

export default MainButton;
