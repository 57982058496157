import React from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import SidebarButton from "../button/SidebarButton";
import { SidebarContext } from "../../contexts/SidebarContext";
import { SidebarItem } from "../../utilities/types/sidebar/types";
import { exactRouteMatch, isSubRoute } from "../../utilities/helper/routingHelpers";
import { useLogoutHandler } from "../modal/LogoutHandler";
import { useSidebarItems } from "../../utilities/helper/useSidebarItems";

const Sidebar = () => {
  const { pathname } = useLocation();
  const { isLogoOpen } = useContext(SidebarContext)!;
  const { handleLogout, LogoutModalComponent } = useLogoutHandler();
  const { mainItems, primaryItems, upgradeItems } = useSidebarItems();

  const isItemActive = (item: SidebarItem): boolean => {
    if (!item.href) return false;

    // For the dashboard route, only activate on exact match
    if (item.href === "/dashboard" || item.href === "/admin") {
      return exactRouteMatch(pathname, item.href);
    }

    // For other routes, activate on exact match or if it's a sub-route
    return exactRouteMatch(pathname, item.href) || isSubRoute(pathname, item.href);
  };

  return (
    <>
      <motion.div
        initial={{ width: isLogoOpen ? "fit-content" : "20%" }}
        animate={{ width: isLogoOpen ? "fit-content" : "20%" }}
        transition={{ duration: 0.6 }}
        className="hidden lg:block overflow-y-auto max-w-[17rem] hide_scroll"
      >
        <div className={`flex flex-col gap-3 pt-6 pb-10 px-4 rounded-xl border border-gorgonzola-blue-50 *:flex *:flex-col *:gap-2`}>
          {/* Main Sidebar Items */}
          <div>
            {mainItems.map((item: SidebarItem) => (
              <SidebarButton key={item.id} type={item.type} to={item.href} title={item.title} svg={item.svg} active={isItemActive(item)} />
            ))}
          </div>

          {/* Separator */}
          <span className="w-full h-px bg-gorgonzola-blue-50" />

          {/* Primary Sidebar Items */}
          <div>
            {primaryItems.map((item: SidebarItem) => (
              <SidebarButton
                key={item.id}
                type={item.type}
                to={item.href}
                title={item.title}
                svg={item.svg}
                isLogout={item.logout}
                active={isItemActive(item)}
                onClick={item.logout ? handleLogout : undefined}
              />
            ))}
          </div>

          {/* Upgrade Sidebar Items */}
          {upgradeItems.length > 0 ? (
            <div>
              {upgradeItems.map((item: SidebarItem) => (
                <SidebarButton key={item.id} type={item.type} to={item.href} title={item.title} svg={item.svg} />
              ))}
            </div>
          ) : null}
        </div>
      </motion.div>
      {LogoutModalComponent}
    </>
  );
};

export default Sidebar;
