import { format, parseISO } from "date-fns";

export default function TransformPublishedAt(publishedAtString: string) {
  const publishedAtDate = parseISO(publishedAtString);
  return format(publishedAtDate, "MM/dd/yyyy");
}

export const TransformTime = (dateString: string): string => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "---";
  }
  const hours = String(date.getHours() % 12 || 12).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const ampm = date.getHours() >= 12 ? "PM" : "AM";
  return `${hours}:${minutes}:${seconds} ${ampm}`;
};
