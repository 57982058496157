import React, { useState, useEffect } from "react";
import Loader from "../../loading/Loader";

interface ProductImageProps {
  images: string[];
  alt: string;
  className?: string;
}

const ProductImage: React.FC<ProductImageProps> = ({ images, alt, className }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState<boolean[]>([]);

  useEffect(() => {
    setIsLoading(true);
    setLoadedImages(new Array(images.length).fill(false));

    // Preload images
    images.forEach((src, index) => {
      const img = new Image();
      img.src = src;
      img.onload = () => handleImageLoad(index);
      img.onerror = (e) => handleImageError(e as Event, index);
    });

    return () => {
      // Cleanup
      setLoadedImages([]);
      setIsLoading(true);
    };
  }, [images]);

  const handleImageLoad = (index: number) => {
    setLoadedImages((prev) => {
      const newState = [...prev];
      newState[index] = true;
      // Check if all images are loaded
      if (newState.slice(0, images.length).every((state) => state)) {
        setIsLoading(false);
      }
      return newState;
    });
  };

  const handleImageError = (e: Event | React.SyntheticEvent<HTMLImageElement>, index: number) => {
    const fallbackSrc = "/assets/image/free-trial/visual-clothes-search/visual-fallback.gif";

    if ("target" in e && e.target instanceof HTMLImageElement) {
      e.target.onerror = null;
      e.target.src = fallbackSrc;
    }
    handleImageLoad(index);
  };

  // Handle single image case
  if (images.length === 1) {
    return (
      <div className="relative w-full h-full">
        {isLoading && (
          <div className="absolute inset-0 grid place-items-center bg-gorgonzola-blue-50/10">
            <Loader />
          </div>
        )}
        <img src={images[0]} alt={alt} className={className} onLoad={() => handleImageLoad(0)} onError={(e) => handleImageError(e, 0)} />
      </div>
    );
  }

  // Handle multiple images case
  return (
    <div className="relative size-full" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      {isLoading && (
        <div className="absolute inset-0 grid place-items-center bg-gorgonzola-blue-50/10">
          <Loader />
        </div>
      )}

      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`${alt}-${index + 1}`}
          className={`${className} absolute inset-0 transition-opacity duration-600 ease-in-out ${index === 0 ? (isHovered ? "opacity-0" : "opacity-100") : isHovered ? "opacity-100" : "opacity-0"}`}
          onLoad={() => handleImageLoad(index)}
          onError={(e) => handleImageError(e, index)}
        />
      ))}
    </div>
  );
};

export default ProductImage;
