import { forwardRef } from "react";
import { useSnackbar } from "notistack";
import { CloseSquare } from "iconsax-react";

const ErrorSnackbar = forwardRef<HTMLDivElement, { message: string }>(({ message }, ref) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <div ref={ref} className="bg-white border-l-8 border-[#CF474B] p-4 rounded-lg flex items-center justify-between gap-10 z-[9999999]">
      <div className="flex items-center gap-2">
        <span className="size-6">
          <img src={"/assets/image/notistack/closesquare.svg"} alt="Error Square" />
        </span>
        <span className="text-xs text-closed-shutter-300 line-clamp-1">{message}</span>
      </div>
      <button className="size-6" onClick={() => closeSnackbar()}>
        <CloseSquare className="size-6" variant="Broken" />
      </button>
    </div>
  );
});

ErrorSnackbar.displayName = "ErrorSnackbar";

export default ErrorSnackbar;
