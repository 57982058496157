import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import { useVisualClothesServiceContext } from "../../contexts/VisualClothesServiceContext";
import { FREE_TRIAL } from "../../utilities/defaults/free-trial/plans";
import VisualClothesServiceResult from "./visual-clothes-service/visual-clothes-service-steps/VisualClothesServiceResult";
import Loader from "../loading/Loader";
import VisualClothesServiceUploadPic from "./visual-clothes-service/visual-clothes-service-steps/VisualClothesServiceUploadPic";
import VisualClothesServiceStepIndicator from "./visual-clothes-service/VisualClothesServiceStepIndicator";
import VisualClothesServiceProssesing from "./visual-clothes-service/visual-clothes-service-steps/VisualClothesServiceProssesing";

const VisualClothesService: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { currentStep } = useVisualClothesServiceContext();
  const [service, setService] = useState<typeof FREE_TRIAL[number] | null>(null);

  useEffect(() => {
    const foundService = FREE_TRIAL.find((service) => service.sdk_href === id);
    setService(foundService || null);
  }, [id]);

  if (!service) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <Breadcrumb />

      <div className="flex flex-col items-center gap-16 p-4 md:p-6">
        <VisualClothesServiceStepIndicator />

        <div className="flex justify-center w-full">
          {currentStep === 0 ? <VisualClothesServiceUploadPic /> : null}
          {currentStep === 1 ? <VisualClothesServiceProssesing /> : null}
          {currentStep === 2 ? <VisualClothesServiceResult /> : null}
        </div>
      </div>
    </div>
  );
};

export default VisualClothesService;
