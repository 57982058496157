import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CloseCircle } from "iconsax-react";

interface ReusableModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showCloseButton?: boolean;
  customModal?: boolean;
}

const ReusableModal: React.FC<ReusableModalProps> = ({ isOpen, onClose, children, showCloseButton = true, customModal = false }) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (!isClosing) {
      setIsClosing(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  return (
    <AnimatePresence>
      {!isClosing && isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 -top-1 z-[999] flex items-center justify-center bg-[#000000cc]"
          onClick={handleBackgroundClick}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={`relative custom_scroll ${!customModal ? "flex flex-col items-center gap-8 bg-white p-4 md:p-6 rounded-md max-w-[90%] max-h-[90%] overflow-y-auto" : ""}`}
          >
            {showCloseButton && (
              <button
                onClick={handleClose}
                className="absolute z-50 grid transition-colors duration-300 border rounded place-content-center size-10 right-4 md:right-6 top-4 md:top-6 border-gorgonzola-blue-50 text-closed-shutter-300 hover:text-gorgonzola-blue-300 hover:border-gorgonzola-blue-200"
              >
                <CloseCircle className="size-6" variant="Broken" />
              </button>
            )}
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ReusableModal;
