import React, { useEffect } from "react";
import { useAutoTaggingServiceContext } from "../../../../contexts/AutoTaggingServiceContext";
import AutoTaggingServiceImageDisplay from "../AutoTaggingServiceImageDisplay";
import ErrorDisplay from "../../ErrorDisplay";
import FreeTrialServicesLoader from "../../FreeTrialServicesLoader";
import { useNavigate } from "react-router-dom";

const AutoTagging: React.FC = () => {
  const { submitAutoTagging, resetAutoTaggingService, error, setError } = useAutoTaggingServiceContext();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAutoTaggingData = async () => {
      await submitAutoTagging();
    };
    fetchAutoTaggingData();
  }, []);

  if (error) {
    return (
      <ErrorDisplay
        error={error}
        onRetry={() => {
          setError(null);
          resetAutoTaggingService();
          navigate("/dashboard/free-trial/auto-tagging");
        }}
      />
    );
  }

  return (
    <div className="flex flex-col items-center gap-32 w-full md:w-[33.8%]">
      <FreeTrialServicesLoader />
      <div className="flex flex-col items-center gap-10">
        <span className="font-bold text-center capitalize text-closed-shutter-300">Clothes Recognition in progress...</span>
        <AutoTaggingServiceImageDisplay />
      </div>
    </div>
  );
};

export default AutoTagging;
