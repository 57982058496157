// Helper function to convert file to base64
export const convertToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    // Handle successful file read
    reader.onload = () => {
      const base64 = reader.result as string;
      resolve(base64.split(",")[1]);
    };

    // Handle error while reading file
    reader.onerror = (error) => {
      console.error("Error converting to base64:", error);
      reject(error);
    };
  });
};
