import moment from "moment";

export const formatDate = (date: string | number | Date): string => {
  return moment(date).format("YYYY/DD/MM");
};

// export const formatTime = (timestamp: number): string => {
//   const date = new Date(timestamp * 1000);
//   return date.toLocaleTimeString("en-US", {
//     hour: "2-digit",
//     minute: "2-digit",
//     hour12: true,
//   });
// };

export const formatTime = (date: Date): string => {
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};
