const TourGuideStep03 = () => {
  return (
    <div className="flex flex-col items-center w-full gap-4">
      <img src="/assets/image/tour-guide/TourGuideStep03.gif" alt="Tour Guide Step gif" className="w-[13.75rem] min-w-[13.75rem]" />
      <div className="space-y-6 text-center ">
        <h2 className="font-bold text-closed-shutter-300">Thank You for Trying Our Free Plan!</h2>
        <p className="text-xs text-closed-shutter-300/70">
          We hope you enjoyed exploring our AI-powered solutions for your fashion business. To continue making requests and fully leverage our platform, we invite you to purchase one of our affordable
          plans. Our plans offer the highest request limits at the best prices. Don't miss out—upgrade today!
        </p>
      </div>
    </div>
  );
};

export default TourGuideStep03;
