interface SizeResult {
  size: string;
  percentage: number;
}

interface SizeGuideResultProps {
  results: Array<Record<string, number>>;
}

const SizeGuideResult: React.FC<SizeGuideResultProps> = ({ results }) => {
  const formatResults = (data: Array<Record<string, number>>): SizeResult[] => {
    if (!Array.isArray(data)) return [];

    return data
      .flatMap((obj) =>
        Object.entries(obj).map(([size, percentage]) => ({
          size,
          percentage: Math.round(percentage * 100) / 100,
        }))
      )
      .sort((a, b) => b.percentage - a.percentage);
  };

  const sortedResults = formatResults(results);

  if (!sortedResults.length) return null;

  const bestMatch = sortedResults[0];

  return (
    <div className="p-2 md:p-6 lg:p-8">
      <div className="p-2 py-4 md:p-4 lg:p-6 mb-8 rounded-3xl bg-gorgonzola-blue-50">
        <div className="mb-8 text-center">
          <h3 className="mb-3 text-lg font-inter-bold text-closed-shutter-300">Size Recommendation</h3>
          <p className="text-base font-inter-regular text-closed-shutter-200">Best size match based on your measurements</p>
        </div>
        <div className="text-center">
          <span className="block text-xl font-inter-bold text-gorgonzola-blue-300">Size {bestMatch.size}</span>
          <p className="mt-2 text-xs font-inter-medium text-gorgonzola-blue-400">{bestMatch.percentage}% Match</p>
        </div>
      </div>

      {sortedResults.length > 1 && (
        <div className="space-y-4">
          <h4 className="mb-4 text-base font-inter-medium text-closed-shutter-300">Alternative Size Options:</h4>
          {sortedResults.slice(1).map((result, index) => (
            <div key={index} className="flex items-center justify-between p-4 rounded-3xl bg-closed-shutter-50">
              <span className="text-base font-inter-medium text-closed-shutter-300">Size {result.size}</span>
              <span className="text-xs font-inter-regular text-closed-shutter-200">{result.percentage}% Match</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SizeGuideResult;
