const TourGuideStep02 = () => {
  return (
    <div className="flex flex-col items-center w-full gap-4">
      <img src="/assets/image/tour-guide/TourGuideStep02.gif" alt="Tour Guide Step gif" className="w-[13.75rem] min-w-[13.75rem]" />
      <div className="space-y-6 text-center ">
        <h2 className="font-bold text-closed-shutter-300">How to Integrate Shaku on Your Website</h2>
        <p className="text-xs text-closed-shutter-300/70">
          Want to integrate Shaku services into your projects? It's easy with our SDK and API! Our straightforward documentation and user-friendly tools will have you up and running in no time. Dive
          into our resources and start creating amazing experiences today!
        </p>
      </div>
    </div>
  );
};

export default TourGuideStep02;
