import { createContext, useState, FC, ReactNode, useContext, useCallback } from "react";
import { AuthContext } from "./AuthContext";
import { getDashboardList, getDashboardUsagePercentage, getDashboardSalePlanPercentage, getDashboardSalePlanSumAmount } from "../utilities/api/adminService";
import { MainAdminList, MainAdminSalePlanPercentage, MainAdminSalePlanSumAmount, MainAdminUsagePercentage, Payment, Ticket, User } from "../utilities/types/admin/types";

interface MainAdminContextValue {
  mainAdminList: MainAdminList;
  usersList: User[];
  ticketsList: Ticket[];
  paymentsList: Payment[];
  mainAdminUsagePercentage: MainAdminUsagePercentage[] | null;
  mainAdminSalePlanPercentage: MainAdminSalePlanPercentage | null;
  mainAdminSalePlanSumAmount: MainAdminSalePlanSumAmount | null;
  loading: boolean;
  fetchMainAdminList: () => Promise<void>;
  fetchMainAdminUsagePercentage: () => Promise<void>;
  fetchMainAdminSalePlanPercentage: () => Promise<void>;
  fetchMainAdminSalePlanSumAmount: () => Promise<void>;
}

export const MainAdminContext = createContext<MainAdminContextValue>({
  mainAdminList: {
    users: [],
    tickets: [],
    usages: [],
    payments: [],
  },
  usersList: [],
  ticketsList: [],
  paymentsList: [],
  mainAdminUsagePercentage: null,
  mainAdminSalePlanPercentage: null,
  mainAdminSalePlanSumAmount: null,
  loading: false,
  fetchMainAdminList: async () => {},
  fetchMainAdminUsagePercentage: async () => {},
  fetchMainAdminSalePlanPercentage: async () => {},
  fetchMainAdminSalePlanSumAmount: async () => {},
});

export const MainAdminProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { accessToken } = useContext(AuthContext);
  const [mainAdminList, setMainAdminList] = useState<MainAdminList>({
    users: [],
    tickets: [],
    usages: [],
    payments: [],
  });
  const [usersList, setUsersList] = useState<User[]>([]);
  const [ticketsList, setTicketsList] = useState<Ticket[]>([]);
  const [paymentsList, setPaymentsList] = useState<Payment[]>([]);
  const [mainAdminUsagePercentage, setMainAdminUsagePercentage] = useState<MainAdminUsagePercentage[] | null>(null);
  const [mainAdminSalePlanPercentage, setMainAdminSalePlanPercentage] = useState<MainAdminSalePlanPercentage | null>(null);
  const [mainAdminSalePlanSumAmount, setMainAdminSalePlanSumAmount] = useState<MainAdminSalePlanSumAmount | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchMainAdminList = useCallback(async () => {
    if (!accessToken) return;
    try {
      setLoading(true);
      const response = await getDashboardList(accessToken);
      if (response.ok) {
        setMainAdminList(response.data);
        setUsersList(response.data.users);
        setTicketsList(response.data.tickets);
        setPaymentsList(response.data.payments);
      }
    } catch (error) {
      console.error("Error fetching mainAdmin list:", error);
    } finally {
      setLoading(false);
    }
  }, [accessToken]);

  const fetchMainAdminUsagePercentage = useCallback(async () => {
    if (!accessToken) return;
    try {
      setLoading(true);
      const response = await getDashboardUsagePercentage(accessToken);
      if (response.ok) {
        setMainAdminUsagePercentage(response.data);
      }
    } catch (error) {
      console.error("Error fetching mainAdmin usage percentage:", error);
    } finally {
      setLoading(false);
    }
  }, [accessToken]);

  const fetchMainAdminSalePlanPercentage = useCallback(async () => {
    if (!accessToken) return;
    try {
      setLoading(true);
      const response = await getDashboardSalePlanPercentage(accessToken);
      if (response.ok) {
        setMainAdminSalePlanPercentage(response.data);
      }
    } catch (error) {
      console.error("Error fetching mainAdmin sale plan percentage:", error);
    } finally {
      setLoading(false);
    }
  }, [accessToken]);

  const fetchMainAdminSalePlanSumAmount = useCallback(async () => {
    if (!accessToken) return;
    try {
      setLoading(true);
      const response = await getDashboardSalePlanSumAmount(accessToken);
      if (response.ok) {
        setMainAdminSalePlanSumAmount(response.data);
      }
    } catch (error) {
      console.error("Error fetching mainAdmin sale plan sum amount:", error);
    } finally {
      setLoading(false);
    }
  }, [accessToken]);

  return (
    <MainAdminContext.Provider
      value={{
        mainAdminList,
        usersList,
        ticketsList,
        paymentsList,
        mainAdminUsagePercentage,
        mainAdminSalePlanPercentage,
        mainAdminSalePlanSumAmount,
        loading,
        fetchMainAdminList,
        fetchMainAdminUsagePercentage,
        fetchMainAdminSalePlanPercentage,
        fetchMainAdminSalePlanSumAmount,
      }}
    >
      {children}
    </MainAdminContext.Provider>
  );
};

export const useMainAdmin = () => {
  const context = useContext(MainAdminContext);
  if (!context) {
    throw new Error("useMainAdmin must be used within a MainAdminProvider");
  }
  return context;
};
