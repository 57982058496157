import React from "react";
import { DocumentUpload, Eye } from "iconsax-react";
import { Ticket } from "../../utilities/types/tickets/types";
import { TableColumn } from "../../utilities/types/support/types";
import Table from "../table/Table";
import MainButton from "../button/MainButton";
import StatusBadge from "../table/StatusBadge";
import { Link } from "react-router-dom";
import { formatDate } from "../../utilities/helper/dateUtils";
import MobileTicketCard from "./MobileTicketCard";

interface TicketsTableProps {
  tickets: Ticket[];
}

const TicketsTable: React.FC<TicketsTableProps> = ({ tickets }) => {
  const ticketColumns: TableColumn<Ticket>[] = [
    {
      key: "id",
      header: "Id",
      render: (value: string) => (
        <div className="flex items-center gap-2">
          <span>#{value ? value : "-"}</span>
          <DocumentUpload className="size-6" variant="Broken" />
        </div>
      ),
    },
    { key: "title", header: "Subject" },
    { key: "section", header: "Dep" },
    {
      key: "status",
      header: "Status",
      render: (value: string) => (
        <div className="flex w-full">
          <StatusBadge statusText={value} />
        </div>
      ),
    },
    {
      key: "created_at_date",
      header: "Date",
      render: (value, item) => {
        const date = new Date(item.created_at * 1000);
        return item.created_at ? formatDate(date.toLocaleDateString()) : "-";
      },
    },
    {
      key: "created_at_time",
      header: "Time",
      render: (value, item) => {
        const date = new Date(item.created_at * 1000);
        return item.created_at ? date.toLocaleTimeString() : "-";
      },
    },
    {
      key: "action",
      header: "Show Answer",
      render: (_, ticket: Ticket) => (
        <Link to={`/dashboard/support/${ticket.id}`} state={{ ticketId: ticket.id }} className="flex items-center justify-center">
          <MainButton type={ticket.status === "answered" ? "primary" : "ghostOutlined"}>
            <Eye variant="Broken" className="mr-2 size-4" />
            Answer
          </MainButton>
        </Link>
      ),
    },
  ];

  return (
    <>
      <div className="block xl:hidden odd:*:bg-gorgonzola-blue-50/30 even:*:bg-white">
        {tickets.map((ticket) => (
          <MobileTicketCard key={ticket.id} ticket={ticket} />
        ))}
      </div>
      <div className="hidden w-full xl:block">
        <Table data={tickets} columns={ticketColumns} />
      </div>
    </>
  );
};

export default TicketsTable;
