import { useAutoTaggingServiceContext } from "../../../../contexts/AutoTaggingServiceContext";
import AutoTaggingServicePagination from "../AutoTaggingServicePagination";
import InteractiveAutoTaggingResult from "../InteractiveAutoTaggingResult";

const AutoTaggingResultDisplay = () => {
  const { autoTaggingResult, hoveredItem } = useAutoTaggingServiceContext();

  const renderTag = (text: string) => (
    <div className="flex items-center gap-2 p-2 capitalize rounded-md bg-gradient-to-r from-[#FAFAED] from-10%  via-[#E3E4F8] via-30% to-[#EDEDFA] to-90% text-closed-shutter-300">
      <img src="/assets/image/free-trial/auto-tagging/sparkles.svg" alt="sparkles" className="size-6" />
      <span>{text}</span>
    </div>
  );

  const renderColorTag = (colorArray: any) => {
    const processColor = (color: any) => {
      if (typeof color === "string") {
        const [r, g, b] = color
          .replace(/[\[\]]/g, "")
          .split(" ")
          .map(Number);
        return { r, g, b };
      } else if (Array.isArray(color) && color.length > 0 && typeof color[0] === "string") {
        const [r, g, b] = color[0]
          .replace(/[\[\]]/g, "")
          .split(" ")
          .map(Number);
        return { r, g, b };
      }
      return null;
    };

    const renderSingleColor = (color: any, index: number) => {
      const processedColor = processColor(color);
      if (processedColor) {
        const { r, g, b } = processedColor;
        return (
          <div key={index} className="flex items-center gap-2 p-2 capitalize rounded-md bg-gradient-to-r from-[#FAFAED] from-10%  via-[#E3E4F8] via-30% to-[#EDEDFA] to-90% text-closed-shutter-300">
            <img src="/assets/image/free-trial/auto-tagging/sparkles.svg" alt="sparkles" className="size-6" />
            <span>Color</span>
            <div className="rounded-full size-6" style={{ backgroundColor: `rgb(${r}, ${g}, ${b})` }}></div>
          </div>
        );
      }
      return null;
    };

    if (Array.isArray(colorArray)) {
      return colorArray.map((color, index) => renderSingleColor(color, index));
    } else {
      return renderSingleColor(colorArray, 0);
    }
  };
  return (
    <div className="flex flex-col items-center w-full gap-32">
      <div className="flex flex-col items-center w-full gap-32">
        <div className="flex flex-col gap-4 p-4 border rounded-lg md:flex-row w-fit border-gorgonzola-blue-50">
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-3 p-4">
              <span className="rounded-full size-6 bg-gorgonzola-blue-50" />
              <h2 className="text-xs font-bold text-gorgonzola-blue-300">Auto Tagging Result</h2>
            </div>

            <InteractiveAutoTaggingResult />
          </div>

          {autoTaggingResult?.Clothes.length ? (
            <div className="flex flex-col justify-end gap-9">
              <div className="flex flex-col overflow-y-auto divide-y divide-gorgonzola-blue-50 max-h-[25rem] custom_scroll">
                {autoTaggingResult?.Clothes.map((clothingItem, index) => (
                  <div key={index} className={`grid gap-2 p-4 rounded-md transition-colors duration-600 *:w-fit ${hoveredItem === clothingItem ? "bg-gorgonzola-blue-100" : ""}`}>
                    {renderTag(clothingItem.ClotheType)}
                    {clothingItem.Design && renderTag(clothingItem.Design)}
                    {clothingItem.Sleeve && renderTag(clothingItem.Sleeve)}
                    {clothingItem.Neckline && renderTag(clothingItem.Neckline)}
                    {clothingItem.Crop && renderTag(clothingItem.Crop)}
                    {clothingItem.Colors && clothingItem.Colors.map((color, colorIndex) => color && renderColorTag(color))}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>

        <AutoTaggingServicePagination handlerButtonText={"Try Again"} isTaggingCompleted={true} />
      </div>
    </div>
  );
};

export default AutoTaggingResultDisplay;
