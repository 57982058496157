import React from "react";
import { Link } from "react-router-dom";
import { DocumentUpload } from "iconsax-react";
import StatusBadge from "../table/StatusBadge";
import { formatDate } from "../../utilities/helper/dateUtils";

interface Payment {
  id: number;
  hash_id: string;
  pay_operator: string;
  pay_operator_logo: string;
  status: string;
  amount: number;
  last4_card_number: string;
  created_at: number;
}

interface MobilePaymentCardProps {
  payment: Payment;
}

const MobilePaymentCard: React.FC<MobilePaymentCardProps> = ({ payment }) => {
  return (
    <div className="flex flex-col items-center px-4 text-center *:w-full *:py-6 *:border-b *:border-b-gorgonzola-blue-100">
      <div className="grid grid-cols-2 *:place-content-center *:place-self-center overflow-hidden">
        <Link to={`/dashboard/payment/${payment.hash_id}`} className="flex items-center gap-2 text-closed-shutter-300 hover:text-gorgonzola-blue-300">
          <span>#{payment.id}</span>
          <DocumentUpload className="size-5" variant="Broken" />
        </Link>
        <span className="font-bold">${payment.amount.toFixed(2)}</span>
      </div>

      <div className="grid grid-cols-2 *:place-content-center *:place-self-center overflow-hidden">
        <div className="flex items-center gap-2">
          <img src={payment.pay_operator_logo} alt={payment.pay_operator} className="w-8 h-6" />
          {payment.last4_card_number && <span>•••• {payment.last4_card_number}</span>}
        </div>
        {/* <StatusBadge
          statusText={payment.status === "paid" ? "Success" : payment.status === "failed" ? "Error" : "Pending"}
          isSuccess={payment.status === "paid" ? true : payment.status === "failed" ? false : null}
        /> */}
        <StatusBadge statusText={payment.status} />
      </div>

      <div className="grid grid-cols-2 *:place-content-center *:place-self-center overflow-hidden">
        <p>{payment.created_at ? formatDate(new Date(payment.created_at * 1000).toLocaleDateString()) : "-"}</p>
        <p>{payment.created_at ? new Date(payment.created_at * 1000).toLocaleTimeString() : "-"}</p>
      </div>
    </div>
  );
};

export default MobilePaymentCard;
