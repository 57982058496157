import { Box, Code } from "iconsax-react";
import { FREE_TRIAL } from "../../utilities/defaults/free-trial/plans";
import MainButton from "../button/MainButton";

const FreeTrial: React.FC = () => {
  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="w-full p-4 md:p-6">
        <h2 className="font-bold text-closed-shutter-300">Free Trial</h2>
      </div>

      <div className="flex flex-col items-center w-full h-full gap-6 p-4 overflow-y-auto md:p-6">
        <div className="relative hidden w-full overflow-hidden rounded-md md:block">
          <img src="/assets/image/free-trial/header.svg" alt="Free Trial Header" className="object-contain min-w-full" />
          {/* <span className="absolute inset-0 grid text-lg font-bold text-gorgonzola-blue-50 size-full place-content-center">Start Your Free Trial</span> */}
        </div>
        <div className="relative flex justify-center w-full overflow-hidden rounded-md md:hidden">
          <img src="/assets/image/free-trial/mobile-header.svg" alt="Free Trial Header" className="object-contain w-full max-w-[18.25rem]" />
          {/* <span className="absolute inset-0 grid text-lg font-bold text-gorgonzola-blue-50 size-full place-content-center">Start Your Free Trial</span> */}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-items-center md:gap-10 gap-[4.375rem] md:max-w-[63.5rem]">
          {FREE_TRIAL.map((plan) => (
            <div key={plan.id} className="flex flex-col items-center justify-between gap-4 p-3 md:p-4 w-full max-w-[18.25rem] md:h-full border rounded-md border-gorgonzola-blue-50">
              <div className="flex flex-col items-center w-full gap-4">
                <div className="w-full overflow-hidden rounded">
                  <img src={plan.image} alt={plan.image_alt} className="object-contain min-w-full" />
                </div>

                <div className="flex flex-col divide-y divide-gorgonzola-blue-50 *:py-4">
                  <h2 className="font-bold text-center text-closed-shutter-300">{plan.title}</h2>
                  <p className="text-closed-shutter-300/70">{plan.description}</p>
                </div>
              </div>

              <div className="flex justify-center gap-6">
                <MainButton type="secondary" to="https://api.shaku.tech/docs.html" passHref passHrefTarget="_blank">
                  <Code className="size-6" variant="Broken" />
                  <span>Api</span>
                </MainButton>

                <MainButton type="primary" to={`/dashboard/free-trial/${plan.sdk_href}`}>
                  <Box className="size-6" variant="Broken" />
                  <span>Sdk's</span>
                </MainButton>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FreeTrial;
