import React, { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { ArrowLeft, Printer } from "iconsax-react";
import { useAuth } from "../../../../contexts/AuthContext";
import StatusBadge from "../../../table/StatusBadge";
import MainButton from "../../../button/MainButton";
import ReusableModal from "../../../modal/ReusableModal";
import { getPaymentDetail } from "../../../../utilities/api/adminService";
import { PaymentDetails } from "../../../../utilities/types/admin/types";

interface PaymentDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentId: string;
}

const PaymentDetailsModal: React.FC<PaymentDetailsModalProps> = ({ isOpen, onClose, paymentId }) => {
  const { accessToken } = useAuth();
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      if (!accessToken) return;
      try {
        setLoading(true);
        const response = await getPaymentDetail(accessToken, paymentId);
        if (response.ok) {
          setPaymentDetails(response.data);
        } else {
          enqueueSnackbar(response.message || "Unable to retrieve payment details. Please try again.", { variant: "error" });
        }
      } catch (error) {
        console.error("Error fetching payment details:", error);
        enqueueSnackbar("An error occurred while retrieving payment details. Please try again later.", { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchPaymentDetails();
    }
  }, [accessToken, paymentId, isOpen]);

  const handlePrint = () => {
    window.print();
  };

  if (!paymentDetails) {
    return null;
  }

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="flex flex-col items-center gap-8 bg-white p-0 md:p-6 rounded-md w-full md:max-w-[33.5625rem] overflow-y-auto">
        <div className="grid w-full grid-cols-1 gap-y-4 md:gap-y-0 md:gap-x-4 md:grid-cols-3">
          <div className="flex flex-col items-center col-span-2 gap-4">
            <div className="w-full overflow-hidden rounded">
              <img src="/assets/image/plan/ConfirmationModalLogo.png" alt="Confirmation Modal Logo" className="w-full" />
            </div>
            <div className="flex flex-col items-start gap-1 p-4 bg-[#ededfa80] w-full rounded border border-gorgonzola-blue-100 *:flex *:flex-col *:gap-1">
              <div>
                <h5 className=" text-left text-2xs text-closed-shutter-300/70">Invoice to:</h5>
                <span className="text-xs capitalize text-gorgonzola-blue-300 ">{paymentDetails.from?.company_name || "-"}</span>
              </div>
              <div>
                <h5 className=" text-left text-2xs text-closed-shutter-300/70">Your email:</h5>
                <span className="text-xs capitalize text-closed-shutter-300">{`${paymentDetails.to.email || "-"}`}</span>
              </div>
              <div>
                <h5 className=" text-left text-2xs text-closed-shutter-300/70">Your name:</h5>
                <span className="text-xs capitalize text-closed-shutter-300">{`${paymentDetails.to.first_name || "-"} ${paymentDetails.to.last_name || ""}`}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-between p-4 border rounded border-gorgonzola-blue-100 min-h-max">
            <div className="flex flex-col items-center w-full gap-2">
              <div className="flex flex-col gap-1 p-3 bg-[#ededfa80] rounded w-full">
                <h5 className="text-2xs text-closed-shutter-300/70">Invoice number:</h5>
                <span className="text-xs capitalize text-closed-shutter-300">#{paymentDetails.id}</span>
              </div>
              <div className="flex flex-col gap-1 p-3 bg-[#ededfa80] rounded w-full">
                <h5 className="text-2xs text-closed-shutter-300/70">Issued:</h5>
                <span className="text-xs capitalize text-closed-shutter-300">
                  {new Date(paymentDetails.created_at * 1000).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
                </span>
              </div>
            </div>

            <span className="w-full h-px my-4 bg-gorgonzola-blue-100" />
            <div className="flex flex-col items-start justify-between gap-2">
              <h5 className="text-left text-2xs text-closed-shutter-300/70">Amount due:</h5>
              <span className="font-bold capitalize text-closed-shutter-300">${parseFloat(paymentDetails.price).toFixed(2)}</span>
              <span className="text-left text-2xs text-closed-shutter-300/70">
                Due by: {new Date(paymentDetails.created_at * 1000 + 30 * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
              </span>
            </div>
          </div>
        </div>

        <div className="flex items-center w-full gap-2">
          <div className="flex items-center w-full gap-2 overflow-x-auto">
            <div className="inline-flex items-center gap-2">
              <h5 className="text-2xs text-closed-shutter-300/70">Status:</h5>
              <StatusBadge statusText={paymentDetails.status} customClass="w-24 min-h-[2.125rem]" />
            </div>

            <div className="inline-flex items-center gap-2">
              <h5 className="text-2xs text-closed-shutter-300/70">P.method:</h5>
              <div className="flex items-center w-fit min-h-[2.125rem] px-2 py-1 border rounded-md gap-2 bg-gorgonzola-blue-50 border-gorgonzola-blue-200">
                <img src={paymentDetails.pay_operator_logo} alt={paymentDetails.pay_operator} className="w-8 h-6" />
                <span className="text-xs capitalize text-closed-shutter-300">{paymentDetails.pay_operator}</span>
              </div>
            </div>

            {paymentDetails.from.country ? (
              <div className="inline-flex items-center gap-2">
                <h5 className="text-2xs text-closed-shutter-300/70">Country:</h5>
                <div className="flex items-center w-fit min-h-[2.125rem] px-2 py-1 border rounded-md gap-2 bg-gorgonzola-blue-50 border-gorgonzola-blue-200">
                  <span className="text-xs capitalize text-closed-shutter-300">{paymentDetails.from.country}</span>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="w-full">
          <div className="flex items-center justify-start w-full gap-1 p-3 mb-2 border rounded bg-gorgonzola-blue-50">
            <h4 className="text-[#868DA6] text-2xs">Description:</h4>
            <span className="flex-grow text-xs font-bold rounded text-closed-shutter-300 capitalize line-clamp-1">
              {paymentDetails.product.label ? paymentDetails.product.label : "---"} / {paymentDetails.product_type === "charge" ? "Wallet Charge" : paymentDetails.product?.name || "N/A"}
            </span>
          </div>

          <div className={`grid ${paymentDetails?.product?.api?.total_requests ? "grid-cols-3" : "grid-cols-2"} border mb-2 text-2xs gap-3 text-[#868DA6] w-full rounded`}>
            {paymentDetails?.product?.api?.total_requests ? <span className="px-4 py-3 text-center">Requests</span> : null}
            <span className="px-4 py-3 text-center">Price</span>
            <span className="px-4 py-3 text-center">Total</span>
          </div>

          <div className={`grid ${paymentDetails?.product?.api?.total_requests ? "grid-cols-3" : "grid-cols-2"} text-xs gap-3 w-full *:text-center`}>
            {paymentDetails?.product?.api?.total_requests ? (
              <span className="p-3 bg-transparent border rounded border-gorgonzola-blue-50 text-closed-shutter-300/70">{paymentDetails.product.api.total_requests}</span>
            ) : null}
            <span className="p-3 bg-transparent border rounded border-gorgonzola-blue-50 text-closed-shutter-300/70">${parseFloat(paymentDetails.price).toFixed(2)}</span>
            <span className="p-3 rounded bg-gorgonzola-blue-50 text-closed-shutter-300">${parseFloat(paymentDetails.total).toFixed(2)}</span>
          </div>
        </div>

        <div className="flex items-center justify-between w-full px-4 py-3 border rounded">
          <span className="text-xs text-closed-shutter-300/70">Invoice Total</span>
          <span className="font-bold text-gorgonzola-blue-300">${parseFloat(paymentDetails.total).toFixed(2)}</span>
        </div>
      </div>

      <div className="flex justify-between w-[88%] no-print">
        <MainButton type="secondary" onClick={onClose}>
          <ArrowLeft className="size-6" variant="Broken" />
          Back
        </MainButton>

        <MainButton type="primary" onClick={handlePrint}>
          Print
          <Printer className="size-6" variant="Broken" />
        </MainButton>
      </div>
    </ReusableModal>
  );
};

export default PaymentDetailsModal;
