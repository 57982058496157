import React, { useState } from "react";
import { motion } from "framer-motion";
import { useGlobalInfo } from "../../contexts/GlobalInfoContext";
import PlanIcons, { ApiType } from "./PlanIcons";

export interface Plan {
  name: string;
  is_free_plan: boolean;
  label: string;
  main_price: number;
  remaining_price: number;
  apis: {
    name: string;
    real_price: number;
    paid_price: number;
    discount: number;
    total_requests: number;
    remaining_requests: number;
    remaining_requests_pre: number;
  }[];
}

interface PlanOverviewProps {
  activedOrders: { plan: Plan }[];
}

const PlanOverview: React.FC<PlanOverviewProps> = ({ activedOrders = [] }) => {
  const { userInfo } = useGlobalInfo();
  const [selectedApiType, setSelectedApiType] = useState<ApiType>(ApiType.SIZE_MEASUREMENT);
  const [currentPlanIndex, setCurrentPlanIndex] = useState<number>(0);

  const activePlan = activedOrders.find((order) => order.plan.apis[0].name === selectedApiType);

  const currentPlan = activePlan?.plan || {
    name: "No Plan",
    apis: [
      {
        name: selectedApiType,
        total_requests: 0,
        remaining_requests: 0,
        remaining_requests_pre: 0,
      },
    ],
  };

  const renderProgressBar = (plan: any) => {
    const usagePercentage = plan.apis[0].remaining_requests_pre ?? 0;
    return (
      <div className="relative w-full h-2.5 rounded-full bg-white">
        <div
          className={`absolute inset-0 h-full rounded-full bg-[linear-gradient(90deg,_#00A991_44.5%,_#008774_100%)] z-40`}
          style={{ width: `${usagePercentage}%` }}
          aria-label={`${usagePercentage}% of ${plan.name} used`}
        />

        <div className="absolute inset-0 w-[calc(100%-2rem)]">
          <motion.div
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: `${Math.min(usagePercentage <= 10 ? 10 : usagePercentage, 100)}%` }}
            transition={{ duration: 1, delay: 0.3 }}
            className="absolute w-full -translate-y-full bottom-3.5"
          >
            <div className="relative -translate-x-1/2 w-fit">
              <img src="/assets/image/plan/persentage_bg.svg" alt="percentage background" className="w-16" />
              <span className="absolute inset-0 z-20 flex items-center justify-center text-xs font-bold text-shindig-800">{usagePercentage}%</span>
            </div>
          </motion.div>
        </div>
      </div>
    );
  };

  return (
    <div className={`flex-grow w-full min-h-[11.125rem] p-4 rounded-md transition-colors duration-600 overflow-hidden md:overflow-visible ${activePlan ? "bg-shindig-50" : "bg-closed-shutter-50"}`}>
      <div className="flex flex-col gap-12">
        <div className="relative flex items-start justify-between h-fit ps-4">
          <span className="absolute inset-0 w-1 h-full rounded-lg bg-shindig-300" />
          <div className="relative flex flex-col w-full gap-4 font-bold">
            <h4 className="text-base text-closed-shutter-300 line-clamp-1 max-w-12">plan</h4>
            {/* <h4 className="text-base text-closed-shutter-300 line-clamp-1 max-w-12">{activePlan?.plan ? "Plan" : "NoPlan"}</h4> */}

            <div className="absolute top-0 right-0">
              <PlanIcons activedOrders={activedOrders} currentPlanIndex={currentPlanIndex} selectedApiType={selectedApiType} onPlanSelect={setCurrentPlanIndex} onApiSelect={setSelectedApiType} />
            </div>

            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-1 *:line-clamp-1">
                <span className="text-shindig-300/70">{currentPlan.apis[0].total_requests}</span>
                <span className="text-shindig-300">/ {currentPlan.apis[0].remaining_requests}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-end h-full gap-3">
          <div className="w-full">{renderProgressBar(currentPlan)}</div>
          <div className="w-full">
            <span className="text-2xs text-closed-shutter-300 line-clamp-1">
              Dear{userInfo?.first_name ? " " + userInfo?.first_name : null}, {currentPlan.apis[0].total_requests - currentPlan.apis[0].remaining_requests} requests have been used so far
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanOverview;
