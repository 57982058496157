import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { MainAdminSalePlanPercentagePlanType } from "../../../utilities/types/admin/types";

ChartJS.register(ArcElement, Tooltip, Legend);

interface SalePlanPercentageChartProps {
  data: {
    plan_data: {
      label: string;
      plan: MainAdminSalePlanPercentagePlanType;
      count: number;
      percentage: number;
    }[];
    service_data: {
      service_name: string;
      service_percentage: number;
    }[];
  };
}

type ServiceColors = {
  [key: string]: {
    [key: string]: string;
  };
};

const SalePlanPercentageChart: React.FC<SalePlanPercentageChartProps> = ({ data }) => {
  const serviceColors: ServiceColors = {
    auto_tagging: {
      "Enterprise Plan": "#2C7BD5",
      "Professional Plan": "#3491FA",
      "Standard Plan": "#59A5FB",
      "Basic Plan": "#7FBAFC",
      "Free Plan": "#A8D0FD",
    },
    body_measurement: {
      "Enterprise Plan": "#D29E1A",
      "Professional Plan": "#F7BA1E",
      "Standard Plan": "#F9C84A",
      "Basic Plan": "#FAD676",
      "Free Plan": "#FCE4A3",
    },
    visual_clothes_search: {
      "Enterprise Plan": "#11ABAB",
      "Professional Plan": "#14C9C9",
      "Standard Plan": "#42D4D4",
      "Basic Plan": "#70DFDF",
      "Free Plan": "#9DEAEA",
    },
  };

  const orderedData = [
    { label: "auto_tagging", color: "#3491FA", displayName: "Auto Tagging" },
    { label: "visual_clothes_search", color: "#14C9C9", displayName: "Visual Clothes Search" },
    { label: "body_measurement", color: "#F7BA1E", displayName: "Body Measurement" },
  ];

  const sortedData = orderedData.map((item) => {
    const foundItem = data.service_data.find((d) => d.service_name === item.label);
    return {
      ...item,
      service_percentage: foundItem ? foundItem.service_percentage : 0,
    };
  });

  const getColor = (label: string, plan: string) => {
    return serviceColors[label]?.[plan] || "#gray";
  };

  const chartData = {
    labels: data.plan_data.map((item) => `${item.label} - ${item.plan}`),
    datasets: [
      {
        label: "Plan Percentage",
        data: data.plan_data.map((item) => item.percentage),
        backgroundColor: data.plan_data.map((item) => getColor(item.label, item.plan)),
        hoverBackgroundColor: data.plan_data.map((item) => getColor(item.label, item.plan)),
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#fff" as const,
        titleColor: "#484554c2" as const,
        titleFont: {
          size: 12,
          weight: "bold" as const,
        },
        titleAlign: "center" as const,
        bodyColor: "#23262F" as const,
        bodyFont: {
          size: 12,
          weight: "bolder" as const,
        },
        bodyAlign: "left" as const,
        bodySpacing: 8,
        borderColor: "#C6C7F0" as const,
        borderWidth: 1,
        cornerRadius: 16,
        padding: 12,
        usePointStyle: true,
        boxPadding: 8,
        callbacks: {
          label: function (context: any) {
            const label = data.plan_data[context.dataIndex].plan;
            const value = context.parsed;
            return `${label}: ${value}%`;
          },
          labelTextColor: function (context: any) {
            return getColor(data.plan_data[context.dataIndex].label, data.plan_data[context.dataIndex].plan);
          },
        },
      },
    },
  };

  return (
    <div className="flex flex-col justify-between w-full min-h-[22rem]">
      <div className="relative w-full h-[14.625rem]">
        <div className="absolute bottom-0 z-20 h-full transform -translate-x-1/2 left-1/2">
          <Pie data={chartData} options={options} />
        </div>
      </div>
      <div className="flex flex-col items-center w-full gap-6 p-4">
        <div className="flex flex-col w-full gap-3">
          {sortedData.map((item, index) => (
            <div key={index} className="inline-flex items-center justify-between">
              <div className="inline-flex items-center gap-2">
                <span className="rounded-full size-3 min-w-3" style={{ backgroundColor: item.color }}></span>
                <span className="text-xs text-closed-shutter-300/70">{item.displayName}</span>
              </div>
              <span className="text-xs text-closed-shutter-300">{item.service_percentage}%</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SalePlanPercentageChart;
