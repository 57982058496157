import React, { useContext, useEffect, useState } from "react";
import { getUserShow } from "../../../../utilities/api/adminService";
import { AuthContext } from "../../../../contexts/AuthContext";
import { Link } from "react-router-dom";
import StatusBadge from "../../../table/StatusBadge";
import Loader from "../../../loading/Loader";

interface UserNavigationBoxesProps {
  userId: string;
}

interface UserDetails {
  usage: {
    total: number;
    success: number;
    error: number;
  };
  payment: {
    total_amount: number;
    total_invoice: number;
    error: number;
  };
  plan_service: Array<{
    name: string;
    label: string;
    apis: Array<{
      name: string;
      total_requests: number;
      remaining_requests: number;
      remaining_requests_pre: number;
    }>;
  }> | null;
}

const getPlanColor = (serviceName: string): string => {
  switch (serviceName) {
    case "Size Measurement":
      return "#F7BA1E";
    case "Clothes Recognition":
      return "#3491FA";
    case "Visual Clothes Search":
      return "#14C9C9";
    default:
      return "#484554";
  }
};

const getServiceTotal = (planService: any[], serviceName: string): string => {
  const plan = planService?.find((plan) => plan.apis[0].name === serviceName);
  return plan ? plan.apis[0].total_requests : "---";
};
const getServiceRemaining = (planService: any[], serviceName: string): string => {
  const plan = planService?.find((plan) => plan.apis[0].name === serviceName);
  return plan ? plan.apis[0].remaining_requests : "---";
};

const UserNavigationBoxes: React.FC<UserNavigationBoxesProps> = ({ userId }) => {
  const { accessToken } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!accessToken) return;

    const fetchUserDetail = async () => {
      setIsLoading(true);
      try {
        const response = await getUserShow(accessToken, userId);
        if (response.ok) {
          setUserDetails(response.data);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDetail();
  }, [userId, accessToken]);

  if (isLoading) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <Loader />
      </div>
    );
  }

  if (!userDetails)
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <span className="capitalize text-english-vermillion-300">No user details found</span>
      </div>
    );

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
      <Link
        to={`/admin/users/${userId}/usage`}
        className="group flex flex-col gap-3 p-4 md:p-6 rounded-md border border-gorgonzola-blue-50 bg-[#F9F9FA] hover:bg-gorgonzola-blue-900 transition-colors duration-600"
      >
        <div className="inline-flex items-center gap-2">
          <img src={"/assets/svg/admin-user-profile-usage.svg"} alt="Usage" className="p-2 transition-colors bg-white rounded-full group-hover:bg-gorgonzola-blue-100 duration-600" />
          <span className="font-bold transition-colors text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Usage</span>
        </div>

        <div className="flex flex-col items-center justify-between w-full gap-2">
          <div className="inline-flex items-center justify-between w-full">
            <div className="inline-flex items-center gap-2">
              <img src={"/assets/svg/Total-Req.svg"} alt="Total Req" className="bg-white rounded-full size-5 p-0.5" />
              <span className="transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Total Req</span>
            </div>
            <div className="font-bold transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">{userDetails.usage.total}</div>
          </div>

          <div className="inline-flex items-center justify-between w-full">
            <div className="inline-flex items-center gap-2">
              <img src={"/assets/svg/Success-Req.svg"} alt="Success Req" className="bg-white rounded-full size-5 p-0.5" />
              <span className="transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Success Req</span>
            </div>
            <div className="font-bold transition-colors text-2xs text-shindig-300 duration-600">{userDetails.usage.success}</div>
          </div>

          <div className="inline-flex items-center justify-between w-full">
            <div className="inline-flex items-center gap-2">
              <img src={"/assets/svg/Faild-Req.svg"} alt="Faild Req" className="bg-white rounded-full size-5 p-0.5" />
              <span className="transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Faild Req</span>
            </div>
            <div className="font-bold transition-colors text-2xs text-english-vermillion-300 duration-600">{userDetails.usage.error}</div>
          </div>
        </div>
      </Link>

      <Link
        to={`/admin/users/${userId}/payments`}
        className="group flex flex-col gap-3 p-4 md:p-6 rounded-md border border-gorgonzola-blue-50 bg-[#F9F9FA] hover:bg-gorgonzola-blue-900 transition-colors duration-600"
      >
        <div className="inline-flex items-center gap-2">
          <img src={"/assets/svg/admin-user-profile-payment.svg"} alt="Payment" className="p-2 transition-colors bg-white rounded-full group-hover:bg-gorgonzola-blue-100 duration-600" />
          <span className="font-bold transition-colors text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Payment</span>
        </div>

        <div className="flex flex-col items-center justify-between w-full gap-2">
          <div className="inline-flex items-center justify-between w-full">
            <div className="inline-flex items-center gap-2">
              <img src={"/assets/svg/Total-Amount.svg"} alt="Usage" className="bg-white rounded-full size-5 p-0.5" />
              <span className="transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Total Amount</span>
            </div>
            <div className="font-bold transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">${userDetails.payment.total_amount}</div>
          </div>

          <div className="inline-flex items-center justify-between w-full">
            <div className="inline-flex items-center gap-2">
              <img src={"/assets/svg/Total-Invoise.svg"} alt="Usage" className="bg-white rounded-full size-5 p-0.5" />
              <span className="transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Total Invoice</span>
            </div>
            <div className="font-bold transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">{userDetails.payment.total_invoice}</div>
          </div>
          <div className="inline-flex items-center justify-between w-full">
            <div className="inline-flex items-center gap-2">
              <img src={"/assets/svg/Faild-Req.svg"} alt="Faild Req" className="bg-white rounded-full size-5 p-0.5" />
              <span className="transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Payment Error</span>
            </div>
            <div className="font-bold transition-colors text-2xs text-english-vermillion-300 duration-600">{userDetails.payment.error}</div>
          </div>
        </div>
      </Link>

      <Link
        to={`/admin/users/${userId}/plans`}
        className="group flex flex-col gap-3 p-4 md:p-6 rounded-md border border-gorgonzola-blue-50 bg-[#F9F9FA] hover:bg-gorgonzola-blue-900 transition-colors duration-600"
      >
        <div className="flex items-center justify-between w-full">
          <div className="inline-flex items-center gap-2">
            <img src={"/assets/svg/admin-user-profile-plan&service.svg"} alt="Plan & Service" className="p-2 transition-colors bg-white rounded-full group-hover:bg-gorgonzola-blue-100 duration-600" />
            <span className="font-bold transition-colors text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Plan & Service</span>
          </div>

          <StatusBadge statusText={userDetails.plan_service ? "active" : "inactive"} />
        </div>

        <div className="flex flex-col items-center justify-between w-full gap-2">
          <div className="inline-flex items-center justify-between w-full">
            <div className="inline-flex items-center gap-2">
              <div className="w-1 h-5 rounded-md" style={{ backgroundColor: getPlanColor("Size Measurement") }} />
              <span className="transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Size Measurement</span>
            </div>
            <div className="font-bold transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">
              <span className="text-[#F7BA1E]/50">{getServiceTotal(userDetails.plan_service || [], "Size Measurement")}</span>
              <span className="text-[#F7BA1E]"> / {getServiceRemaining(userDetails.plan_service || [], "Size Measurement")} </span>
            </div>
          </div>

          <div className="inline-flex items-center justify-between w-full">
            <div className="inline-flex items-center gap-2">
              <div className="w-1 h-5 rounded-md" style={{ backgroundColor: getPlanColor("Clothes Recognition") }} />
              <span className="transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Clothes Recognition</span>
            </div>
            <div className="font-bold transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">
              <span className="text-[#3491FA]/50">{getServiceTotal(userDetails.plan_service || [], "Clothes Recognition")}</span>
              <span className="text-[#3491FA]"> / {getServiceRemaining(userDetails.plan_service || [], "Clothes Recognition")} </span>
            </div>
          </div>

          <div className="inline-flex items-center justify-between w-full">
            <div className="inline-flex items-center gap-2">
              <div className="w-1 h-5 rounded-md" style={{ backgroundColor: getPlanColor("Visual Clothes Search") }} />
              <span className="transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">Visual Clothes Search</span>
            </div>
            <div className="font-bold transition-colors text-2xs text-closed-shutter-300 group-hover:text-gorgonzola-blue-100 duration-600">
              <span className="text-[#14C9C9]/50">{getServiceTotal(userDetails.plan_service || [], "Visual Clothes Search")}</span>
              <span className="text-[#14C9C9]"> / {getServiceRemaining(userDetails.plan_service || [], "Visual Clothes Search")} </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default UserNavigationBoxes;
