import { motion } from "framer-motion";

const HamburgerIcon = ({ isOpen }: { isOpen: boolean }) => {
  const topLineVariants = {
    open: { rotate: -45, y: 6 },
    closed: { rotate: 0, y: 0 },
  };

  const middleLineVariants = {
    open: { opacity: 0 },
    closed: { opacity: 1 },
  };

  const bottomLineVariants = {
    open: { rotate: 45, y: -6 },
    closed: { rotate: 0, y: 0 },
  };

  return (
    <div className={`flex flex-col gap-1 p-1.5 cursor-pointer rounded-lg transition-colors duration-600 ${isOpen ? "bg-english-vermillion-300" : "bg-gorgonzola-blue-300  "}`}>
      <motion.span className="block w-3.5 h-0.5 bg-gorgonzola-blue-50 rounded-full" variants={topLineVariants} animate={isOpen ? "open" : "closed"} transition={{ duration: 0.3 }} />
      <motion.span className="block w-3.5 h-0.5 bg-gorgonzola-blue-50 rounded-full" variants={middleLineVariants} animate={isOpen ? "open" : "closed"} transition={{ duration: 0.3 }} />
      <motion.span className="block w-3.5 h-0.5 bg-gorgonzola-blue-50 rounded-full" variants={bottomLineVariants} animate={isOpen ? "open" : "closed"} transition={{ duration: 0.3 }} />
    </div>
  );
};

export default HamburgerIcon;
