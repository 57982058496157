import React from "react";
import { Link, useParams } from "react-router-dom";
import UserInfo from "./UserProfile/UserInfo";
import UserNavigationBoxes from "./UserProfile/UserNavigationBoxes";
import UserTicketHistory from "./UserProfile/UserTicketHistory";
import { adminRoutes } from "../../../utilities/defaults/routes/adminRoutes";

const UserProfile: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  if (!userId) {
    return <div>User ID is not available.</div>;
  }

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="flex flex-col w-full gap-1 p-4 md:p-6">
        <Link to={adminRoutes.users.list.path} className="text-xs transition-colors w-fit text-closed-shutter-300/70 hover:text-gorgonzola-blue-300 duration-600">
          Users List
        </Link>
        <span className="font-bold text-closed-shutter-300">#{userId}</span>
      </div>

      <div className="w-full p-4 space-y-6 md:p-6">
        <UserInfo userId={userId} />
        <UserNavigationBoxes userId={userId} />
        <UserTicketHistory userId={userId} />
      </div>
    </div>
  );
};

export default UserProfile;
