import React from "react";
import { TableColumn } from "../../../../utilities/types/support/types";
import Table from "../../../table/Table";
import StatusBadge from "../../../table/StatusBadge";
import MainButton from "../../../button/MainButton";
import { Eye } from "iconsax-react";
import { Link } from "react-router-dom";
import { TransformTime } from "../../../../utilities/helper/TransformPublishedAt";
import MobileTicketCard from "./MobileTicketCard";

interface TicketsTableProps {
  tickets: any[];
}

const TicketsTable: React.FC<TicketsTableProps> = ({ tickets }) => {
  const ticketColumns: TableColumn<any>[] = [
    {
      key: "id",
      header: "Ticket ID",
      render: (value) => <span className="text-sm font-medium">#{value ? value : "-"}</span>,
    },
    {
      key: "title",
      header: "Subject",
      render: (value) => <span className="text-sm">{value ? value : "-"}</span>,
    },
    {
      key: "section",
      header: "Dep",
      render: (value) => <span className="text-sm">{value ? value : "-"}</span>,
    },
    {
      key: "status",
      header: "Status",
      render: (value) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.toLowerCase()} />
        </div>
      ),
    },
    {
      key: "created_at",
      header: "Date",
      render: (value) => <span className="text-sm">{new Date(value * 1000).toLocaleDateString()}</span>,
    },
    {
      key: "time",
      header: "Time",
      render: (value, item) => <span className="text-sm">{item.created_at ? TransformTime(new Date(item.created_at * 1000).toISOString()) : "-"}</span>,
    },
    {
      key: "id",
      header: "Show Answer",
      render: (value, item) => (
        <Link to={`/admin/tickets/${item.id}`}>
          <MainButton type={item.status === "open" ? "primary" : "ghostOutlined"}>
            <Eye variant="Broken" className="mr-2 size-4" />
            Answer
          </MainButton>
        </Link>
      ),
    },
  ];

  return (
    <>
      <div className="block xl:hidden odd:*:bg-gorgonzola-blue-50/30 even:*:bg-white">
        {tickets.map((ticket) => (
          <MobileTicketCard key={ticket.id} ticket={ticket} />
        ))}
      </div>

      <div className="hidden w-full xl:block">
        <Table data={tickets} columns={ticketColumns} />
      </div>
    </>
  );
};

export default TicketsTable;
