import React from "react";
import BalanceCard from "./BalanceCard";
import TransactionChart from "./TransactionChart";
import LastTransaction from "./LastTransaction";
import { Transaction } from "../../utilities/types/mianDashboard/types";
import { Link, useNavigate } from "react-router-dom";
import MainButton from "../button/MainButton";
import { Crown1 } from "iconsax-react";

interface BalanceOverviewProps {
  balance: number;
  transactions: Transaction[];
}

const BalanceOverview: React.FC<BalanceOverviewProps> = ({ balance, transactions }) => {
  const navigate = useNavigate();

  const handleNavigateToPlan = () => {
    navigate("/dashboard/plans#buy_plan");
  };

  return (
    <div className="relative flex flex-col flex-grow min-h-full gap-4 p-4 rounded-md md:min-h-fit bg-gorgonzola-blue-50 ">
      <div className="flex flex-col justify-between h-full gap-7">
        <BalanceCard balance={balance} />
        {transactions.length > 0 ? (
          <div className="flex justify-between w-full">
            <LastTransaction action={transactions[transactions.length - 1].action} amount={transactions[transactions.length - 1].amount} />
            <TransactionChart transactions={transactions} />
          </div>
        ) : (
          <div className="absolute inset-0 size-full bg-gorgonzola-blue-50/70 flex flex-col items-center justify-center gap-6 p-4 rounded-md backdrop-blur-[4.3px]">
            <div className="inline-flex w-full gap-3">
              <div className="rounded-full size-3 min-w-3 bg-gorgonzola-blue-300" />
              <h2 className="text-xs text-gorgonzola-blue-300">Subscribe to a service plan to gain access to your financial information.</h2>
            </div>
            <MainButton type="primary" onClick={handleNavigateToPlan}>
              Buy a plan
              <Crown1 className="size-6" variant="Broken" />
            </MainButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default BalanceOverview;
