import React, { useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useVisualClothesServiceContext } from "../../../contexts/VisualClothesServiceContext";
import { ClothingItem } from "../../../utilities/types/free-trial/auto-tagging-service/types";
import ImageDisplay from "./VisualClothesServiceImageDisplay";

const InteractiveVisualClothesResult: React.FC = () => {
  const { visualClothesResult, getAdjustedCoordinates, hoveredItem, setHoveredItem, searchResultsMap, isFirstServiceCalled } = useVisualClothesServiceContext();

  const containerRef = useRef<HTMLDivElement>(null);

  const getCenter = (region: ClothingItem["Region"]) => {
    const { X1, Y1, X2, Y2 } = getAdjustedCoordinates(region);
    return {
      x: (X1 + X2) / 2,
      y: (Y1 + Y2) / 2,
    };
  };

  const getInverseClipPath = (region: ClothingItem["Region"]) => {
    const { X1, Y1, X2, Y2 } = getAdjustedCoordinates(region);

    return `polygon(
      0% 0%,
      100% 0%,
      100% 100%,
      0% 100%,
      0% 0%,
      ${X1}% ${Y1}%,
      ${X1}% ${Y2}%,
      ${X2}% ${Y2}%,
      ${X2}% ${Y1}%,
      ${X1}% ${Y1}%
    )`;
  };

  const infiniteAnimation = {
    x: "-50%",
    y: "-50%",
    scale: [1, 1.2, 1],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };

  return (
    <div ref={containerRef} className="relative w-fit">
      <ImageDisplay />

      {visualClothesResult?.Clothes.map((item, index) => {
        const isItemDisabled = searchResultsMap[index]?.loading || !searchResultsMap[index];
        const center = getCenter(item.Region);
        const { X1, Y1, X2, Y2 } = getAdjustedCoordinates(item.Region);

        return (
          <div key={index}>
            {!hoveredItem ? (
              <motion.div
                className={`absolute rounded-full ${isItemDisabled ? "opacity-30 cursor-not-allowed" : "opacity-50 cursor-pointer"} bg-gorgonzola-blue-300`}
                style={{
                  left: `${center.x}%`,
                  top: `${center.y}%`,
                  width: "20px",
                  height: "20px",
                }}
                initial={{ x: "-50%", y: "-50%" }}
                animate={!isItemDisabled ? infiniteAnimation : {}}
                whileHover={!isItemDisabled ? { scale: 1.5 } : {}}
                onClick={() => !isItemDisabled && setHoveredItem(item)}
              />
            ) : null}

            {hoveredItem === item ? (
              <div
                className="absolute"
                style={{
                  left: `${X1}%`,
                  top: `${Y1}%`,
                  width: `${X2 - X1}%`,
                  height: `${Y2 - Y1}%`,
                  cursor: "pointer",
                }}
                onClick={() => setHoveredItem(null)}
              />
            ) : null}
          </div>
        );
      })}

      <AnimatePresence>
        {hoveredItem
          ? (() => {
              return (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="absolute inset-0 bg-black bg-opacity-70"
                  style={{
                    clipPath: getInverseClipPath(hoveredItem.Region),
                  }}
                />
              );
            })()
          : null}
      </AnimatePresence>
    </div>
  );
};

export default InteractiveVisualClothesResult;
