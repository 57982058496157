import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Crown1 } from "iconsax-react";
import MainButton from "../button/MainButton";
import ReusableModal from "../modal/ReusableModal";

interface FreePlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  freeRequests: number;
}

const FreePlanModal: React.FC<FreePlanModalProps> = ({ isOpen, onClose, freeRequests }) => {
  const navigate = useNavigate();

  const handleNavigateToPlan = () => {
    onClose();
    navigate("/dashboard/plans#buy_plan");
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col items-center gap-6 bg-white rounded-xl p-6 w-fit max-w-[22.375rem]">
        <img src="/assets/image/plan/update-plan.svg" alt="Update Plan" className="w-[13.75rem]" />
        <div className="space-y-3 text-center">
          <h2 className="text-closed-shutter-300">Update your plan</h2>
          <p className="text-xs text-closed-shutter-300/70">
            Your subscription is currently free, allowing you to make {freeRequests} requests per service for free. By{" "}
            <Link to="/dashboard/plans#buy_plan" className="underline text-gorgonzola-blue-300" onClick={onClose}>
              purchasing a plan
            </Link>
            , you can access additional features and make more requests per service.
          </p>
        </div>
        <MainButton type="primary" onClick={handleNavigateToPlan}>
          Buy a plan
          <Crown1 className="size-6" variant="Broken" />
        </MainButton>
      </div>
    </ReusableModal>
  );
};

export default FreePlanModal;
