import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import MainButton from "../../button/MainButton";
import TextField from "../../fields/TextField";
import PhoneField from "../../fields/PhoneField";
import { useAuth } from "../../../contexts/AuthContext";
import { useGlobalInfo } from "../../../contexts/GlobalInfoContext";
import { updateUserInfo, setProfilePicture } from "../../../utilities/api/userService";
import { enqueueSnackbar } from "notistack";
import AvatarUpload from "../AvatarUpload";

interface ProfileFormData {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

const BasicInformation: React.FC = () => {
  const { accessToken } = useAuth();
  const { userInfo, refreshUserInfo } = useGlobalInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialData, setInitialData] = useState<ProfileFormData>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm<ProfileFormData>({
    mode: "onChange",
    defaultValues: initialData,
  });

  useEffect(() => {
    if (userInfo) {
      setInitialData({
        first_name: userInfo.first_name || "",
        last_name: userInfo.last_name || "",
        email: userInfo.email || "",
        phone_number: userInfo.phone_number || "",
      });
    }
  }, [userInfo]);

  useEffect(() => {
    reset(initialData);
  }, [initialData, reset]);

  const onSubmit: SubmitHandler<ProfileFormData> = async (data) => {
    if (!accessToken) {
      enqueueSnackbar("Authentication failed. Please log in again.", { variant: "error" });
      return;
    }

    setIsSubmitting(true);
    try {
      await updateUserInfo(accessToken, data);
      enqueueSnackbar("Profile updated successfully!", { variant: "success" });
      await refreshUserInfo();
    } catch (error) {
      console.error("Error updating profile:", error);
      enqueueSnackbar("Unable to update profile. Please try again.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAvatarUpload = async (base64Image: string) => {
    if (!accessToken) {
      enqueueSnackbar("Authentication failed. Please log in again.", { variant: "error" });
      return;
    }

    try {
      const response = await setProfilePicture(accessToken, base64Image);
      if (response.ok) {
        enqueueSnackbar("Profile picture updated successfully!", { variant: "success" });
        await refreshUserInfo();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error updating profile picture:", error);
      enqueueSnackbar("Unable to update profile. Please try again. picture", { variant: "error" });
    }
  };

  return (
    <div className="flex flex-col gap-13 min-h-[40rem]">
      <div className="flex justify-center">
        <AvatarUpload onUpload={handleAvatarUpload} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-13">
          <Controller
            name="first_name"
            control={control}
            rules={{ required: "First name is required" }}
            render={({ field }) => <TextField label="First Name" placeholder="Enter your first name" {...field} error={!!errors.first_name} success={!!watch("first_name") && !errors.first_name} />}
          />
          <Controller
            name="last_name"
            control={control}
            rules={{ required: "Last name is required" }}
            render={({ field }) => <TextField label="Last Name" placeholder="Enter your last name" {...field} error={!!errors.last_name} success={!!watch("last_name") && !errors.last_name} />}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => <TextField label="Email" type="email" placeholder="YourEmail@gmail.com" {...field} error={!!errors.email} success={!!watch("email") && !errors.email} />}
          />
          <Controller
            name="phone_number"
            control={control}
            rules={{ required: "Phone number is required" }}
            render={({ field }) => (
              <PhoneField label="Phone Number" placeholder="Enter your phone number" {...field} error={!!errors.phone_number} success={!!watch("phone_number") && !errors.phone_number} />
            )}
          />
        </div>
        <div className="flex justify-end w-full">
          <MainButton type="submit" isSubmit disabled={!isValid || isSubmitting}>
            {isSubmitting ? "Updating..." : "Save Changes"}
          </MainButton>
        </div>
      </form>
    </div>
  );
};

export default BasicInformation;
