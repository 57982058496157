import { AxiosResponse } from "axios";
import instance from "./axiosInstance";
import { AvailableChartsResponse, MeasurementDataResponse, MeasurementRequest, SizeGuideRequest, SizeGuideResponse } from "../types/measurement-data/types";

export const submitSizeMeasurement = async (accessToken: string, data: MeasurementRequest): Promise<MeasurementDataResponse> => {
  try {
    const response: AxiosResponse<MeasurementDataResponse> = await instance.post(`/api/v1/services/sizeMeasurement`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending size measurement:", error);
    throw error;
  }
};

export const SizeGuide = async (accessToken: string, data: any): Promise<SizeGuideResponse> => {
  try {
    const response: AxiosResponse<SizeGuideResponse> = await instance.post(`/api/v1/services/size_guide`, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending size guide request:", error);
    throw error;
  }
};

export const getAvailableCharts = async (accessToken: string): Promise<AvailableChartsResponse> => {
  try {
    const response: AxiosResponse<AvailableChartsResponse> = await instance.post(`/api/v1/services/available_charts`, null, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting available charts:", error);
    throw error;
  }
};
