import React, { useEffect } from "react";
import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
import BodyMeasurementServiceImageDisplay from "../BodyMeasurementServiceImageDisplay";
import ErrorDisplay from "../../ErrorDisplay";
import FreeTrialServicesLoader from "../../FreeTrialServicesLoader";
import { useNavigate } from "react-router-dom";

const Measurement: React.FC = () => {
  const { height, submitMeasurement, fetchAvailableCharts, error, setError, resetBodyMeasurementService } = useBodyMeasurementServiceContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeasurementData = async () => {
      await submitMeasurement();
      await fetchAvailableCharts();
    };
    fetchMeasurementData();
  }, []);

  if (error) {
    return (
      <ErrorDisplay
        error={error}
        onRetry={() => {
          setError(null);
          // submitMeasurement();
          resetBodyMeasurementService();
          navigate("/dashboard/free-trial/body-measurment");
        }}
      />
    );
  }

  return (
    <div className="flex flex-col items-center gap-32 w-full md:w-[33.8%]">
      <FreeTrialServicesLoader />
      <div className="flex flex-col items-center gap-10">
        <span className="text-center font-bold capitalize text-closed-shutter-300 *:font-bold *:text-gorgonzola-blue-300">
          Your height is: <span>{height}cm</span>
        </span>
        <BodyMeasurementServiceImageDisplay />
      </div>
    </div>
  );
};

export default Measurement;
