import React, { useEffect } from "react";
import { useAutoTaggingServiceContext } from "../../../../contexts/AutoTaggingServiceContext";
import MainButton from "../../../button/MainButton";
import { CloudChange, DocumentUpload } from "iconsax-react";
import AutoTaggingServicePagination from "../AutoTaggingServicePagination";
import ImageDisplay from "../AutoTaggingServiceImageDisplay";

const UploadPic: React.FC = () => {
  const { uploadedImage, handleImageUpload, setCanProceedToNextStep } = useAutoTaggingServiceContext();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      handleImageUpload(files[0]);
    }
  };

  useEffect(() => {
    setCanProceedToNextStep(uploadedImage !== null);
  }, [uploadedImage, setCanProceedToNextStep]);

  return (
    <div className="flex flex-col items-center w-full gap-8 md:gap-32">
      <div className="flex flex-col xl:flex-row justify-center w-fit gap-4 *:flex *:flex-col md:*:flex-row *:items-center md:*:justify-center xl:*:justify-between *:gap-4 *:rounded-md *:border *:border-gorgonzola-blue-50 *:p-4">
        <div>
          <ImageDisplay />

          <div className="flex flex-col justify-between gap-4 min-w-[15.125rem] md:max-w-[25rem]">
            <div className="flex flex-col gap-4">
              <div className="flex gap-2 font-bold text-gorgonzola-blue-300">
                <span className="grid rounded-full bg-gorgonzola-blue-50 min-w-6 size-6 place-content-center">1</span>
                <h2>Please check the following details before uploading your clothes image</h2>
              </div>
              <ul className="space-y-1 text-xs list-disc ps-6 text-closed-shutter-300">
                {/* <li>Front view of clothing clearly visible</li>
                <li>Entire item included in image</li>
                <li>No shadows on clothing for color accuracy</li>
                <li>Sleeves and collar clearly visible</li>
                <li>Gender based on wearer of clothing</li>
                <li>Limit image size to a maximum of 5MB.</li> */}
                <li>Ensure the front view of the clothing is fully visible.</li>
                <li>Include the entire item within the frame.</li>
                <li>Avoid shadows on the clothing for accurate color representation.</li>
                <li>Make sure sleeves and collars are clearly shown.</li>
                <li>Gender based on wearer of clothing.</li>
                <li>Limit image size to a maximum of 5MB.</li>
              </ul>
            </div>

            <div className="flex flex-col w-full gap-2">
              <MainButton type="primary" customClass="justify-center !p-0">
                <label htmlFor="ImageFile" className="z-50 flex items-center justify-center h-full min-w-full gap-2 cursor-pointer">
                  {uploadedImage ? <CloudChange variant="Broken" /> : <DocumentUpload variant="Broken" />}
                  <span>{uploadedImage ? "Change Image" : "Upload Image"}</span>
                </label>
              </MainButton>
              <input id="ImageFile" type="file" className="hidden" onChange={handleImageChange} accept="image/*" />

              {/* <MainButton type="secondary" customClass="justify-center">
                <Camera variant="Broken" />
                <span>Camera</span>
              </MainButton> */}
            </div>
          </div>
        </div>
      </div>

      <AutoTaggingServicePagination />
    </div>
  );
};

export default UploadPic;
