import { createContext, useState, FC, ReactNode, useContext, useCallback } from "react";
import { AuthContext } from "./AuthContext";
import { getContacts } from "../utilities/api/adminService";

interface ContactsContextValue {
  contactsData: any[];
  totalPages: number;
  currentPage: number;
  perPage: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  loading: boolean;
  fetchContactsData: (page?: number, filters?: any) => Promise<void>;
  setPerPage: (perPage: number) => void;
}

export const ContactsContext = createContext<ContactsContextValue>({
  contactsData: [],
  totalPages: 1,
  currentPage: 1,
  perPage: 10,
  totalItems: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  loading: false,
  fetchContactsData: async () => {},
  setPerPage: () => {},
});

export const ContactsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { accessToken } = useContext(AuthContext);
  const [contactsData, setContactsData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchContactsData = useCallback(
    async (page: number = 1) => {
      if (!accessToken) return;
      try {
        setLoading(true);
        const response = await getContacts(page);
        setContactsData(response.data);
        setTotalItems(response.meta.pagination.total);
        setTotalPages(response.meta.pagination.pageCount);
        setCurrentPage(response.meta.pagination.page);
        setHasPreviousPage(response.meta.pagination.page > 1);
        setHasNextPage(response.meta.pagination.page < response.meta.pagination.pageCount);
      } catch (error) {
        console.error("Error fetching contacts data:", error);
      } finally {
        setLoading(false);
      }
    },
    [accessToken]
  );

  return (
    <ContactsContext.Provider
      value={{
        contactsData,
        totalPages,
        currentPage,
        perPage,
        totalItems,
        hasPreviousPage,
        hasNextPage,
        loading,
        fetchContactsData,
        setPerPage,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export const useContacts = () => {
  const context = useContext(ContactsContext);
  if (!context) {
    throw new Error("useContacts must be used within a ContactsProvider");
  }
  return context;
};
