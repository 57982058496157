import React from "react";
import { DashboardProvider } from "../../contexts/DashboardContext";
import Payment from "./Payment";

const PaymentProvider = () => {
  return (
    <DashboardProvider>
      <Payment />
    </DashboardProvider>
  );
};

export default PaymentProvider;
