import { useGlobalInfo } from "../../contexts/GlobalInfoContext";
import {
  USER_MAIN_SIDEBAR_ITEMS,
  USER_PRIMARY_SIDEBAR_ITEMS,
  USER_UPGRADE_SIDEBAR_ITEMS,
  ADMIN_MAIN_SIDEBAR_ITEMS,
  ADMIN_PRIMARY_SIDEBAR_ITEMS,
  ADMIN_UPGRADE_SIDEBAR_ITEMS,
} from "../defaults/sidebar/sidebar-items";

export const useSidebarItems = () => {
  const { isAdmin } = useGlobalInfo();

  const mainItems = isAdmin ? ADMIN_MAIN_SIDEBAR_ITEMS : USER_MAIN_SIDEBAR_ITEMS;
  const primaryItems = isAdmin ? ADMIN_PRIMARY_SIDEBAR_ITEMS : USER_PRIMARY_SIDEBAR_ITEMS;
  const upgradeItems = isAdmin ? ADMIN_UPGRADE_SIDEBAR_ITEMS : USER_UPGRADE_SIDEBAR_ITEMS;

  return {
    mainItems,
    primaryItems,
    upgradeItems,
  };
};
