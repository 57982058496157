import React from "react";
import BodyMeasurementServicePagination from "../BodyMeasurementServicePagination";
import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
import BodyMeasurementServiceImageDisplay from "../BodyMeasurementServiceImageDisplay";
import Loader from "../../../loading/Loader";
import { getValidMeasurements } from "../../../../utilities/helper/filterValidMeasurements";
import SizeGuideQuestionnaire from "../SizeGuideQuestionnaire";

const Result: React.FC = () => {
  const { measurementData } = useBodyMeasurementServiceContext();

  if (!measurementData) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <Loader />
      </div>
    );
  }

  const validMeasurements = getValidMeasurements(measurementData);

  return (
    <div className="flex flex-col items-center w-full gap-32">
      <div className="flex flex-col items-center w-full gap-10">
        <BodyMeasurementServiceImageDisplay />

        <div className="grid w-full grid-rows-3 gap-1 p-3 border rounded-md border-gorgonzola-blue-50 max-w-[26.375rem]">
          {validMeasurements.map((measurement, index) => (
            <div key={measurement.key} className={`flex justify-between gap-1 w-full *:rounded *:p-2 ${index % 2 === 0 ? "*:bg-gorgonzola-blue-50" : "*:bg-gorgonzola-blue-100"}`}>
              <span className="flex items-center">
                <img src={measurement.icon} alt={`${measurement.label} icon`} className="size-5 min-w-5" />
              </span>
              <span className="text-xs md:text-base flex-grow md:min-w-[11.25rem] line-clamp-1">{measurement.label}</span>
              <span className="text-xs md:text-base w-[6.2rem] md:w-36 line-clamp-1">{measurement.value} cm</span>
            </div>
          ))}
        </div>

        <SizeGuideQuestionnaire />

        <BodyMeasurementServicePagination handlerButtonText={"Try Again"} isMeasurementSuccessed={true} />
      </div>
    </div>
  );
};

export default Result;
