import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { UserPaymentsContext } from "../../../contexts/UserPaymentsContext";
import Loader from "../../loading/Loader";
import FinanceSummaryInfo from "../../payment/FinanceSummaryInfo";
import { useUsersList } from "../../../contexts/UsersListContext";
import FinanceSummaryChart from "../../payment/FinanceSummaryChart";
import UserDetails from "./UserDetails";
import { adminRoutes } from "../../../utilities/defaults/routes/adminRoutes";
import { ArrowRight2 } from "iconsax-react";
import PaymentsTable from "./PaymentsTable";
import { useGlobalInfo } from "../../../contexts/GlobalInfoContext";

const UserPayments: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { transfersHistory, walletSummary, paymentsList, loading, fetchUserPaymentsData, fetchUserPaymentChart, fetchUserPaymentSum } = useContext(UserPaymentsContext);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { userInfo } = useGlobalInfo();

  useEffect(() => {
    if (userId && userInfo) {
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);
      const dateRange = { from_date: userInfo?.created_at.toString(), to_date: currentTimeInSeconds.toString() };
      fetchUserPaymentsData(userId, currentPage);
      fetchUserPaymentChart(userId, dateRange);
      fetchUserPaymentSum(userId, dateRange);
    }
  }, [userId, userInfo, currentPage]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  if (loading) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <Loader />
      </div>
    );
  }

  if (!transfersHistory && !walletSummary && !paymentsList) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <span className="capitalize text-english-vermillion-300">No user details found</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="flex flex-col w-full p-4 md:p-6">
        <div className="flex items-center gap-1">
          <Link to={adminRoutes.users.list.path} className="text-xs transition-colors w-fit text-closed-shutter-300/70 hover:text-gorgonzola-blue-300 duration-600">
            Users List
          </Link>
          <ArrowRight2 className="text-closed-shutter-300/70 size-4" />
          <Link to={`/admin/users/${userId}`} className="font-bold transition-colors text-closed-shutter-300 hover:text-gorgonzola-blue-300 duration-600">
            #{userId}
          </Link>
        </div>
        <span className="font-bold text-closed-shutter-300">Payment</span>
      </div>

      {loading || !userId ? (
        <div className="flex items-center justify-center p-6">
          <Loader />
        </div>
      ) : !userInfo ? (
        <div className="flex justify-center w-full p-4 md:p-6">
          <span className="capitalize text-english-vermillion-300">No user details found</span>
        </div>
      ) : (
        <div className="flex flex-col w-full gap-6 p-4 md:p-6">
          <UserDetails userId={userId} />
          <div className="flex flex-col justify-between gap-4 xl:flex-row">
            <FinanceSummaryChart data={transfersHistory} userCreatedAt={userInfo.created_at} />
            <FinanceSummaryInfo
              data={{
                payment: walletSummary?.deposits,
                pending: walletSummary?.withdraws,
                balance: walletSummary?.balance,
              }}
            />
          </div>

          <PaymentsTable data={paymentsList} onPageChange={handlePageChange} />
        </div>
      )}
    </div>
  );
};

export default UserPayments;
