import { FC, ReactNode } from "react";
import { UsersListProvider } from "../../contexts/UsersListContext";
import { UsageAnalyticsProvider } from "../../contexts/UsageAnalyticsContext";
import { PlansAnalyticsProvider } from "../../contexts/PlansAnalyticsContext";
import { PaymentsAnalyticsProvider } from "../../contexts/PaymentsAnalyticsContext";
import { TicketsProvider } from "../../contexts/TicketsContext";
import { MainAdminProvider } from "../../contexts/MainAdminContext";
import { ContactsProvider } from "../../contexts/ContactContext";
import { UserPaymentsProvider } from "../../contexts/UserPaymentsContext";

interface AdminProvidersProps {
  children: ReactNode;
}

const AdminProviders: FC<AdminProvidersProps> = ({ children }) => {
  return (
    <MainAdminProvider>
      <UsageAnalyticsProvider>
        <PlansAnalyticsProvider>
          <PaymentsAnalyticsProvider>
            <TicketsProvider>
              <ContactsProvider>
                <UsersListProvider>
                  <UserPaymentsProvider>{children}</UserPaymentsProvider>
                </UsersListProvider>
              </ContactsProvider>
            </TicketsProvider>
          </PaymentsAnalyticsProvider>
        </PlansAnalyticsProvider>
      </UsageAnalyticsProvider>
    </MainAdminProvider>
  );
};

export default AdminProviders;
