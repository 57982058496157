import React from "react";
import Table from "../table/Table";
import { TableColumn } from "../../utilities/types/support/types";
import { DocumentUpload } from "iconsax-react";
import StatusBadge from "../table/StatusBadge";
import { Link } from "react-router-dom";
import { formatDate } from "../../utilities/helper/dateUtils";
import MobilePaymentCard from "./MobilePaymentCard";
import CustomPagination from "../table/CustomPagination";

interface Payment {
  id: number;
  hash_id: string;
  label: string;
  pay_operator: string;
  pay_operator_logo: string;
  status: string;
  amount: number;
  product_type: string;
  product: {
    id: number;
    name: string;
    price: number;
  } | null;
  last4_card_number: string;
  created_at: number;
}

interface PaymentsTableProps {
  data: {
    data: Payment[];
    current_page: number;
    last_page: number;
  };
  onPageChange: (newPage: number) => void;
}

const PaymentsTable: React.FC<PaymentsTableProps> = ({ data, onPageChange }) => {
  const columns: TableColumn<Payment>[] = [
    {
      key: "id",
      header: "Invoice",
      render: (value, item) => (
        <Link to={`/dashboard/payment/${item.hash_id}`} className="flex items-center gap-2 text-closed-shutter-300 hover:text-gorgonzola-blue-300">
          <span>#{value}</span>
          <DocumentUpload className="size-5" variant="Broken" />
        </Link>
      ),
    },
    {
      key: "pay_operator",
      header: "P.Method",
      render: (value, item) => (
        <div className="flex items-center gap-2">
          <img src={item.pay_operator_logo} alt={value} className="w-8 h-6" />
          {item.last4_card_number ? <span>•••• {item.last4_card_number}</span> : null}
        </div>
      ),
    },
    {
      key: "amount",
      header: "Amount",
      render: (value) => <span className="font-bold">${value ? value.toFixed(2) : "-"}</span>,
    },
    {
      key: "status",
      header: "Status",
      render: (value) => (
        <div className="flex w-full">
          <StatusBadge statusText={value} />
        </div>
      ),
    },
    {
      key: "created_at_date",
      header: "Date",
      render: (value, item) => {
        const date = new Date(item.created_at * 1000);
        return item.created_at ? formatDate(date.toLocaleDateString()) : "-";
      },
    },
    {
      key: "created_at_time",
      header: "Time",
      render: (value, item) => {
        const date = new Date(item.created_at * 1000);
        return item.created_at ? date.toLocaleTimeString() : "-";
      },
    },
  ];

  return (
    <div className="w-full border rounded-md border-gorgonzola-blue-50">
      <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
        <div className="flex flex-col justify-between w-full gap-2.5 p-4 md:py-6 md:items-center md:flex-row md:gap-0">
          <h2 className="font-bold text-closed-shutter-300">Recent Payment Transactions</h2>
          <span className="text-xs text-closed-shutter-300/70">List of your payments</span>
        </div>

        {data && data.data && data.data.length > 0 ? (
          <>
            <div className="block lg:hidden odd:*:bg-gorgonzola-blue-50/30 even:*:bg-white">
              {data.data.map((payment) => (
                <MobilePaymentCard key={payment.id} payment={payment} />
              ))}
            </div>
            <div className="hidden w-full lg:block">
              <Table data={data.data} columns={columns} />
            </div>
            <CustomPagination
              currentPage={data.current_page}
              totalPages={data.last_page}
              onPageChange={onPageChange}
              hasPreviousPage={data.current_page > 1}
              hasNextPage={data.current_page < data.last_page}
            />
          </>
        ) : (
          <div className="p-6 text-center">
            <p className="text-closed-shutter-300">No payment data available</p>
            <p className="mt-2 text-xs text-closed-shutter-200">Your payment history will appear here once you make transactions.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentsTable;
