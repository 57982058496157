export const FREE_TRIAL = [
  {
    id: 0,
    title: "Body Measurment",
    description:
      "Shaku's Body Measurement service utilizes cutting-edge AI technology to deliver accurate and personalized body measurements, empowering customers to shop with confidence. Users can simply upload two photos, and our AI analyzes it to provide precise measurements, ensuring a perfect fit for every garment purchased online. This tool dramatically reduces the hassle of returns due to sizing errors and enhances the overall shopping experience by guaranteeing a perfect fit.",
    image: "/assets/image/free-trial/plans/body_meaurment.png",
    image_alt: "Body Meaurment",
    api_href: "#api_href",
    sdk_href: "body-measurment",
  },
  {
    id: 1,
    title: "Auto Tagging",
    description:
      "Shaku’s Auto Tagging feature revolutionizes the way online apparel is cataloged. By automatically identifying and tagging key attributes of clothing items from images, this technology simplifies the process for retailers, ensuring detailed and searchable product listings. From identifying colors and styles to fabric types, Auto Tagging enhances inventory management, improves search functionality, and drives better customer engagement and sales.",
    image: "/assets/image/free-trial/plans/auto_tagging.png",
    image_alt: "Auto Tagging",
    api_href: "#api_href",
    sdk_href: "auto-tagging",
  },
  {
    id: 2,
    title: "Visual Clothes Search",
    description:
      "With Shaku's Clothes Visual Search, discovering and purchasing fashion has never been easier. Users can upload an image of an outfit they admire, and our advanced visual search technology quickly scans through a comprehensive inventory to present similar items available for purchase. This intuitive feature saves time, sparks new fashion discoveries, and personalizes the shopping journey, making it a delightful experience for every user.",
    image: "/assets/image/free-trial/plans/visual-clothes-search.png",
    image_alt: "Visual Clothes Search",
    api_href: "#api_href",
    sdk_href: "visual-clothes-search",
  },
];
