import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

interface SalePlanSumAmountChartProps {
  data: {
    total_amount: number;
    service_data: {
      auto_tagging: number;
      body_measurement: number;
      visual_clothes_search: number;
    };
  };
}

const SalePlanSumAmountChart: React.FC<SalePlanSumAmountChartProps> = ({ data }) => {
  const chartRefBottom = useRef<HTMLCanvasElement>(null);
  const chartInstanceBottom = useRef<Chart<"doughnut", number[], unknown> | null>(null);

  const body_measurement = typeof data.service_data.body_measurement === "string" ? parseFloat(data.service_data.body_measurement) : data.service_data.body_measurement || 0;
  const auto_tagging = typeof data.service_data.auto_tagging === "string" ? parseFloat(data.service_data.auto_tagging) : data.service_data.auto_tagging || 0;
  const visual_clothes_search = typeof data.service_data.visual_clothes_search === "string" ? parseFloat(data.service_data.visual_clothes_search) : data.service_data.visual_clothes_search || 0;

  useEffect(() => {
    if (chartRefBottom.current) {
      const ctxBottom = chartRefBottom.current.getContext("2d");

      if (ctxBottom) {
        if (chartInstanceBottom.current) {
          chartInstanceBottom.current.destroy();
        }

        chartInstanceBottom.current = new Chart<"doughnut", number[], unknown>(ctxBottom, {
          type: "doughnut",
          data: {
            datasets: [
              {
                data: [body_measurement || 0.01, auto_tagging || 0.01, visual_clothes_search || 0.01],
                backgroundColor: ["#F7BA1E", "#3491FA", "#14C9C9"],
                borderWidth: 0,
              },
            ],
          },
          options: {
            cutout: "70%",
            rotation: -90,
            circumference: 180,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false },
              tooltip: {
                backgroundColor: "#fff",
                titleColor: "#484554c2",
                titleFont: {
                  size: 12,
                  weight: "bold",
                },
                titleAlign: "center",
                bodyColor: "#23262F",
                bodyFont: {
                  size: 12,
                  weight: "bolder",
                },
                bodyAlign: "left",
                bodySpacing: 8,
                borderColor: "#C6C7F0",
                borderWidth: 1,
                cornerRadius: 16,
                padding: 12,
                usePointStyle: true,
                boxPadding: 8,
                callbacks: {
                  label: function (context: any) {
                    const labels = ["Body Measurement", "Auto Tagging", "Visual Clothes Search"];
                    const label = labels[context.dataIndex];
                    const value = context.parsed;
                    return `${label}: $${value}`;
                  },
                  labelTextColor: function (context: any) {
                    return ["#F7BA1E", "#3491FA", "#14C9C9"][context.dataIndex];
                  },
                },
              },
            },
          },
        });
      }
    }

    return () => {
      if (chartInstanceBottom.current) {
        chartInstanceBottom.current.destroy();
      }
    };
  }, [body_measurement, auto_tagging, visual_clothes_search]);

  return (
    <div className="flex flex-col justify-between w-full min-h-[22rem]">
      <div className="relative w-full h-[11rem]">
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[15.875rem] h-full z-20">
          <canvas ref={chartRefBottom}></canvas>
        </div>
      </div>
      <div className="flex flex-col items-center w-full gap-6 p-4">
        <span className="font-bold text-center text-closed-shutter-300">${data.total_amount}</span>

        <div className="flex flex-col w-full gap-3">
          <div className="inline-flex items-center justify-between">
            <div className="inline-flex items-center gap-2">
              <span className="bg-[#3491FA] rounded-full size-3 min-w-3" />
              <span className="text-xs capitalize text-closed-shutter-300/70">Auto Tagging</span>
            </div>
            <span className="text-xs text-closed-shutter-300">{data.service_data.auto_tagging ? `$${data.service_data.auto_tagging}` : "---"}</span>
          </div>
          <div className="inline-flex items-center justify-between">
            <div className="inline-flex items-center gap-2">
              <span className="bg-[#14C9C9] rounded-full size-3 min-w-3" />
              <span className="text-xs capitalize text-closed-shutter-300/70">Visual Clothes Search</span>
            </div>
            <span className="text-xs text-closed-shutter-300">{data.service_data.visual_clothes_search ? `$${data.service_data.visual_clothes_search}` : "---"}</span>
          </div>
          <div className="inline-flex items-center justify-between">
            <div className="inline-flex items-center gap-2">
              <span className="bg-[#F7BA1E] rounded-full size-3 min-w-3" />
              <span className="text-xs capitalize text-closed-shutter-300/70">Body Measurement</span>
            </div>
            <span className="text-xs text-closed-shutter-300">{data.service_data.body_measurement ? `$${data.service_data.body_measurement}` : "---"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalePlanSumAmountChart;
