import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Clock, ArrowLeft } from "iconsax-react";
import { HOW_TO_WORK_POSTS } from "../../utilities/defaults/tutorial/tutorial-posts";
import { TutorialPostParams } from "../../utilities/types/tutorial/types";
import MainButton from "../button/MainButton";

const TutorialPost: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<TutorialPostParams | null>(null);

  useEffect(() => {
    const foundPost = HOW_TO_WORK_POSTS.find((p) => p.id === Number(id));
    setPost(foundPost || null);
  }, [id]);

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <div className="flex flex-col gap-6 p-4 md:p-6">
      <div className="flex items-center justify-between w-full">
        <MainButton to="/dashboard/tutorial" type="ghostOutlined">
          <ArrowLeft size={20} variant="Broken" />
          <span>Back</span>
        </MainButton>

        <div className="flex items-center">
          <span className={`px-4 py-3 text-xs capitalize text-white rounded ${post.tag === "body_measurment" ? "bg-[#F7BA1E]" : "bg-gorgonzola-blue-300"}`}>{post.title}</span>
        </div>
      </div>

      {post.image && (
        <div className="relative overflow-hidden rounded-lg aspect-video">
          {post.movie_src && (
            <div className="mt-6">
              <video controls className="w-full rounded-lg" src={`/assets/videos/${post.movie_src}.mp4`}>
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      )}

      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-2 text-xs text-closed-shutter-300/70">
          <Clock size={16} variant="Broken" />
          <span>{post.reading_time} min</span>
        </div>
        <h1 className="font-bold text-closed-shutter-300">{post.title}</h1>
        <span className="text-xs text-closed-shutter-300/70">{post.created_at}</span>
      </div>

      <p className="text-base text-closed-shutter-300/70">{post.description}</p>
    </div>
  );
};

export default TutorialPost;
