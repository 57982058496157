import React, { useContext, useEffect, useState } from "react";
import { getUserTickets } from "../../../../utilities/api/adminService";
import Loader from "../../../loading/Loader";
import CustomPagination from "../../../table/CustomPagination";
import TicketsTable from "./TicketsTable";
import { AuthContext } from "../../../../contexts/AuthContext";

interface UserTicketHistoryProps {
  userId: string;
}

const UserTicketHistory: React.FC<UserTicketHistoryProps> = ({ userId }) => {
  const { accessToken } = useContext(AuthContext);

  const [tickets, setTickets] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!accessToken) return;

    const fetchTickets = async () => {
      try {
        setLoading(true);
        const response = await getUserTickets(accessToken, userId, currentPage);
        if (response.ok) {
          setTickets(response.data.data);
          setTotalPages(response.data.pagination.last_page);
        }
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, [userId, currentPage, accessToken]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="w-full border rounded-md border-gorgonzola-blue-50">
      <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
        <div className="flex flex-col justify-between w-full gap-2.5 p-4 md:py-6 md:items-center md:flex-row md:gap-0">
          <h2 className="font-bold text-closed-shutter-300">User Ticket History</h2>
        </div>

        {loading ? (
          <div className="flex justify-center w-full p-4 md:p-6">
            <Loader />
          </div>
        ) : tickets.length > 0 ? (
          <>
            <TicketsTable tickets={tickets} />
            <CustomPagination currentPage={currentPage} totalPages={totalPages} hasPreviousPage={currentPage > 1} hasNextPage={currentPage < totalPages} onPageChange={handlePageChange} />
          </>
        ) : (
          <div className="p-6 text-center">
            <p className="text-closed-shutter-300">No tickets found</p>
            <p className="mt-2 text-xs text-closed-shutter-200">There are no tickets matching your filters.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserTicketHistory;
