import React, { useEffect } from "react";
import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
import BodyMeasurementServiceImageDisplay from "../BodyMeasurementServiceImageDisplay";
import BodyMeasurementServicePagination from "../BodyMeasurementServicePagination";

const Height: React.FC = () => {
  const { height, setHeight, setCanProceedToNextStep } = useBodyMeasurementServiceContext();

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value.trim());
    setHeight(value);
  };

  useEffect(() => {
    // setCanProceedToNextStep(height.length === 3 && height >= 50 && height <= 250);
    setCanProceedToNextStep(height > 50 && height < 230);
  }, [height, setCanProceedToNextStep]);

  return (
    <div className="flex flex-col items-center w-full gap-32">
      <div className="flex flex-col gap-10 w-full md:w-[21.4375rem]">
        <div className="flex flex-col gap-2">
          <label htmlFor="userHeight">
            <span className="text-xs capitalize text-closed-shutter-300">Your height size</span>
          </label>
          <input
            id="userHeight"
            type="number"
            value={height}
            minLength={2}
            maxLength={3}
            min={51}
            max={230}
            placeholder="180"
            onChange={handleHeightChange}
            className={`py-3 px-4 rounded-full border outline-none placeholder:text-closed-shutter-300/70 valid:border-closed-shutter-300/70 invalid:border-english-vermillion-300`}
          />
        </div>

        <BodyMeasurementServiceImageDisplay />
      </div>
      <BodyMeasurementServicePagination />
    </div>
  );
};

export default Height;
