import { forwardRef } from "react";
import { useSnackbar } from "notistack";
import { CloseSquare } from "iconsax-react";

const SuccessSnackbar = forwardRef<HTMLDivElement, { message: string }>(({ message }, ref) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <div ref={ref} className=" bg-white border-l-8 border-[#16A34A] p-4 rounded-lg flex items-center justify-between gap-10 z-[9999999]">
      <div className="flex items-center gap-2">
        <span className="size-6">
          <img src={"/assets/image/notistack/ticksquare.svg"} alt="Tick Square" />
        </span>
        <span className="text-xs text-closed-shutter-300 line-clamp-1">{message}</span>
      </div>
      <button className="size-6" onClick={() => closeSnackbar()}>
        <CloseSquare className="size-6" variant="Broken" />
      </button>
    </div>
  );
});

SuccessSnackbar.displayName = "SuccessSnackbar";

export default SuccessSnackbar;
