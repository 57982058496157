import React, { useState, useEffect, useRef } from "react";
import styles from "../../styles/plan/calendarSelector.module.css";
import { ArrowLeft2, ArrowRight2, Calendar } from "iconsax-react";
import MainButton from "../button/MainButton";

interface CalendarSelectorProps {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
}

const CalendarSelector: React.FC<CalendarSelectorProps> = ({ selectedDate, onDateChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const calendarRef = useRef<HTMLDivElement>(null);

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

  const [currentMonth, setCurrentMonth] = useState(selectedDate.getMonth());
  const [currentYear, setCurrentYear] = useState(selectedDate.getFullYear());

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getDaysInMonth = (month: number, year: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const generateCalendar = () => {
    const daysInMonth = getDaysInMonth(currentMonth, currentYear);
    const firstDay = new Date(currentYear, currentMonth, 1).getDay();
    const calendar = [];

    for (let i = 0; i < firstDay; i++) {
      calendar.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      calendar.push(i);
    }

    return calendar;
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handleDateClick = (day: number) => {
    const newDate = new Date(currentYear, currentMonth, day);
    if (newDate <= today) {
      onDateChange(newDate);
      setIsOpen(false);
    }
  };

  const isDateDisabled = (day: number) => {
    return new Date(currentYear, currentMonth, day) > today;
  };

  return (
    <div className={styles.calendarSelector} ref={calendarRef}>
      <MainButton type="ghostOutlined" onClick={() => setIsOpen(!isOpen)}>
        <Calendar className="size-6" variant="Broken" />
        {selectedDate.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
      </MainButton>
      {isOpen && (
        <div className={styles.calendarDropdown}>
          <div className={styles.calendarHeader}>
            <MainButton type="ghostOutlined" onClick={handlePrevMonth}>
              <ArrowLeft2 variant="Broken" />
            </MainButton>

            <MainButton type="ghostOutlined" onClick={handleNextMonth}>
              <span>{months[currentMonth]}</span>
              <span>{currentYear}</span>
            </MainButton>

            <MainButton type="ghostOutlined" onClick={handleNextMonth}>
              <ArrowRight2 variant="Broken" />
            </MainButton>
          </div>

          <div className={styles.calendarDays}>
            {days.map((day) => (
              <span key={day}>{day}</span>
            ))}
          </div>

          <div className={styles.calendarDates}>
            {generateCalendar().map((day, index) => (
              <button
                key={index}
                onClick={() => day && handleDateClick(day)}
                className={`${styles.dateCell} 
                  ${day === selectedDate.getDate() && currentMonth === selectedDate.getMonth() && currentYear === selectedDate.getFullYear() ? styles.selected : ""}
                  ${day && isDateDisabled(day) ? styles.disabled : ""}
                  ${day && !isDateDisabled(day) ? styles.enabled : ""}`}
                disabled={!day || isDateDisabled(day)}
              >
                {day}
              </button>
            ))}
          </div>

          <div className={styles.calendarFooter}>
            <MainButton
              type="ghostOutlined"
              onClick={() => {
                onDateChange(new Date());
                setIsOpen(false);
              }}
              customClass="w-full justify-center"
            >
              Today
            </MainButton>

            <MainButton type="primary" onClick={() => setIsOpen(false)} customClass="w-full justify-center">
              Done
            </MainButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarSelector;
