import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { ShakuPlan } from "../../utilities/types/plan/types";
import ShakuPlansItem from "./ShakuPlansItem";

import "swiper/css";
import "swiper/css/pagination";

interface ShakuPlansItemMobileSliderProps {
  plans: ShakuPlan[];
  onPlanClick: (plan: ShakuPlan) => void;
}

const ShakuPlansItemMobileSlider: React.FC<ShakuPlansItemMobileSliderProps> = ({ plans, onPlanClick }) => {
  return (
    <div className="z-20 flex justify-center w-full md:px-4 ">
      <div className="w-full overflow-hidden">
        <Swiper
          direction={"horizontal"}
          loop
          pagination={{
            el: ".shaku-plans-items-mobile-slider",
            clickable: true,
          }}
          spaceBetween={20}
          modules={[Autoplay, Pagination]}
          className="myMobileHeroSwiper"
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
          }}
        >
          {plans.map((plan) => (
            <SwiperSlide key={plan.id}>
              <div className="flex justify-center w-full gap-4">
                <ShakuPlansItem plan={plan} onClick={() => onPlanClick(plan)} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="flex justify-center w-full my-4 md:my-8">
          <div className="flex justify-center gap-1.5 shaku-plans-items-mobile-slider *:cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default ShakuPlansItemMobileSlider;
