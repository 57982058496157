import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import UploadPic from "./body-measurement-service/body-measurement-service-steps/BodyMeasurementUploadPic";
import Height from "./body-measurement-service/body-measurement-service-steps/BodyMeasurementHeight";
import Measurement from "./body-measurement-service/body-measurement-service-steps/BodyMeasurementMeasurement";
import Result from "./body-measurement-service/body-measurement-service-steps/BodyMeasurementResult";
import BodyMeasurementServiceStepIndicator from "./body-measurement-service/BodyMeasurementServiceStepIndicator";
import { useBodyMeasurementServiceContext } from "../../contexts/BodyMeasurementServiceContext";
import { FREE_TRIAL } from "../../utilities/defaults/free-trial/plans";
import Loader from "../loading/Loader";

const BodyMeasurementService: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { currentStep } = useBodyMeasurementServiceContext();
  const [service, setService] = useState<typeof FREE_TRIAL[number] | null>(null);

  useEffect(() => {
    const foundService = FREE_TRIAL.find((service) => service.sdk_href === id);
    setService(foundService || null);
  }, [id]);

  if (!service) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <Breadcrumb />
      <div className="flex flex-col items-center gap-16 p-4 md:p-6">
        <BodyMeasurementServiceStepIndicator />
        <div className="flex justify-center w-full">
          {currentStep === 0 ? <UploadPic /> : null}
          {currentStep === 1 ? <Height /> : null}
          {currentStep === 2 ? <Measurement /> : null}
          {currentStep === 3 ? <Result /> : null}
        </div>
      </div>
    </div>
  );
};

export default BodyMeasurementService;
