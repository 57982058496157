import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface TooltipProps {
  text: string;
  disabled?: boolean;
  position?: "top" | "bottom" | "left" | "right";
  children: React.ReactNode;
  className?: string;
}

const tooltipVariants = {
  initial: (position: string) => ({
    opacity: 0,
    scale: 0.8,
    x: position === "left" ? "-0.5rem" : position === "right" ? "0.5rem" : "-50%",
    y: position === "top" ? "-0.5rem" : position === "bottom" ? "0.5rem" : "-50%",
  }),
  animate: (position: string) => ({
    opacity: 1,
    scale: 1,
    x: position === "left" ? "-0.5rem" : position === "right" ? "0.5rem" : "-50%",
    y: position === "top" ? "-0.5rem" : position === "bottom" ? "0.5rem" : "-50%",
  }),
  exit: (position: string) => ({
    opacity: 0,
    scale: 0.8,
    transition: { duration: 0.2 },
  }),
};

const getBasePosition = (position: string) => {
  switch (position) {
    case "top":
      return "bottom-full left-1/2";
    case "bottom":
      return "top-full left-1/2";
    case "left":
      return "right-full top-1/2";
    case "right":
      return "left-full top-1/2";
    default:
      return "top-full";
  }
};

const getArrowClass = (position: string) => {
  const arrowPositions = {
    top: "bottom-0 left-1/2 -translate-x-1/2 translate-y-full rotate-180",
    bottom: "top-0 left-1/2 -translate-x-1/2 -translate-y-full",
    left: "right-0 top-1/2 -translate-y-1/2 translate-x-full rotate-90",
    right: "left-0 top-1/2 -translate-y-1/2 -translate-x-full -rotate-90",
  };
  return arrowPositions[position as keyof typeof arrowPositions];
};

const Tooltip: React.FC<TooltipProps> = ({ text, disabled = false, position = "bottom", children, className = "" }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={`relative inline-block ${className}`} onMouseEnter={() => !disabled && setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      {children}

      <AnimatePresence>
        {isVisible && (
          <motion.div
            custom={position}
            variants={tooltipVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{
              type: "spring",
              stiffness: 500,
              damping: 25,
            }}
            className={`absolute z-50 whitespace-nowrap pointer-events-none ${getBasePosition(position)}`}
          >
            <div
              className={`relative px-3 py-1.5 text-xs font-medium rounded-md shadow-lg
              ${disabled ? "bg-closed-shutter-100 text-closed-shutter-300" : "bg-white text-gorgonzola-blue-800"}`}
            >
              {text}
              <motion.svg className={`absolute w-2 h-2 fill-current text-white ${getArrowClass(position)}`} viewBox="0 0 8 8">
                <path d="M4 0L8 8H0L4 0z" />
              </motion.svg>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Tooltip;
