import React, { useState } from "react";
import StatusBadge from "../../table/StatusBadge";
import { TransformTime } from "../../../utilities/helper/TransformPublishedAt";
import MainButton from "../../button/MainButton";
import { Eye } from "iconsax-react";
import PaymentDetailsModal from "../analytics/PaymentsAnalytics/PaymentDetailsModal";

interface MobilePaymentCardProps {
  payment: any;
}

const MobilePaymentCard: React.FC<MobilePaymentCardProps> = ({ payment }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex flex-col items-center px-4 text-center *:w-full *:py-6 *:border-b *:border-b-gorgonzola-blue-100 text-closed-shutter-300">
      <span className="font-medium line-clamp-1">{payment.user?.email}</span>

      <div className="grid grid-cols-2 gap-2">
        <div>
          <p className="text-sm text-gray-500">Invoice</p>
          <p>#{payment.id ? payment.id : "-"}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Status</p>
          <div className="flex justify-center">
            <StatusBadge statusText={payment.status} />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2 *:space-y-2">
        <div>
          <p className="text-sm text-gray-500">Date</p>
          <p>{payment.created_at ? new Date(payment.created_at * 1000).toLocaleDateString() : "-"}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Time</p>
          <p>{payment.created_at ? TransformTime(new Date(payment.created_at * 1000).toISOString()) : "-"}</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2">
        <div>
          <p className="text-sm text-gray-500">Amount</p>
          <p>${payment.amount ? payment.amount : "-"}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">P.Method</p>
          <div className="flex items-center justify-center gap-2">
            <img src={payment.pay_operator_logo} alt={payment.pay_operator} className="w-8 h-6" />
            {payment.last4_card_number ? <span>•••• {payment.last4_card_number}</span> : null}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center w-full">
        <MainButton type="ghostOutlined" onClick={() => setIsModalOpen(true)}>
          <Eye variant="Broken" className="mr-2 size-4" />
          See
        </MainButton>
      </div>

      <PaymentDetailsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} paymentId={payment.id} />
    </div>
  );
};

export default MobilePaymentCard;
