import React, { useEffect, useContext, useState, useMemo } from "react";
import { createTicket } from "../../utilities/api/ticketService";
import { AuthContext } from "../../contexts/AuthContext";
import { TicketCreateData, TicketCreateFormData } from "../../utilities/types/tickets/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import TextField from "../fields/TextField";
import MainButton from "../button/MainButton";
import SelectField from "../fields/SelectField";
import AttachmentField from "../fields/AttachmentField";
import { enqueueSnackbar } from "notistack";
import { useSupport } from "../../contexts/SupportContext";
import Loader from "../loading/Loader";

const TicketCreate: React.FC = () => {
  const { accessToken } = useContext(AuthContext);
  const { ticketOptions, loading, fetchTicketOptions, fetchTickets } = useSupport();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<TicketCreateFormData>({
    mode: "onChange",
    defaultValues: {
      title: "",
      message: "",
      priority: "Normal",
      category: { value: "Select category", label: "Select category" },
      section: { value: "Select section", label: "Select section" },
      file: undefined,
    },
  });

  useEffect(() => {
    fetchTicketOptions();
  }, [fetchTicketOptions]);

  const sectionOptions = useMemo(() => ticketOptions.section_parts.map((section) => ({ value: section.operator_name, label: section.operator_name })), [ticketOptions.section_parts]);
  const categoryOptions = useMemo(() => ticketOptions.section_parts.flatMap((section) => section.categories.map((category) => ({ value: category.name, label: category.name }))), [
    ticketOptions.section_parts,
  ]);

  const onSubmit: SubmitHandler<TicketCreateFormData> = async (data) => {
    if (!accessToken) {
      enqueueSnackbar("Authentication failed. Please log in again.", { variant: "error" });
      return;
    }
    setIsSubmitting(true);
    try {
      const ticketData: TicketCreateData = {
        title: data.title,
        message: data.message,
        priority: "Normal",
        category: data.category.value,
        section: data.section.value,
        file: data.file ? await convertToBase64(data.file) : undefined,
      };

      const response = await createTicket(accessToken, ticketData);
      if (response.ok) {
        enqueueSnackbar(`Support ticket created successfully! Ticket ID: ${response.data.ticket_id}`, { variant: "success" });
        fetchTickets();
        reset();
      } else {
        enqueueSnackbar(response.message || "Unable to create support ticket. Please try again.", { variant: "error" });
      }
    } catch (err) {
      console.error("Error creating ticket:", err);
      enqueueSnackbar("Unable to create support ticket. Please try again.. Please try again.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(",")[1]);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const isFormValid = watch("title") && watch("category") && watch("section") && (watch("message") || watch("file")) && !errors.title && !errors.category && !errors.section;

  return (
    <div className="flex flex-col items-end justify-center w-full p-4 border rounded-md gap-13 border-gorgonzola-blue-50">
      {loading ? (
        <div className="flex justify-center w-full p-4 md:p-6">
          <Loader />
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className="grid w-full grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6 lg:gap-y-13">
            <div className="flex flex-col gap-2">
              <Controller
                name="title"
                control={control}
                rules={{ required: "Title is required" }}
                render={({ field }) => (
                  <TextField required label="Title" placeholder="Enter the subject of your support request" {...field} error={!!errors.title} success={!!watch("title") && !errors.title} />
                )}
              />
              {errors.title && <div className="text-english-vermillion-300">{errors.title.message}</div>}
            </div>

            <div className="flex flex-col gap-2">
              <Controller
                name="category"
                control={control}
                rules={{ required: "Category is required" }}
                render={({ field }) => (
                  <SelectField
                    label="Category"
                    options={categoryOptions}
                    control={control}
                    required
                    placeholder="Select category"
                    error={!!errors.category}
                    success={!!watch("category") && !errors.category}
                    {...field}
                  />
                )}
              />
              {errors.category && <div className="text-english-vermillion-300">{errors.category.message}</div>}
            </div>
            <div className="flex flex-col col-span-1 gap-2">
              <Controller
                name="section"
                control={control}
                rules={{ required: "Department is required" }}
                render={({ field }) => (
                  <SelectField
                    label="Department"
                    options={sectionOptions}
                    control={control}
                    required
                    placeholder="Select the relevant department for your inquiry"
                    error={!!errors.section}
                    success={!!watch("section") && !errors.section}
                    {...field}
                  />
                )}
              />
              {errors.section && <div className="text-english-vermillion-300">{errors.section.message}</div>}
            </div>
            <div className="flex flex-col gap-2">
              <Controller name="file" control={control} render={({ field }) => <AttachmentField label="Attachment" onChange={(file) => field.onChange(file)} />} />
            </div>
            <div className="flex flex-col col-span-1">
              <Controller
                name="message"
                control={control}
                render={({ field }) => <TextField type="textArea" required label="Message" placeholder="Provide details about your issue or question" {...field} value={field.value ?? ""} />}
              />
            </div>
          </form>
          <MainButton type="submit" disabled={!isFormValid || isSubmitting} onClick={handleSubmit(onSubmit)}>
            {isSubmitting ? "Sending..." : "Send"}
          </MainButton>
        </>
      )}
    </div>
  );
};

export default TicketCreate;
