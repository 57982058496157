import { useParams } from "react-router-dom";
import { FREE_TRIAL } from "../../utilities/defaults/free-trial/plans";
import BodyMeasurementService from "./BodyMeasurementService";
import AutoTaggingService from "./AutoTaggingService";
import VisualClothesService from "./VisualClothesService";
import { useEffect, useState } from "react";
import Loader from "../loading/Loader";
import { VisualClothesServiceProvider } from "../../contexts/VisualClothesServiceContext";
import { AutoTaggingServiceProvider } from "../../contexts/AutoTaggingServiceContext";
import { BodyMeasurementServiceProvider } from "../../contexts/BodyMeasurementServiceContext";

const FreeTrialDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [service, setService] = useState<typeof FREE_TRIAL[number] | null>(null);

  useEffect(() => {
    const foundService = FREE_TRIAL.find((service) => service.sdk_href === id);
    setService(foundService || null);
  }, [id]);

  if (!service) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <Loader />
      </div>
    );
  }

  switch (service.id) {
    case 0:
      return (
        <BodyMeasurementServiceProvider>
          <BodyMeasurementService />
        </BodyMeasurementServiceProvider>
      );
    case 1:
      return (
        <AutoTaggingServiceProvider>
          <AutoTaggingService />
        </AutoTaggingServiceProvider>
      );
    case 2:
      return (
        <VisualClothesServiceProvider>
          <VisualClothesService />
        </VisualClothesServiceProvider>
      );
    default:
      return <div>Invalid service</div>;
  }
};

export default FreeTrialDetails;
