import React, { createContext, useState, useContext } from "react";
import { getAvailableCharts, SizeGuide, submitSizeMeasurement } from "../utilities/api/bodyMeasurementService";
import { AuthContext } from "./AuthContext";
import { MeasurementResult } from "../utilities/types/measurement-data/types";
import { convertToBase64 } from "../utilities/helper/convertToBase64";
import { SizeGuideData } from "../components/free-trial/body-measurement-service/SizeGuideQuestionnaire";

// Interface for the context value
interface BodyMeasurementServiceContextValue {
  frontImage: string | null;
  sideImage: string | null;
  height: number;
  currentStep: number;
  canProceedToNextStep: boolean;
  measurementData: MeasurementResult | null;
  error: string | null;
  availableCharts: any | null;
  sizeGuideResult: any | null;
  loadingCharts: boolean;
  loadingSizeGuide: boolean;
  setError: (error: string | null) => void;
  handlePageChange: (page: number) => void;
  handleFrontImageUpload: (image: File | undefined) => void;
  handleSideImageUpload: (image: File | undefined) => void;
  setHeight: (height: number) => void;
  setCanProceedToNextStep: (value: boolean) => void;
  submitMeasurement: () => Promise<void>;
  resetBodyMeasurementService: () => void;
  fetchAvailableCharts: () => Promise<void>;
  submitSizeGuide: (sizeGuideData: SizeGuideData) => Promise<void>;
}

// Interface for the provider props
interface BodyMeasurementServiceProviderProps {
  children: React.ReactNode;
}

// Create the context
export const BodyMeasurementServiceContext = createContext<BodyMeasurementServiceContextValue | undefined>(undefined);

// Provider component
export const BodyMeasurementServiceProvider: React.FC<BodyMeasurementServiceProviderProps> = ({ children }) => {
  // State variables
  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [sideImage, setSideImage] = useState<string | null>(null);
  const [height, setHeight] = useState(180);
  const [currentStep, setCurrentStep] = useState(0);
  const [canProceedToNextStep, setCanProceedToNextStep] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [measurementData, setMeasurementData] = useState<MeasurementResult | null>(null);
  const { accessToken } = useContext(AuthContext);
  const [availableCharts, setAvailableCharts] = useState<any | null>(null);
  const [sizeGuideResult, setSizeGuideResult] = useState<any | null>(null);
  const [loadingCharts, setLoadingCharts] = useState(false);
  const [loadingSizeGuide, setLoadingSizeGuide] = useState(false);

  // Function to handle page change
  const handlePageChange = (page: number) => {
    setError(null);
    setCurrentStep(page);
    setCanProceedToNextStep(false);
  };

  // Function to handle front image upload
  const handleFrontImageUpload = async (image: File | undefined) => {
    if (image) {
      setError(null);

      const base64 = await convertToBase64(image);
      setFrontImage(base64);
      setCanProceedToNextStep(sideImage !== null);
    }
  };

  // Function to handle side image upload
  const handleSideImageUpload = async (image: File | undefined) => {
    if (image) {
      const base64 = await convertToBase64(image);
      setSideImage(base64);
      setCanProceedToNextStep(frontImage !== null);
    }
  };

  const submitMeasurement = async () => {
    if (!frontImage || !sideImage || !height || !accessToken) {
      setError("Missing required information for measurement");
      return;
    }

    try {
      const response = await submitSizeMeasurement(accessToken, {
        present_height: height.toString(),
        img_full_view_body: frontImage,
        img_side_view_body: sideImage,
      });

      if (response.ok) {
        setMeasurementData(response.data.result);
        setCanProceedToNextStep(true);
        setCurrentStep(3);
        setError(null);
      } else {
        setError(response.message || "Failed to process measurement");
        setCurrentStep(2);
      }
    } catch (error) {
      console.error("Error submitting size measurement:", error);
      setError("An error occurred during measurement. Please try again.");
      setCurrentStep(2);
    }
  };

  const fetchAvailableCharts = async () => {
    if (!accessToken) {
      setError("No access token available");
      return;
    }

    try {
      setLoadingCharts(true);
      const response = await getAvailableCharts(accessToken);
      if (response.ok) {
        setAvailableCharts(response.data);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError("Failed to fetch available charts");
      console.error("Error fetching available charts:", error);
    } finally {
      setLoadingCharts(false);
    }
  };

  const submitSizeGuide = async (sizeGuideData: SizeGuideData) => {
    if (!accessToken) {
      setError("No access token available");
      return;
    }

    try {
      setLoadingSizeGuide(true);
      const response = await SizeGuide(accessToken, sizeGuideData);

      if (response.ok) {
        setSizeGuideResult(response.data);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError("Failed to submit size guide");
      console.error("Error submitting size guide:", error);
    } finally {
      setLoadingSizeGuide(false);
    }
  };

  // Function to reset the Free Trial
  const resetBodyMeasurementService = () => {
    setFrontImage(null);
    setSideImage(null);
    setHeight(180);
    setCurrentStep(0);
    setCanProceedToNextStep(false);
    setMeasurementData(null);
    setAvailableCharts(null);
    setSizeGuideResult(null);
  };

  // Return the context value
  return (
    <BodyMeasurementServiceContext.Provider
      value={{
        frontImage,
        sideImage,
        height,
        currentStep,
        canProceedToNextStep,
        handlePageChange,
        handleFrontImageUpload,
        handleSideImageUpload,
        availableCharts,
        sizeGuideResult,
        loadingCharts,
        loadingSizeGuide,
        fetchAvailableCharts,
        submitSizeGuide,
        error,
        setError,
        setHeight,
        setCanProceedToNextStep,
        submitMeasurement,
        resetBodyMeasurementService,
        measurementData,
      }}
    >
      {children}
    </BodyMeasurementServiceContext.Provider>
  );
};

// Custom hook to consume the context
export const useBodyMeasurementServiceContext = () => {
  const context = useContext(BodyMeasurementServiceContext);
  if (!context) {
    throw new Error("useBodyMeasurementServiceContext must be used within a BodyMeasurementServiceProvider");
  }
  return context;
};
