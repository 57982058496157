import { ProfileTabs } from "../../types/profile-tabs/types";

export const PROFILE_TABS: ProfileTabs[] = [
  {
    title: "Personal Information",
    active: true,
  },
  {
    title: "Business Information",
    active: false,
  },
  {
    title: "Change Password",
    active: false,
  },
];
