import React, { useEffect, useState } from "react";
import { useBodyMeasurementServiceContext } from "../../../contexts/BodyMeasurementServiceContext";
import { MeasurementResult } from "../../../utilities/types/measurement-data/types";
import Loader from "../../loading/Loader";
import MainButton from "../../button/MainButton";
import SizeGuideResult from "./SizeGuideResult";

export interface QuestionnaireStep {
  brand: string;
  gender: string;
  category: string;
  type: string;
}

export interface SizeGuideData {
  Brand: string;
  Gender: string;
  Category: string;
  Type: string;
  Size: Partial<MeasurementResult>;
}

const SizeGuideQuestionnaire: React.FC = () => {
  const { availableCharts, fetchAvailableCharts, submitSizeGuide, measurementData, loadingSizeGuide, sizeGuideResult } = useBodyMeasurementServiceContext();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [selections, setSelections] = useState<QuestionnaireStep>({
    brand: "",
    gender: "",
    category: "",
    type: "",
  });

  useEffect(() => {
    fetchAvailableCharts();
  }, []);

  if (!availableCharts) return null;

  const brands = Object.keys(availableCharts);
  const genders = selections.brand ? Object.keys(availableCharts[selections.brand]) : [];
  const categories = selections.gender ? Object.keys(availableCharts[selections.brand][selections.gender]) : [];
  const types = selections.category ? availableCharts[selections.brand][selections.gender][selections.category] : [];

  const steps = [
    {
      key: "brand",
      title: "Brand",
      description: "Choose your preferred brand",
      options: brands,
    },
    {
      key: "gender",
      title: "Gender",
      description: "Select the gender category",
      options: genders,
    },
    {
      key: "category",
      title: "Category",
      description: "Choose product category",
      options: categories,
    },
    {
      key: "type",
      title: "Type",
      description: "Select specific product type",
      options: types,
    },
  ];

  const handleItemSelect = (value: string) => {
    setSelectedItem(value);
  };

  const handleConfirmSelection = async () => {
    if (!selectedItem) return;

    const key = steps[currentStep].key as keyof QuestionnaireStep;

    setSelections((prev) => {
      const newSelections = { ...prev, [key]: selectedItem };

      if (key === "brand") {
        newSelections.gender = "";
        newSelections.category = "";
        newSelections.type = "";
      } else if (key === "gender") {
        newSelections.category = "";
        newSelections.type = "";
      } else if (key === "category") {
        newSelections.type = "";
      }

      return newSelections;
    });

    if (key === "type") {
      const sizeData = measurementData
        ? Object.entries(measurementData)
            .filter(([key]) => !["Details", "MetaData"].includes(key))
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
        : {};

      const sizeGuideData: SizeGuideData = {
        Brand: selections.brand,
        Gender: selections.gender,
        Category: selections.category,
        Type: selectedItem,
        Size: sizeData,
      };

      setCurrentStep(steps.length); // Go to results step
      await submitSizeGuide(sizeGuideData);
    } else {
      setCurrentStep(currentStep + 1);
    }

    setSelectedItem("");
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setSelectedItem("");
    }
  };

  const renderStepContent = (step: typeof steps[0]) => (
    <div className="grid grid-cols-2 gap-4">
      {step.options.map((option: any) => (
        <button
          key={option}
          onClick={() => handleItemSelect(option)}
          className={`p-4 transition-colors border rounded-lg 
            ${selectedItem === option ? "border-gorgonzola-blue-300 bg-gorgonzola-blue-50" : "hover:bg-gray-50"}`}
        >
          {option}
        </button>
      ))}
    </div>
  );

  return (
    <div className="flex flex-col w-full gap-8 md:p-6 lg:p-8 max-w-[40rem]">
      <hr />
      <div className="relative grid gap-2 grid-cols-5 *:flex-grow">
        {[...steps, { title: "Results" }].map((step, index) => (
          <div key={index} className="relative flex flex-col items-center bgred9">
            {index < steps.length && (
              <div className={`block absolute top-5 left-0 lg:left-1/2 translate-x-1/2 w-full lg:w-14 h-[2px] ${index < currentStep ? "bg-gorgonzola-blue-300" : "bg-closed-shutter-200"}`} />
            )}

            <div
              className={`w-10 h-10 rounded-full flex items-center justify-center border transition-all duration-600 bg-white z-20 ${
                index <= currentStep ? "border-gorgonzola-blue-300 bg-gorgonzola-blue-50" : "border-closed-shutter-200"
              }`}
            >
              {index + 1}
            </div>
            <span className={`hidden md:block mt-3.25 text-xs font-inter-medium ${index <= currentStep ? "text-gorgonzola-blue-300" : "text-closed-shutter-200"}`}>{step.title}</span>
          </div>
        ))}
      </div>

      <div className="space-y-2">
        {currentStep < steps.length ? (
          <>
            <h2 className="text-lg font-inter-bold text-closed-shutter-300">{steps[currentStep].title}</h2>
            <p className="text-base font-inter-regular text-closed-shutter-200">{steps[currentStep].description}</p>
            {renderStepContent(steps[currentStep])}
          </>
        ) : (
          <div className="flex flex-col gap-4">
            {loadingSizeGuide ? (
              <div className="flex justify-center p-8">
                <Loader />
              </div>
            ) : sizeGuideResult ? (
              <SizeGuideResult results={sizeGuideResult} />
            ) : (
              <div className="p-4 text-center text-gray-500">No size guide data available</div>
            )}
          </div>
        )}
      </div>

      <div className="flex justify-between mt-6">
        <MainButton type="secondary" onClick={handlePrevStep} disabled={currentStep <= 0}>
          Previous
        </MainButton>

        {currentStep < steps.length && (
          <MainButton type="primary" onClick={handleConfirmSelection} disabled={!selectedItem}>
            {currentStep === steps.length - 1 ? "Show Results" : "Next"}
          </MainButton>
        )}
      </div>
    </div>
  );
};

export default SizeGuideQuestionnaire;
