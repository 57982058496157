import { createContext, useState, FC, ReactNode, useContext, useCallback } from "react";
import { AuthContext } from "./AuthContext";
import { getUserPaymentChart, getUserPaymentSum, getUserPayments } from "../utilities/api/adminService";

interface UserPaymentsContextValue {
  transfersHistory: any;
  walletSummary: any;
  paymentsList: any;
  loading: boolean;
  fetchUserPaymentsData: (userId: string, page?: number, filters?: any) => Promise<void>;
  fetchUserPaymentChart: (userId: string, dateRange: { from_date: string; to_date: string }) => Promise<void>;
  fetchUserPaymentSum: (userId: string, dateRange: { from_date: string; to_date: string }) => Promise<void>;
}

export const UserPaymentsContext = createContext<UserPaymentsContextValue>({
  transfersHistory: null,
  walletSummary: null,
  paymentsList: null,
  loading: false,
  fetchUserPaymentsData: async () => {},
  fetchUserPaymentChart: async () => {},
  fetchUserPaymentSum: async () => {},
});

export const UserPaymentsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { accessToken } = useContext(AuthContext);
  const [transfersHistory, setTransfersHistory] = useState<any>(null);
  const [walletSummary, setWalletSummary] = useState<any>(null);
  const [paymentsList, setPaymentsList] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const fetchUserPaymentsData = useCallback(
    async (userId: string, page: number = 1, filters?: any) => {
      if (!accessToken) return;
      try {
        setLoading(true);
        const response = await getUserPayments(accessToken, userId, page, filters);
        if (response.ok) {
          setPaymentsList(response.data);
        }
      } catch (error) {
        console.error("Error fetching user payments data:", error);
      } finally {
        setLoading(false);
      }
    },
    [accessToken]
  );

  const fetchUserPaymentChart = useCallback(
    async (userId: string, dateRange: { from_date: string; to_date: string }) => {
      if (!accessToken) return;
      try {
        setLoading(true);
        const response = await getUserPaymentChart(accessToken, userId, dateRange);
        if (response.ok) {
          setTransfersHistory(response.data);
        }
      } catch (error) {
        console.error("Error fetching user payment chart:", error);
      } finally {
        setLoading(false);
      }
    },
    [accessToken]
  );

  const fetchUserPaymentSum = useCallback(
    async (userId: string, dateRange: { from_date: string; to_date: string }) => {
      if (!accessToken) return;
      try {
        setLoading(true);
        const response = await getUserPaymentSum(accessToken, userId, dateRange);
        if (response.ok) {
          setWalletSummary(response.data);
        }
      } catch (error) {
        console.error("Error fetching user payment sum:", error);
      } finally {
        setLoading(false);
      }
    },
    [accessToken]
  );

  return (
    <UserPaymentsContext.Provider
      value={{
        transfersHistory,
        walletSummary,
        paymentsList,
        loading,
        fetchUserPaymentsData,
        fetchUserPaymentChart,
        fetchUserPaymentSum,
      }}
    >
      {children}
    </UserPaymentsContext.Provider>
  );
};

export const useUserPayments = () => {
  const context = useContext(UserPaymentsContext);
  if (!context) {
    throw new Error("useUserPayments must be used within a UserPaymentsProvider");
  }
  return context;
};
