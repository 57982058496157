import { useBodyMeasurementServiceContext } from "../../../contexts/BodyMeasurementServiceContext";

const BodyMeasurementServiceImageDisplay = () => {
  const { frontImage, sideImage } = useBodyMeasurementServiceContext();

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="grid grid-cols-2 gap-4 overflow-hidden *:w-[9.375rem] *:h-[17.375rem] *:border-8 *:rounded-md *:border-gorgonzola-blue-50 *:object-contain *:max-h-full">
        {frontImage && <img src={`data:image/png;base64,${frontImage}`} alt="" />}
        {sideImage && <img src={`data:image/png;base64,${sideImage}`} alt="" />}
      </div>
    </div>
  );
};

export default BodyMeasurementServiceImageDisplay;
