import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { GlobalInfoProvider } from "./contexts/GlobalInfoContext";
import { SnackbarProvider } from "notistack";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleOAuthclientId } from "./utilities/defaults/google-OAuth/client-id";
import SuccessSnackbar from "./components/notification/Success";
import ErrorSnackbar from "./components/notification/Error";
import HotjarTracking from "./components/HotjarTracking/HotjarTracking";
import AppRoutes from "./components/AppRoutes";

const App: React.FC = () => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      Components={{
        success: SuccessSnackbar,
        error: ErrorSnackbar,
      }}
    >
      <AuthProvider>
        <GlobalInfoProvider>
          <GoogleOAuthProvider clientId={GoogleOAuthclientId}>
            <BrowserRouter>
              <HotjarTracking />
              <AppRoutes />
            </BrowserRouter>
          </GoogleOAuthProvider>
        </GlobalInfoProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
};

export default App;
