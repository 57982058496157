import React, { useContext, useState, useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import MainButton from "../../button/MainButton";
import TextField from "../../fields/TextField";
import SelectField from "../../fields/SelectField";
import CountrySelector from "../../fields/CountrySelector";
import { AuthContext } from "../../../contexts/AuthContext";
import { useGlobalInfo } from "../../../contexts/GlobalInfoContext";
import { addBusiness } from "../../../utilities/api/addBusinessService";
import { enqueueSnackbar } from "notistack";

interface BusinessFormData {
  business_email: string;
  company: string;
  legal: { value: boolean; label: string };
  tax_number: string;
  city: string;
  country: string;
  postal_code: string;
  address: string;
}

interface BusinessInfo {
  business_email: string;
  company: string;
  legal: boolean;
  tax_number: string;
  city: string;
  country: string;
  postal_code: string;
  address: string;
}

const legalOptions = [
  { value: true, label: "Legal Entity" },
  { value: false, label: "Natural Person" },
];

const Businessinfo: React.FC = () => {
  const { accessToken } = useContext(AuthContext);
  const { userInfo, refreshUserInfo } = useGlobalInfo();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialData, setInitialData] = useState<BusinessFormData>({
    business_email: "",
    company: "",
    legal: { value: true, label: "Legal Entity" },
    tax_number: "",
    city: "",
    country: "",
    postal_code: "",
    address: "",
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm<BusinessFormData>({
    mode: "onChange",
    defaultValues: initialData,
  });

  useEffect(() => {
    if (userInfo && userInfo.business) {
      setInitialData({
        business_email: userInfo.business.info.business_email || "",
        company: userInfo.business.info.company || "",
        legal: userInfo.business.info.legal ? { value: true, label: "Legal Entity" } : { value: false, label: "Natural Person" },
        tax_number: userInfo.business.info.tax_number || "",
        city: userInfo.business.info.city || "",
        country: userInfo.business.info.country || "",
        postal_code: userInfo.business.info.postal_code || "",
        address: userInfo.business.info.address || "",
      });
    }
  }, [userInfo]);

  useEffect(() => {
    reset(initialData);
  }, [initialData, reset]);

  const onSubmit: SubmitHandler<BusinessFormData> = async (data) => {
    if (!accessToken) {
      enqueueSnackbar("Authentication failed. Please log in again.", { variant: "error" });
      return;
    }

    setIsSubmitting(true);
    try {
      const businessInfo: BusinessInfo = {
        business_email: data.business_email,
        company: data.company,
        legal: data.legal.value,
        tax_number: data.tax_number,
        city: data.city,
        country: data.country,
        postal_code: data.postal_code,
        address: data.address,
      };

      const response = await addBusiness(accessToken, businessInfo);
      if (response.ok) {
        enqueueSnackbar("Business information updated successfully!", { variant: "success" });
        await refreshUserInfo();
      } else {
        enqueueSnackbar(response.message || "Unable to update business information. Please try again.", { variant: "error" });
      }
    } catch (error) {
      console.error("Error updating business information:", error);
      enqueueSnackbar("An error occurred while updating business information. Please try again later.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col gap-13 min-h-[40rem]">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-13">
          <Controller
            name="business_email"
            control={control}
            rules={{
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <TextField
                label="Business Email"
                type="email"
                placeholder="Enter business email"
                {...field}
                error={!!errors.business_email}
                success={!!watch("business_email") && !errors.business_email}
              />
            )}
          />
          <Controller
            name="company"
            control={control}
            render={({ field }) => <TextField label="Company Name" placeholder="Enter company name" {...field} error={!!errors.company} success={!!watch("company") && !errors.company} />}
          />
          <Controller
            name="legal"
            control={control}
            render={({ field }) => <SelectField label="Legal Status" options={legalOptions} control={control} {...field} error={!!errors.legal} success={!!watch("legal") && !errors.legal} />}
          />
          <Controller
            name="tax_number"
            control={control}
            render={({ field }) => <TextField label="Tax Number" placeholder="Enter tax number" {...field} error={!!errors.tax_number} success={!!watch("tax_number") && !errors.tax_number} />}
          />
          <Controller
            name="city"
            control={control}
            render={({ field }) => <TextField label="City" placeholder="Enter city" {...field} error={!!errors.city} success={!!watch("city") && !errors.city} />}
          />
          <Controller
            name="country"
            control={control}
            render={({ field }) => <CountrySelector label="Country" {...field} error={!!errors.country} success={!!watch("country") && !errors.country} />}
          />
          <Controller
            name="postal_code"
            control={control}
            render={({ field }) => <TextField label="Postal Code" placeholder="Enter postal code" {...field} error={!!errors.postal_code} success={!!watch("postal_code") && !errors.postal_code} />}
          />
          <Controller
            name="address"
            control={control}
            render={({ field }) => <TextField label="Address" placeholder="Enter address" {...field} error={!!errors.address} success={!!watch("address") && !errors.address} />}
          />
        </div>
        <div className="flex justify-end w-full">
          <MainButton type="submit" isSubmit disabled={!isValid || isSubmitting}>
            {isSubmitting ? "Updating..." : "Update Business Information"}
          </MainButton>
        </div>
      </form>
    </div>
  );
};

export default Businessinfo;
