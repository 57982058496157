import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.shaku.tech",
  // baseURL: "https://apitest.shaku.tech",
  // baseURL: "http://172.17.17.154:8000",
});

// Add the response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check if the error is a 401 or 403 error and the current path is dashboard or its sub-routes
    // if ((error.response.status === 401 || error.response.status === 403) && checkRoute()) {
    if ((error.response.status === 401 || error.response.status === 403) && !window.location.pathname.startsWith("/auth")) {
      localStorage.removeItem("userInfo");
      window.location.href = "/auth/login";
    }

    return Promise.reject(error);
  }
);

// Helper function to check if the current route is dashboard or its sub-routes
// function checkRoute() {
//   // return null;
//   const currentPath = window.location.pathname;
//   return currentPath.startsWith("/dashboard") || currentPath.startsWith("/admin") || currentPath.startsWith("/");
// }

export default instance;
