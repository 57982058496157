import { MeasurementResult } from "../types/measurement-data/types";

export interface ValidMeasurement {
  key: keyof Omit<MeasurementResult, "Details">;
  value: number;
  label: string;
  icon?: string;
}

export const measurementConfig: Array<{ key: keyof Omit<MeasurementResult, "Details">; label: string; icon: string }> = [
  { key: "Shoulder", label: "Shoulder", icon: "/assets/image/free-trial/body-measurment/measurement-result-icon/shoulder.svg" },
  { key: "Chest", label: "Chest", icon: "/assets/image/free-trial/body-measurment/measurement-result-icon/chest.svg" },
  { key: "Waist", label: "Waist", icon: "/assets/image/free-trial/body-measurment/measurement-result-icon/waist.svg" },
  { key: "Hips", label: "Hips", icon: "/assets/image/free-trial/body-measurment/measurement-result-icon/hips.svg" },
  { key: "Neck", label: "Neck", icon: "/assets/image/free-trial/body-measurment/measurement-result-icon/neck.svg" },
  { key: "UnderChest", label: "Under Chest", icon: "/assets/image/free-trial/body-measurment/measurement-result-icon/under-chest.svg" },
  { key: "Wrist", label: "Wrist", icon: "/assets/image/free-trial/body-measurment/measurement-result-icon/wrist.svg" },
  { key: "Knee", label: "Knee", icon: "/assets/image/free-trial/body-measurment/measurement-result-icon/knee.svg" },
  { key: "UpperBody", label: "Upper Body", icon: "/assets/image/free-trial/body-measurment/measurement-result-icon/upper-body.svg" },
  { key: "LowerBody", label: "Lower Body", icon: "/assets/image/free-trial/body-measurment/measurement-result-icon/lower-body.svg" },
  { key: "ArmLength", label: "Arm Length", icon: "/assets/image/free-trial/body-measurment/measurement-result-icon/arm-length.svg" },
];

export const getValidMeasurements = (data: MeasurementResult): ValidMeasurement[] => {
  return measurementConfig
    .map((config) => ({
      ...config,
      value: data[config.key],
    }))
    .filter((measurement) => typeof measurement.value === "number" && measurement.value !== null && measurement.value > 0);
};
