import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { getUserUsage } from "../../../utilities/api/adminService";
import ProjectDeliveriesChart from "../../project-deliveries-chart/ProjectDeliveriesChart";
import UserStatistics from "../../user-statistics/UserStatistics";
import ServiceHistoryTable from "../../usage/ServiceHistoryTable";
import Loader from "../../loading/Loader";
import { useUsersList } from "../../../contexts/UsersListContext";
import UserDetails from "./UserDetails";
import { adminRoutes } from "../../../utilities/defaults/routes/adminRoutes";
import { ArrowRight2 } from "iconsax-react";

const UserUsage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { accessToken } = useContext(AuthContext);
  const [usageList, setUsageList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [error, setError] = useState<string | null>(null);
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;
  const { userDetail, fetchUserDetail } = useUsersList();

  useEffect(() => {
    if (userId) {
      fetchUserDetail(userId);
    }
  }, [userId]);

  useEffect(() => {
    const fetchUserUsageData = async () => {
      if (!accessToken || !userId) return;

      setLoading(true);
      try {
        const listResponse = await getUserUsage(accessToken, userId, currentPage);

        if (listResponse.ok) {
          setUsageList(listResponse.data.data);
          setTotalPages(listResponse.data.last_page);
          setError(null);
        } else {
          setError("Failed to load user usage data.");
        }
      } catch (err) {
        console.error("Failed to fetch user usage data:", err);
        setError("An error occurred while fetching user usage data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserUsageData();
  }, [accessToken, userId, currentPage]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  if (!userDetail)
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <span className="capitalize text-english-vermillion-300">No user details found</span>
      </div>
    );

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="flex flex-col w-full p-4 md:p-6">
        <div className="flex items-center gap-1">
          <Link to={adminRoutes.users.list.path} className="text-xs transition-colors w-fit text-closed-shutter-300/70 hover:text-gorgonzola-blue-300 duration-600">
            Users List
          </Link>
          <ArrowRight2 className="text-closed-shutter-300/70 size-4" />
          <Link to={`/admin/users/${userId}`} className="font-bold transition-colors text-closed-shutter-300 hover:text-gorgonzola-blue-300 duration-600">
            #{userId}
          </Link>
        </div>
        <span className="font-bold text-closed-shutter-300">Usage</span>
      </div>

      {loading || !userId ? (
        <div className="flex items-center justify-center p-6">
          <Loader />
        </div>
      ) : !userDetail ? (
        <div className="flex justify-center w-full p-4 md:p-6">
          <span className="capitalize">not found anything</span>
        </div>
      ) : (
        <div className="flex flex-col justify-between w-full gap-4 p-4 md:p-6">
          <UserDetails userId={userId} />

          <div className="flex flex-col justify-between w-full gap-4 xl:flex-row">
            <ProjectDeliveriesChart userCreatedAt={userDetail.created_at} isAdmin userId={userId} />
            <UserStatistics userCreatedAt={userDetail.created_at} isAdmin userId={userId} />
          </div>

          <div className="w-full border rounded-md border-gorgonzola-blue-50">
            <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
              <div className="flex items-center justify-between w-full px-4 py-6">
                <h2 className="font-bold text-closed-shutter-300">Service Usage History</h2>
              </div>
              {loading ? (
                <div className="flex justify-center w-full p-4 md:p-6">
                  <Loader />
                </div>
              ) : error ? (
                <div className="p-6 text-center text-english-vermillion-300">{error}</div>
              ) : (
                <ServiceHistoryTable
                  serviceHistory={usageList}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  hasPreviousPage={hasPreviousPage}
                  hasNextPage={hasNextPage}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserUsage;
